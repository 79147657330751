import { Dispatch, useEffect, useReducer } from 'react';

import {
  FetchKeypadResponse,
  FetchKeypadsItem,
  FetchKeypadsResponse,
  FetchUpdateKeypadBody,
} from '../../../../tagging-tool/service/keypad';
import {
  fetchCreateKeypad,
  fetchDuplicateKeypad,
  fetchDeleteKeypad,
  fetchKeypads,
  fetchUpdateKeypad,
} from '../../../../tagging-tool/service/keypad.service';
import { sortArray } from '../../../../tagging-tool/utility/arrays';

export type State = {
  loading: boolean;
  fetching?: boolean;
  creating?: boolean;
  showNameModal?: boolean;
  showDeleteModal?: boolean;
  data?: FetchKeypadsResponse;
  targetKeypadData?: FetchKeypadsItem;
  cloningData?: { id?: string; name: string };
  updatingData?: FetchUpdateKeypadBody;
  deletingData?: { id: string };
};

export enum ActionType {
  SET_DATA,
  UPDATE_NAME,
  SET_DEFAULT,
  NEW_KEYPAD,
  CLONE_KEYPAD,
  SUBMIT,
  SUBMIT_UPDATE_SUCCESS,
  SUBMIT_CREATE_SUCCESS,
  CANCEL_NAME_MODAL,
  CLOSED_NAME_MODAL,
  DELETE,
  CANCEL_DELETE_MODAL,
  CLOSED_DELETE_MODAL,
  SUBMIT_DELETE,
  SUBMIT_DELETE_SUCCESS,
  ERROR,
}

export type Actions =
  | { type: ActionType.SET_DATA; payload: FetchKeypadsResponse }
  | { type: ActionType.UPDATE_NAME; payload: FetchKeypadsItem }
  | { type: ActionType.SET_DEFAULT; payload: FetchKeypadsItem }
  | { type: ActionType.NEW_KEYPAD }
  | { type: ActionType.CLONE_KEYPAD; payload: FetchKeypadsItem }
  | { type: ActionType.SUBMIT; payload: string }
  | { type: ActionType.SUBMIT_UPDATE_SUCCESS }
  | { type: ActionType.SUBMIT_CREATE_SUCCESS; payload: FetchKeypadResponse }
  | { type: ActionType.CANCEL_NAME_MODAL }
  | { type: ActionType.CLOSED_NAME_MODAL }
  | { type: ActionType.DELETE; payload: FetchKeypadsItem }
  | { type: ActionType.CANCEL_DELETE_MODAL }
  | { type: ActionType.CLOSED_DELETE_MODAL }
  | { type: ActionType.SUBMIT_DELETE }
  | { type: ActionType.SUBMIT_DELETE_SUCCESS; payload: string }
  | { type: ActionType.ERROR };

const reducer = (state: State, action: Actions): State => {
  if (action.type === ActionType.SET_DATA) {
    return {
      ...state,
      loading: false,
      data: sortArray({ data: action.payload, key: 'name' }),
    };
  } else if (action.type === ActionType.UPDATE_NAME) {
    return {
      ...state,
      showNameModal: true,
      creating: false,
      targetKeypadData: action.payload,
    };
  } else if (action.type === ActionType.SET_DEFAULT) {
    const current = action.payload;
    const data: FetchKeypadsResponse = state.data!.map((keypad) => {
      const isDefault = !current.isDefault ? keypad.isDefault : keypad.id === current.id;
      return {
        ...keypad,
        isDefault,
      };
    });
    return { ...state, data: sortArray({ data, key: 'name' }) };
  } else if (action.type === ActionType.NEW_KEYPAD) {
    return {
      ...state,
      showNameModal: true,
      creating: true,
      targetKeypadData: undefined,
    };
  } else if (action.type === ActionType.CLONE_KEYPAD) {
    return {
      ...state,
      showNameModal: true,
      creating: true,
      targetKeypadData: action.payload,
    };
  } else if (action.type === ActionType.SUBMIT) {
    if (state.fetching === true) {
      return state;
    } else if (state.creating !== true) {
      // just updating name
      const bodyUpdate: FetchKeypadsItem = {
        ...state.targetKeypadData!,
        name: action.payload,
      };
      return { ...state, fetching: true, updatingData: bodyUpdate };
    } else if (state.creating) {
      // creating
      return {
        ...state,
        fetching: true,
        cloningData: {
          id: state.targetKeypadData?.id,
          name: action.payload,
        },
      };
    }
  } else if (action.type === ActionType.SUBMIT_UPDATE_SUCCESS) {
    const current = state.updatingData!;
    const data: FetchKeypadsResponse = state.data!.map((keypad) => {
      const name = current.id === keypad.id ? current.name : keypad.name;
      return {
        ...keypad,
        name,
      };
    });
    return {
      ...state,
      data: sortArray({ data, key: 'name' }),
      fetching: false,
      showNameModal: false,
    };
  } else if (action.type === ActionType.SUBMIT_CREATE_SUCCESS) {
    const { id, name, isDefault, tags } = action.payload;
    const data: FetchKeypadsResponse = [...state.data!, { id, name, isDefault, nTags: tags.length }];
    return {
      ...state,
      data: sortArray({ data, key: 'name' }),
      fetching: false,
      showNameModal: false,
    };
  } else if (action.type === ActionType.CANCEL_NAME_MODAL) {
    return { ...state, showNameModal: false };
  } else if (action.type === ActionType.CLOSED_NAME_MODAL) {
    return { ...state, targetKeypadData: undefined };
  } else if (action.type === ActionType.DELETE) {
    return { ...state, showDeleteModal: true, targetKeypadData: action.payload };
  } else if (action.type === ActionType.CANCEL_DELETE_MODAL) {
    return { ...state, showDeleteModal: false };
  } else if (action.type === ActionType.CLOSED_DELETE_MODAL) {
    return { ...state, targetKeypadData: undefined };
  } else if (action.type === ActionType.SUBMIT_DELETE) {
    return {
      ...state,
      fetching: true,
      deletingData: { id: state.targetKeypadData!.id },
    };
  } else if (action.type === ActionType.SUBMIT_DELETE_SUCCESS) {
    const data = state.data!.filter((keypad) => keypad.id !== action.payload).map((keypad) => ({ ...keypad }));

    return {
      ...state,
      data: sortArray({ data, key: 'name' }),
      fetching: false,
      showDeleteModal: false,
      targetKeypadData: undefined,
      deletingData: undefined,
    };
  } else if (action.type === ActionType.ERROR) {
    return {
      ...state,
      loading: false,
      fetching: false,
      creating: false,
      showNameModal: false,
      showDeleteModal: false,
    };
  }
  return state;
};

export const useKeypadListScreenState = (): [State, Dispatch<Actions>] => {
  const [state, dispatch] = useReducer(reducer, { loading: true });

  // on mount => fetch keypads

  useEffect(() => {
    fetchKeypads().then((res) => {
      if (res.error) {
        return dispatch({ type: ActionType.ERROR });
      }
      return dispatch({ type: ActionType.SET_DATA, payload: res.data });
    });
  }, []);

  // effect submit update

  useEffect(() => {
    if (!state.updatingData) {
      return;
    }

    fetchUpdateKeypad(state.updatingData).then((res) => {
      if (res.error) {
        return dispatch({ type: ActionType.ERROR });
      }

      dispatch({ type: ActionType.SUBMIT_UPDATE_SUCCESS });
    });
  }, [state.updatingData]);

  // effect submit cloning

  useEffect(() => {
    if (!state.cloningData) {
      return;
    }

    const { id, name } = state.cloningData;
    if (!id) {
      // new keypad based on generic
      fetchCreateKeypad({ name: name }).then((res) => {
        if (res.error) {
          return dispatch({ type: ActionType.ERROR });
        }

        dispatch({
          type: ActionType.SUBMIT_CREATE_SUCCESS,
          payload: { ...res.data },
        });
      });
      return;
    }

    fetchDuplicateKeypad({ id: id, name: name }).then((res) => {
      if (res.error) {
        return dispatch({ type: ActionType.ERROR });
      }

      dispatch({
        type: ActionType.SUBMIT_CREATE_SUCCESS,
        payload: { ...res.data },
      });
    });
  }, [state.cloningData]);

  // effect submit delete

  useEffect(() => {
    if (!state.deletingData) {
      return;
    }

    const { id } = state.deletingData!;

    fetchDeleteKeypad({ id }).then((res) => {
      if (res.error) {
        return dispatch({ type: ActionType.ERROR });
      }
      dispatch({
        type: ActionType.SUBMIT_DELETE_SUCCESS,
        payload: id,
      });
    });
  }, [state.deletingData]);

  return [state, dispatch];
};
