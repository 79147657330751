import classNames from 'classnames';
import React from 'react';

import { TeamTypes } from 'pages/tactical-analysis/api/types';
import IconClose from 'shared/components/icons/icon-close';
import { IconSizes } from 'shared/components/icons/svg-icon/SvgIcon';

import styles from './ActiveFilter.module.scss';

export enum DefaultVariant {
  DEFAULT = 'default',
}

export type ActiveFilterTypes = TeamTypes | DefaultVariant;

interface Props {
  action?: string;
  size?: IconSizes;
  closeButton?: boolean;
  contained?: boolean;
  logo?: React.ReactNode;
  onClose?: () => void;
  title?: string | React.ReactNode;
  variant?: TeamTypes | DefaultVariant;
  hasTeamFocus?: boolean;
}

const ActiveFilter = ({
  action,
  closeButton = false,
  logo,
  title,
  onClose,
  size,
  variant = DefaultVariant.DEFAULT,
  contained = false,
  hasTeamFocus = false,
}: Props) => {
  return (
    <label
      className={classNames(
        styles.container,
        styles[variant],

        styles[size ? size : 'medium'],
        {
          [styles.teamFocus]: hasTeamFocus,
          [styles.contained]: contained,
          [styles.outlined]: !contained,
          [styles.logo]: logo || !title,
          [styles.empty]: !logo && !title && !action,
          [styles.noAction]: !closeButton,
        },
      )}
    >
      {logo && logo}
      {title && title}
      {action && <span className={styles.action}>{action}</span>}
      {closeButton && (
        <IconClose
          onClick={onClose}
          color='secondary'
          isButton
          size={size === 'small' || size === 'xsmall' ? 'xsmall' : 'small'}
        />
      )}
    </label>
  );
};

export default ActiveFilter;
