import { styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import { Link } from 'react-router-dom';

export const NotificationLink = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  color: theme.palette.common.white,
  '&::after': {
    backgroundColor: theme.palette.common.white,
  },
  '&:focus': {
    //TODO use theme color
    color: Colors.ghost,
  },
  '&:hover': {
    //TODO use theme color
    color: Colors.ghost,
  },
}));
