import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { useState } from 'react';

import { IconTimeline } from 'shared/components/icons';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';

import { MetadataPlayerSummary } from '../../../../types/players';
import { EditPlayerModal } from '../edit-player-modal';
import { PlayerDetailsModal } from '../player-details-modal';
import { PlayerPhoto } from '../player-photo/player-photo';
import { PlayerPhotoSkeleton } from '../player-photo-skeleton';
import { PlayersListItem } from '../players-list/styled';

interface Props {
  player: MetadataPlayerSummary;
}

export const PlayerItem = ({ player }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);
  const handleDetailsModalOpen = () => setIsDetailsModalOpen(true);
  const handleDetailsModalClose = () => setIsDetailsModalOpen(false);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleDetailsModalOpen,
    },
  ];

  return (
    <>
      <PlayersListItem key={player.id}>
        {player.photoUrl ? <PlayerPhoto src={player.photoUrl} /> : <PlayerPhotoSkeleton small />}
        <Typography marginLeft={2} fontWeight={fontWeight['500']}>
          {player.name}
        </Typography>
        <KebabMenu options={options} />
      </PlayersListItem>
      {isEditModalOpen ? (
        <EditPlayerModal open={isEditModalOpen} onClose={handleEditModalClose} player={player} playerId={player.id} />
      ) : null}
      {isDetailsModalOpen && player?.fixtures ? (
        <PlayerDetailsModal playerDetails={player.fixtures} onClose={handleDetailsModalClose} />
      ) : null}
    </>
  );
};
