import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useDuplicatePlaylist } from 'api/playlist/useDuplicatePlaylist';
import { usePlaylists } from 'api/playlist/useFetchPlaylists';
import { routes } from 'kognia/router/routes';
import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog/dialog-actions';
import { DialogButtonContainer } from 'shared/components/dialog/dialog-button-container';
import { DialogContent } from 'shared/components/dialog/dialog-content';
import { DialogContentText, DialogTextVariants } from 'shared/components/dialog/dialog-content-text';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import IconShare from 'shared/components/icons/icon-share';

interface Props {
  onClose: () => void;
  playlistId: string;
  playlistUserName: string;
  playlistName: string;
}

export const CopyPlaylistModal = ({ onClose, playlistId, playlistUserName, playlistName }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { invalidateQuery } = usePlaylists({});
  const { duplicatePlaylist } = useDuplicatePlaylist({
    playlistId,
    successMessage: t('playlists:copy-playlist.success-message', { playlistName }),
  });

  const handleSubmit = useCallback(() => {
    duplicatePlaylist({
      name: playlistName,
      onSuccess: invalidateQuery,
      onSettled: () => {
        onClose();
        history.replace(routes.PLAYLISTS);
      },
    });
  }, [duplicatePlaylist, history, invalidateQuery, onClose, playlistName]);

  const handleCancel = useCallback(() => {
    onClose();
    history.replace(routes.PLAYLISTS);
  }, [history, onClose]);

  return (
    <Dialog open={true} maxWidth='sm' fullWidth>
      <DialogHeader icon={<IconShare size='small' />}>{t('playlists:copy-playlist.modal-title')}</DialogHeader>
      <DialogContent>
        <DialogContentText textVariant={DialogTextVariants.Primary}>
          {t('playlists:copy-playlist.modal-text-first', { playlistName, playlistUserName: playlistUserName })}
        </DialogContentText>
        <DialogContentText>{t('playlists:copy-playlist.modal-text-second')}</DialogContentText>
        <DialogContentText textVariant={DialogTextVariants.Primary}>
          {t('playlists:copy-playlist.modal-text-third')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButtonContainer>
          <Button variant='outlined' color='secondary' onClick={handleCancel}>
            {t('common:actions.cancel')}
          </Button>
        </DialogButtonContainer>
        <DialogButtonContainer>
          <Button variant='contained' onClick={handleSubmit}>
            {t('common:actions.add')}
          </Button>
        </DialogButtonContainer>
      </DialogActions>
    </Dialog>
  );
};
