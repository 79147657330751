import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isFunction } from 'lodash';
import { ReactNode } from 'react';

import { BackendApiRequestTypes, useBackendApi } from 'api/hooks/useBackendApi';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

import { HTTPMethod } from '../../types';

export interface MutationRequestParams<TApiData, TData, TApiError> {
  transformer?: (response: TApiData) => void;
  onSuccess?: (response: TData) => void;
  onError?: (data: AxiosError<TApiError>) => void;
  onSettled?: () => void;
  errorMessage?: string | ((data: TApiError | undefined) => string | ReactNode);
  successMessage?: string;
  type?: BackendApiRequestTypes;
}

type Params = {
  url: string;
  data?: {
    [key: string]: any;
  };
};

export const useMutationRequest = <TApiData, TData, TApiError = unknown>({
  onSuccess,
  onError,
  onSettled,
  transformer = (response: TApiData) => response,
  errorMessage,
  successMessage,
  type = HTTPMethod.POST,
}: MutationRequestParams<TApiData, TData, TApiError>): UseMutationResult<TData, unknown, Params, any> => {
  const triggerNotification = useNotifications();

  return useMutation(
    ({ url, data = {} }: Params) => {
      return useBackendApi(url, type, transformer, data);
    },
    {
      onMutate: async (params: any) => {
        return params;
      },
      onError: (error: AxiosError<TApiError>) => {
        if (onError) onError(error);
        if (errorMessage) {
          triggerNotification({
            type: NotificationType.ERROR,
            message: isFunction(errorMessage) ? errorMessage(error.response?.data) : errorMessage,
          });
        }
      },
      onSuccess: (response: any) => {
        if (onSuccess) onSuccess(response);
        if (successMessage) {
          triggerNotification({ type: NotificationType.SUCCESS, message: successMessage });
        }
      },
      onSettled: () => {
        if (onSettled) onSettled();
      },
    },
  );
};
