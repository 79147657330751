import { MouseEvent } from 'react';

import { applyResizeLimits } from './apply-resize-limits';
import { ResizeInterface } from '../types';

const resizer: ResizeInterface<MouseEvent> = (
  event,
  mainPanelElement,
  secondaryPanelElement,
  mainPanelMinSize,
  secondaryPanelMinSize,
  direction,
  callBack,
  start,
  end,
) => {
  const directionChangeProperty = direction === 'horizontal' ? 'screenX' : 'pageY';
  const updateProperty = direction === 'horizontal' ? 'width' : 'height';

  const previousPosition = event[directionChangeProperty];
  const mainPanel = mainPanelElement.getBoundingClientRect();
  const secondaryPanel = secondaryPanelElement.getBoundingClientRect();
  start();

  function mousemove(event: globalThis.MouseEvent) {
    const newPosition = previousPosition - event[directionChangeProperty];

    mainPanelElement.style[updateProperty] =
      applyResizeLimits(
        mainPanel[updateProperty] - (direction === 'vertical' ? -newPosition : newPosition),
        secondaryPanel[updateProperty] + (direction === 'vertical' ? -newPosition : newPosition),
        mainPanelMinSize,
        secondaryPanelMinSize,
      ) + 'px';
  }

  function mouseup() {
    end();
    const values = mainPanelElement.getBoundingClientRect();
    callBack(values[updateProperty]);

    window.removeEventListener('mousemove', mousemove);
    window.removeEventListener('mouseup', mouseup);
    window.removeEventListener('resize', mouseup);
  }

  window.addEventListener('mousemove', mousemove);
  window.addEventListener('mouseup', mouseup);
  window.addEventListener('resize', mouseup);
};

export default resizer;
