import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconAnalysis = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M19.871 11.454c-.36 0-.698.126-.973.315l-4.126-2.731c.021-.126.042-.252.042-.378A1.67 1.67 0 0 0 13.143 7c-.91 0-1.671.756-1.671 1.66 0 .315.085.609.233.861l-2.793 4.16c-.085-.021-.169-.021-.254-.021-.254 0-.508.063-.719.168l-2.243-2.227c.106-.21.169-.462.169-.714a1.67 1.67 0 0 0-1.671-1.66c-.91 0-1.693.735-1.693 1.66a1.67 1.67 0 0 0 1.671 1.66c.254 0 .508-.063.719-.168l2.264 2.248c-.106.21-.169.462-.169.714A1.67 1.67 0 0 0 8.657 17c.91 0 1.671-.756 1.671-1.66 0-.441-.169-.819-.444-1.113l2.666-3.992c.169.063.36.105.571.105.381 0 .741-.126 1.037-.357l4.062 2.689a1.59 1.59 0 0 0-.064.441 1.67 1.67 0 1 0 3.343 0c0-.903-.719-1.66-1.629-1.66z' />
    </SvgIcon>
  );
};

export default IconAnalysis;
