import { Dialog } from '@mui/material';
import { useEffect, useMemo, useRef } from 'react';

import IconClose from 'shared/components/icons/icon-close';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import { TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { PlaylistItemType, VideoSourceType } from 'shared/components/video-player/types';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { defaultVideoAspectRatio } from 'shared/components/video-player/video-player-component/hooks/use-video-aspect-ratio';

import { ClipPlayerDialogIconButton } from './styled';
import { ClipPlayerData, TaggingToolVideoElement } from '../../types';

export type ClipPlayerModalProps = {
  data: ClipPlayerData;
  onCancel?: () => void;
  videoTypes: VideoSourceType[];
};

const transformTaggingEventJobToPlaylistItem = (
  data: ClipPlayerData,
  videoTypes: VideoSourceType[],
): PlaylistItemType => {
  const calcStartTime = data.event.time - data.event.timeBefore;
  const startTime = calcStartTime < 0 ? 0 : calcStartTime;
  const endTime = data.event.time + data.event.timeAfter;
  const duration = endTime - startTime;

  const videoTypesPreferred: VideoSourceType[] = videoTypes.map((videoType) => {
    return {
      ...videoType,
      videoSources: videoType.videoSources.map((videoSource) => {
        return {
          ...videoSource,
          startTime,
          endTime,
        };
      }),
      playingMode: videoType.playingMode,
    };
  });

  return {
    id: 'playing-tagging-tool-event',
    duration,
    videoTypes: videoTypesPreferred,
    fundamentalsSelected: {
      tacticalAnalysisId: undefined,
      fundamentalsSelected: [],
    },
    hasHomographies: false,
    recordingId: data.event.recordingId,
  };
};

export const ClipPlayerDialog = (props: ClipPlayerModalProps) => {
  const playerElementRef = useRef<TaggingToolVideoElement>();

  const meta = useMemo<{ src: string; min: number; max: number } | undefined>(() => {
    const { event } = props.data;
    const { timeBefore, time, timeAfter } = event;

    const min = Math.max(0, Math.round(time - timeBefore));
    const max = Math.round(time + timeAfter);

    return { src: `${props.data.src}#t=${min},${max}`, min, max };
  }, [props.data]);

  useEffect(() => {
    if (!meta) {
      return;
    }

    const interval = setInterval(() => {
      if (!playerElementRef.current) {
        return;
      }

      const { currentTime } = playerElementRef.current;
      const { min, max } = meta;
      if (currentTime < min || currentTime > max) {
        playerElementRef.current.currentTime = min;
      }
    }, 911);
    return () => {
      clearInterval(interval);
    };
  }, [meta]);

  return (
    <Dialog open={true} onClose={props.onCancel} fullWidth maxWidth='lg'>
      <VideoPlayerStateProvider
        playerId={`clip-player-${props.data.id}`}
        playlistItems={[transformTaggingEventJobToPlaylistItem(props.data, props.videoTypes)]}
        playingMode={TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE}
      >
        <div style={{ aspectRatio: defaultVideoAspectRatio }}>
          <VideoPlayerComponent />
        </div>
      </VideoPlayerStateProvider>

      <ClipPlayerDialogIconButton onClick={props.onCancel}>
        <IconClose size='small' sx={{ color: 'common.white' }} />
      </ClipPlayerDialogIconButton>
    </Dialog>
  );
};
