import classNames from 'classnames';
import { motion, usePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { useDeletePlaylist } from 'api/playlist/useDeletePlaylist';
import { useFetchRecordingPlaylists } from 'api/playlist/useFetchRecordingPlaylists';
import { routes } from 'kognia/router/routes';
import Card from 'shared/components/card';
import IconCopy from 'shared/components/icons/icon-copy';
import IconDelete from 'shared/components/icons/icon-delete';
import IconPlaylist from 'shared/components/icons/icon-playlist';
import IconShare from 'shared/components/icons/icon-share';
import IconTime from 'shared/components/icons/icon-time';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import SharePlaylistModal from 'shared/components/share-playlist-modal';
import useConfirmPopoverDialog from 'shared/hooks/use-confirm-popover-dialog';
import { Playlist } from 'shared/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import styles from './PlaylistCard.module.scss';
import DuplicatePlaylistDialog from '../../duplicate-playlist-dialog';

interface Props {
  playlist: Playlist;
  recordingId: string;
}

const PlaylistCard = ({ playlist, recordingId }: Props) => {
  const { t } = useTranslation();
  const { invalidateQuery } = useFetchRecordingPlaylists(recordingId, false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [isPresent, safeToRemove] = usePresence();
  const { isOpen, setIsOpen, ConfirmPopoverDialog } = useConfirmPopoverDialog();
  const { deletePlaylist, isError, isSuccess } = useDeletePlaylist(playlist.id);

  const [isDeleted, setIsDeleted] = useState(false);
  const history = useHistory();
  const route = generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: playlist.id });

  useEffect(() => {
    if (isSuccess) {
      invalidateQuery && invalidateQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const kebabRef = useRef<HTMLDivElement | null>(null);
  const variants = {
    in: { opacity: 1 },
    out: { opacity: 0, transition: { delay: 0.2, duration: 0.2, type: 'tween' } },
  };

  const closeModal = () => {
    setShareModalOpen(false);
  };

  const onClickShare = () => {
    setShareModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deletePlaylist();
    setIsOpen(false);
    setIsDeleted(true);
  };

  const openDuplicateModal = () => {
    setDuplicateModalOpen(true);
  };

  const menuOptions = [
    {
      displayText: t('recording-playlists:share-modal.share-playlist'),
      icon: <IconShare size='small' color='secondary' />,
      onClick: onClickShare,
    },
    {
      displayText: t('common:actions.delete'),
      icon: <IconDelete size='small' color='secondary' />,
      onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        event && event.stopPropagation();
        setIsOpen(true);
      },
    },
    {
      displayText: t('common:actions.duplicate'),
      icon: <IconCopy size='small' color='secondary' />,
      onClick: openDuplicateModal,
    },
  ] as MenuListOption[];

  useEffect(() => {
    if (isError) setIsDeleted(false);
  }, [isError]);

  return (
    <motion.div
      layout
      initial={'in'}
      animate={isPresent ? 'in' : 'out'}
      variants={variants}
      onAnimationComplete={() => !isPresent && safeToRemove && safeToRemove()}
    >
      <div className={styles.cardContainer}>
        <Card
          className={classNames({ [styles.deleting]: isDeleted || !isPresent })}
          onClick={() => history.push(route)}
        >
          <div className={styles.kebabContainer}>
            <KebabMenu ref={kebabRef} options={menuOptions} />
          </div>
          <div className={styles.headerContainer}>
            <header>
              <Link className={styles.name} to={route}>
                {playlist.name}
              </Link>
              <IconTime size='small' /> {secondsAsDuration(playlist.duration, true)}
            </header>
          </div>
          <div className={styles.clipCount}>
            <IconPlaylist size='small' />
            {playlist.playlistItems.length} {t('recording-playlists:clip', { count: playlist.playlistItems.length })}
          </div>
        </Card>
        {shareModalOpen && <SharePlaylistModal playlist={playlist} onCloseModal={closeModal} />}
        {duplicateModalOpen && (
          <DuplicatePlaylistDialog
            onUpdate={invalidateQuery}
            isOpen={duplicateModalOpen}
            onClose={() => {
              setDuplicateModalOpen(false);
            }}
            playlist={playlist}
          />
        )}
        <ConfirmPopoverDialog
          anchorEl={kebabRef.current}
          cancelLabel={t('common:actions.cancel')}
          confirmLabel={t('common:actions.delete')}
          description={t('recording-playlists:delete-confirm-modal.confirm-message')}
          isOpen={isOpen}
          onConfirm={handleConfirmDelete}
          setIsOpen={setIsOpen}
          buttonColor='warning'
        />
      </div>
    </motion.div>
  );
};

export default PlaylistCard;
