import { useCallback } from 'react';

import { useIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { PlaylistItemType } from 'shared/components/video-player/types';

import { useHandleSelect } from './useHandleSelect';
import { useHandleSetPlaylistItem } from './useHandleSetPlaylistItem';

export const useHandlePlaylistItemClick = (playlistItem: PlaylistItemType, playlistId: string) => {
  const handleSelectItem = useHandleSelect(playlistId);
  const enabledBulkMode = useIsBulkModeActive(playlistId);
  const handleSetPlaylistItem = useHandleSetPlaylistItem(playlistItem);

  return useCallback(
    (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event && event.stopPropagation();
      if (enabledBulkMode) {
        return handleSelectItem(playlistItem.id);
      }

      return handleSetPlaylistItem(true);
    },
    [enabledBulkMode, handleSetPlaylistItem, handleSelectItem, playlistItem.id],
  );
};
