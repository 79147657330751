import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';

import { useCreateStage } from '../../api/stages/use-create-stage';
import { useUpdateStage } from '../../api/stages/use-update-stage';
import { StagesForm } from '../../pages/stages/components/stage-form';
import { stageFormSchema } from '../../pages/stages/components/stage-form/form';
import { StageForm, StageFormSchema } from '../../pages/stages/components/stage-form/types';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: StageForm;
  onSuccess?: () => void;
}

const stagesFormId = 'stages-form';

export const StageModal = ({ open, onClose, defaultValues, onSuccess }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<StageFormSchema>({
    resolver: zodResolver(stageFormSchema),
    defaultValues,
  });

  const { create } = useCreateStage();
  const { update } = useUpdateStage();

  const handleCreate = useCallback(
    (data: StageForm, onSuccess?: () => void) => {
      if (!data.season) return;

      create({
        seasonId: data.season.id,
        data: {
          name: data.name,
          order_index: data.orderIndex,
          ...(data?.parentStageId ? { parent_stage_uuid: data.parentStageId } : {}),
        },
        onSuccess: () => {
          reset();
          onSuccess && onSuccess();
          onClose();
        },
      });
    },
    [create, onClose, reset],
  );

  const handleUpdate = useCallback(
    (data: StageForm, onSuccess?: () => void) => {
      if (!data?.id) return;
      if (!data.season) return;

      const { id, ...rest } = data;
      update({
        data: {
          name: rest.name,
          season_uuid: data.season.id,
          order_index: data.orderIndex,
          ...(rest?.parentStageId ? { parent_stage_uuid: rest.parentStageId } : {}),
        },
        stageId: id,
        onSuccess: () => {
          onSuccess && onSuccess();
          onClose();
        },
      });
    },
    [onClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: StageForm) => {
      if (defaultValues) {
        handleUpdate(data, onSuccess);
      } else {
        handleCreate(data, onSuccess);
      }
    },
    [defaultValues, handleCreate, handleUpdate, onSuccess],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit stage' : 'New stage'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={stagesFormId}
    >
      <form id={stagesFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <StagesForm<StageFormSchema> watch={watch} register={register} errors={errors} control={control} />
      </form>
    </DialogNew>
  );
};
