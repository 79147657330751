import { Stack, Tooltip, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { PlaylistActions } from 'entities/playlist/ui/PlaylistActions';
import { PlaylistContainerGrid } from 'entities/playlist/ui/PlaylistContainerGrid';
import { PlaylistDetails } from 'entities/playlist/ui/PlaylistDetails';
import { PlaylistHeaderGrid } from 'entities/playlist/ui/PlaylistHeaderGrid';
import { PlaylistTimelineContainer } from 'entities/playlist/ui/PlaylistTimelineContainer';
import { PlaylistTimelineHeader } from 'entities/playlist/ui/PlaylistTimelineHeader';
import { PlaylistVideoPlayerContainer } from 'entities/playlist/ui/PlaylistVideoPlayerContainer';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { DownloadCurrentPlaylistItemModal } from 'features/playlist/DownloadCurrentPlaylistItemModal';
import { EnableBulkModeButton } from 'features/playlist/EnableBulkModeButton';
import { PlaylistOverlaysSelectorPanelFeature } from 'features/playlist/playlist-overlay-selector-panel/PlaylistOverlaySelectorPanel.feature';
import { SaveTemporaryPlaylistFeature } from 'features/playlist/save-temporary-playlist/SaveTemporaryPlaylist.feature';
import { TemporaryPlaylistItemsList } from 'features/playlist/temporary-playlist-items-list/TemporaryPlaylistItemsList';
import { IconEye } from 'shared/components/icons/icon-eye';
import { IconInfo } from 'shared/components/icons/icon-info';
import { PlaylistTitle } from 'shared/components/PlaylistTitle';
import { useCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { Playlist } from 'shared/types';

type Props = {
  playlist: Playlist;
};

export const TemporaryPlaylist = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const actions = useVideoPlayerActions();
  const playlistItem = useCurrentPlaylistItem();
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isCurrentItemDownloadModalOpen, setIsCurrentItemDownloadModalOpen] = useState(false);

  const handleDownloadModalClose = useCallback(() => setIsDownloadModalOpen(false), []);

  const handleOpenDownloadCurrentItemModal = useCallback(() => {
    actions.handleStandBy();
    setIsCurrentItemDownloadModalOpen(true);
  }, [actions]);

  const handleCloseDownloadCurrentItemModal = useCallback(() => {
    actions.resumeStandBy();
    setIsCurrentItemDownloadModalOpen(false);
  }, [actions]);

  return (
    <PlaylistContainerGrid container>
      <PlaylistHeaderGrid xs={12} item>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} flex={1}>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <IconEye size={'small'} />
            <PlaylistTitle title={playlist.name} />
            <Tooltip
              placement={'right'}
              title={
                <Stack gap={1}>
                  {t('playlist-detail:read-only-playlist.read-only-info-title')}
                  <Typography variant={'body2'}>
                    {t('playlist-detail:read-only-playlist.read-only-info-description')}
                  </Typography>
                </Stack>
              }
            >
              <span>
                <IconInfo size={'small'} />
              </span>
            </Tooltip>
          </Stack>
          <SaveTemporaryPlaylistFeature playlist={playlist} />
        </Stack>
      </PlaylistHeaderGrid>
      <PlaylistVideoPlayerContainer display='flex' item xs={12}>
        <VideoPlayerComponent onDownloadSource={handleOpenDownloadCurrentItemModal} showVideoPlayerBar />
        {customOverlaysFeatureFlag && playlistItem.hasHomographies ? (
          <PlaylistOverlaysSelectorPanelFeature key={playlistItem.id} playlistId={playlist.id} readOnly />
        ) : null}
      </PlaylistVideoPlayerContainer>
      <PlaylistTimelineContainer item xs={12}>
        <PlaylistTimelineHeader>
          <PlaylistDetails playlist={playlist} />
          <PlaylistActions>
            <EnableBulkModeButton playlistId={playlist.id} />
          </PlaylistActions>
        </PlaylistTimelineHeader>

        <TemporaryPlaylistItemsList playlist={playlist} />
      </PlaylistTimelineContainer>

      {isDownloadModalOpen ? (
        <DownloadPlaylistItemsModal onClose={handleDownloadModalClose} playlistId={playlist.id} />
      ) : null}
      <DownloadCurrentPlaylistItemModal
        playlistId={playlist.id}
        isOpen={isCurrentItemDownloadModalOpen}
        onClose={handleCloseDownloadCurrentItemModal}
      />
    </PlaylistContainerGrid>
  );
};
