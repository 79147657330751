import { Box, styled } from '@mui/material';

export const DownloadModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxWidth: '510px',
  width: '100%',
  padding: theme.spacing(3, 4.5),
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  transform: 'translate(-50%, -50%)',
}));
