import { Button, Grid, Radio } from '@mui/material';
import queryString from 'query-string';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import VODRecordingCard from 'pages/recordings-list/components/vod-recording-card';
import { PageSubTitle } from 'shared/components/page-sub-title';
import { PageTitle } from 'shared/components/page-title';
import { Recording } from 'shared/types/recording/types';
import { makeRecordingAlignmentRoute } from 'tagging-tool/utility/navigation';

import {
  AlignmentSourceActions,
  AlignmentSourceRecordingCard,
  AlignmentSourceRecordingList,
  AlignmentSourceRecordingListItem,
} from './styled';

export type AlignmentSourceListProps = {
  recordings: Recording[];
};

export const AlignmentSourceList = ({ recordings }: AlignmentSourceListProps) => {
  const history = useHistory();
  const { recordingId } = useParams<{ recordingId: string }>();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { isLive } = queryString.parse(search);

  const [selected, setSelected] = useState<Recording | undefined>();

  const handlePress = useCallback(() => {
    history.replace(
      makeRecordingAlignmentRoute({
        recordingId,
        sourceRecordingId: selected!.id,
        isLive: isLive === 'true',
      }),
    );
  }, [history, recordingId, selected, isLive]);

  const handleCancel = useCallback(() => history.push(routes.TAGGING_TOOL), [history]);

  return (
    <>
      {recordings !== undefined && (
        <Grid container direction={'column'} spacing={2} wrap={'nowrap'}>
          <Grid item>
            <PageTitle>{t('tagging-tool:tagging-recording.videos')}</PageTitle>
            <PageSubTitle>{t('tagging-tool:tagging-recording.select-alignment-video-source')}</PageSubTitle>
          </Grid>
          <Grid item marginTop={1}>
            <AlignmentSourceRecordingList>
              {recordings.map((recording) => (
                <AlignmentSourceRecordingListItem key={recording.id}>
                  <Radio
                    id={recording.id}
                    checked={selected?.id === recording.id}
                    onChange={(value) => {
                      if (value) {
                        setSelected(recording);
                      }
                    }}
                  />
                  <AlignmentSourceRecordingCard>
                    <VODRecordingCard hideActions recording={recording} key={recording.id} />
                  </AlignmentSourceRecordingCard>
                </AlignmentSourceRecordingListItem>
              ))}
            </AlignmentSourceRecordingList>
          </Grid>
        </Grid>
      )}
      <AlignmentSourceActions>
        <Button variant='contained' color='secondary' size='large' onClick={handleCancel}>
          {t('common:actions.cancel')}
        </Button>
        <Button onClick={handlePress} variant='contained' size='large'>
          {t('common:actions.next')}
        </Button>
      </AlignmentSourceActions>
    </>
  );
};
