import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { Colors, fontSizes } from 'kognia-ui';
import isEmpty from 'lodash/isEmpty';
import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDownloadPlaylist } from 'api/playlist/useDownloadPlaylist';
import { useDownloadPlaylistItems } from 'api/playlist/useDownloadPlaylistItems';
import { useDownloadPlaylistXml } from 'api/playlist/useDownloadPlaylistXml';
import IconClose from 'shared/components/icons/icon-close';
import { IconDownload } from 'shared/components/icons/icon-download';

import styles from './DownloadModal.module.scss';
import { DownloadModalWrapper } from './ui/download-modal-wrapper/DownloadModalWrapper';

interface Props {
  isPlaylistDownload?: boolean;
  itemsToDownload?: string[];
  onClose: () => void;
  playlistId: string;
}

const DOWNLOAD_TYPE = {
  SINGLE_VIDEO_FILE: 'single-video-file',
  MULTIPLE_VIDEO_FILES: 'multiple-video-files',
};

const DOWNLOAD_BUTTON_MIN_WIDTH = 136;

export const DownloadPlaylistItemsModal = ({
  onClose,
  playlistId,
  isPlaylistDownload = true,
  itemsToDownload,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [showOverlays, setShowOverlays] = React.useState(true);
  const [downloadTypeValue, setDownloadTypeValue] = React.useState(DOWNLOAD_TYPE.SINGLE_VIDEO_FILE);
  const [showTitles, setShowTitles] = React.useState(true);
  const [exportXml, setExportXml] = React.useState(false);
  const downloadPlaylist = useDownloadPlaylist();
  const downloadPlaylistItems = useDownloadPlaylistItems();
  const downloadPlaylistXml = useDownloadPlaylistXml(playlistId, itemsToDownload ?? []);

  const isSinglePlaylistItemDownload = itemsToDownload?.length === 1;

  const handleSubmit = useCallback(() => {
    const joinSources = isSinglePlaylistItemDownload ? false : downloadTypeValue === DOWNLOAD_TYPE.SINGLE_VIDEO_FILE;

    if (isPlaylistDownload) {
      downloadPlaylist({
        playlistId,
        joinSources,
        showOverlays,
        showTitles,
      });
    } else if (!isPlaylistDownload && itemsToDownload && !isEmpty(itemsToDownload)) {
      downloadPlaylistItems({
        playlistId,
        playlistItems: itemsToDownload,
        joinSources,
        showOverlays,
        showTitles,
      });
    }
    if (exportXml) downloadPlaylistXml();

    onClose();
  }, [
    isSinglePlaylistItemDownload,
    downloadTypeValue,
    showOverlays,
    isPlaylistDownload,
    itemsToDownload,
    onClose,
    downloadPlaylist,
    playlistId,
    showTitles,
    exportXml,
    downloadPlaylistXml,
    downloadPlaylistItems,
  ]);

  const handleDownloadTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadTypeValue((event.target as HTMLInputElement).value);
  }, []);

  const handleShowOverlaysChange = useCallback((event: SyntheticEvent<Element, Event>) => {
    const showOverlays = (event.target as HTMLInputElement).checked;
    setShowOverlays(showOverlays);
  }, []);

  const handleTitleChange = useCallback((event: SyntheticEvent<Element, Event>) => {
    const showTitle = (event.target as HTMLInputElement).checked;
    setShowTitles(showTitle);
  }, []);

  const handleExportXml = useCallback((event: SyntheticEvent<Element, Event>) => {
    const exportXmlChecked = (event.target as HTMLInputElement).checked;
    setExportXml(exportXmlChecked);
  }, []);

  return (
    <Modal open onClose={onClose} aria-labelledby='download-title' aria-describedby='download-description'>
      <DownloadModalWrapper>
        <Box display='flex' flexDirection='column' alignItems='center' marginBottom={4}>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='48px'
            height='48px'
            borderRadius='50%'
            // TODO get from theme
            bgcolor={Colors.background}
          >
            <IconDownload size='small' />
          </Box>

          <Typography
            // TODO use from theme
            fontSize={fontSizes.default}
            className={classNames(styles.modalTitle, styles.title)}
          >
            {t('playlist-detail:download-modal.title')}
          </Typography>
        </Box>

        <Box marginBottom={5}>
          {!isSinglePlaylistItemDownload ? (
            <Box marginBottom={4}>
              <FormControl>
                <div className={styles.title}>{t('playlist-detail:download-modal.choose-download-type')}</div>
                <RadioGroup
                  aria-labelledby='download-type'
                  name='download-type'
                  value={downloadTypeValue}
                  onChange={handleDownloadTypeChange}
                >
                  <FormControlLabel
                    value={DOWNLOAD_TYPE.SINGLE_VIDEO_FILE}
                    control={<Radio color='primary' />}
                    label={
                      <div className={styles.modalRadioLabel}>
                        {t('playlist-detail:download-modal.single-video-file')}
                      </div>
                    }
                  />
                  <FormControlLabel
                    value={DOWNLOAD_TYPE.MULTIPLE_VIDEO_FILES}
                    control={<Radio color='primary' />}
                    label={
                      <div className={styles.modalRadioLabel}>
                        {t('playlist-detail:download-modal.multiple-video-files')}
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          ) : null}
        </Box>

        <Box>
          <div className={styles.title}>{t('playlist-detail:download-modal.choose-preferences')}</div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={showOverlays} />}
              label={
                <Typography fontSize={fontSizes.default}>
                  {t('playlist-detail:download-modal.show-tactical-drawings')}
                </Typography>
              }
              onChange={handleShowOverlaysChange}
            />
            <FormControlLabel
              control={<Checkbox checked={showTitles} />}
              label={
                <Typography fontSize={fontSizes.default}>{t('playlist-detail:download-modal.show-titles')}</Typography>
              }
              onChange={handleTitleChange}
            />
            <FormControlLabel
              control={<Checkbox checked={exportXml} />}
              label={
                <Typography fontSize={fontSizes.default}>{t('playlist-detail:download-modal.export-xml')}</Typography>
              }
              onChange={handleExportXml}
            />
          </FormGroup>
        </Box>

        <Box display='flex' justifyContent='center' marginTop={2} gap={2}>
          <Button variant='outlined' color='secondary' onClick={onClose} sx={{ minWidth: DOWNLOAD_BUTTON_MIN_WIDTH }}>
            {t('common:actions.cancel')}
          </Button>
          <Button variant='contained' sx={{ minWidth: DOWNLOAD_BUTTON_MIN_WIDTH }} onClick={handleSubmit}>
            {t('common:actions.download')}
          </Button>
        </Box>

        <Box position='absolute' top={({ spacing }) => spacing(1)} right={({ spacing }) => spacing(1)}>
          <IconClose size='small' isButton onClick={onClose} />
        </Box>
      </DownloadModalWrapper>
    </Modal>
  );
};
