import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconPause = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M8 6h3v12H8zm6 0h3v12h-3z' />
    </SvgIcon>
  );
};

export default IconPause;
