import React, { memo, useCallback, useRef, useState } from 'react';

import { useHandlePlaylistItemClick } from 'entities/playlist/hooks/useHandlePlaylistItemClick';
import { useHandleSetPlaylistItem } from 'entities/playlist/hooks/useHandleSetPlaylistItem';
import { PlaylistItemWrapper } from 'entities/playlist/ui/PlaylistItemWrapper';
import { generateCopyToPlaylistItem } from 'entities/playlist/util/generateCopyToPlaylistItem';
import { CopyToDialog } from 'features/playlist/CopyToDialog';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { PlaylistItemContent } from 'features/playlist/playlist-item-content/PlaylistItemContent';
import { useIsCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { Playlist } from 'shared/types/index';

import { useTemporaryPlaylistItemOptions } from './hooks/useTemporaryPlaylistItemOptions';

interface Props {
  isDisabled?: boolean;
  isEditing?: boolean;
  playlist: Playlist;
  playlistItem: PlaylistItemType;
  videoIndex: number;
  isChecked?: boolean;
}

export const TemporaryPlaylistItem = memo(
  ({ isDisabled = false, isEditing = false, playlist, playlistItem, videoIndex, isChecked = false }: Props) => {
    const actions = useVideoPlayerActions();
    const kebabRef = useRef<HTMLDivElement | null>(null);
    const handleSetPlaylistItem = useHandleSetPlaylistItem(playlistItem);
    const handleItemClick = useHandlePlaylistItemClick(playlistItem, playlist.id);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const isCurrentPlaylistItem = useIsCurrentPlaylistItem(playlistItem.id);
    const [copyToAnchor, setCopyToAnchor] = useState<HTMLElement | null>(null);
    const getMenuOptions = useTemporaryPlaylistItemOptions(playlistItem, setCopyToAnchor);

    const handleCloseDownloadModal = useCallback(() => {
      setIsDownloadModalOpen(false);
      actions.resumeStandBy();
    }, [actions]);

    const handleCloseCopyToDialog = useCallback(() => {
      actions.resumeStandBy();
      setCopyToAnchor(null);
    }, [actions]);

    const showProgressBar = isCurrentPlaylistItem && !isDisabled;

    return (
      <>
        <PlaylistItemWrapper
          isDraggable={false}
          isCurrent={isCurrentPlaylistItem}
          isEditing={isEditing}
          isDisabled={isDisabled}
          onClick={handleItemClick}
          direction='row'
          justifyContent='space-between'
          title={`${playlistItem.name} | ${playlistItem.recordingName} (${playlistItem.recordingMatchday})`}
        >
          <PlaylistItemContent
            isChecked={isChecked}
            isEditingMode={isEditing}
            getMenuOptions={getMenuOptions}
            onClick={handleSetPlaylistItem}
            playlistId={playlist.id}
            playlistItem={playlistItem}
            showProgressBar={showProgressBar}
            videoIndex={videoIndex}
            kebabRef={kebabRef}
          />
        </PlaylistItemWrapper>

        {isDownloadModalOpen ? (
          <DownloadPlaylistItemsModal
            playlistId={playlist.id}
            isPlaylistDownload={false}
            itemsToDownload={[playlistItem.id]}
            onClose={handleCloseDownloadModal}
          />
        ) : null}

        <CopyToDialog
          playlistItems={[generateCopyToPlaylistItem(playlistItem)]}
          onClose={handleCloseCopyToDialog}
          anchorEl={copyToAnchor}
        />
      </>
    );
  },
);

TemporaryPlaylistItem.displayName = 'ReadOnlyPlaylistItemElement';
