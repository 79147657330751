import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, RouteComponentProps, useHistory } from 'react-router-dom';

import { useFetchLiveTaggingSession } from 'api/tagging-tool/use-fetch-live-session';
import { useUpdateLiveTaggingSession } from 'api/tagging-tool/use-update-live-session';
import { routes } from 'kognia/router/routes';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import Spinner from 'shared/components/spinner';

import styles from './LiveTaggingSessionEditPage.module.scss';
import LiveTaggingSessionEditForm from '../live-tagging-session-edit-form';

type RouteParams = { recordingId: string };

const LiveTaggingSessionEditPage = ({ liveTaggingSessionId }: LiveTaggingSessionEditPageProps) => {
  const { data, isError, isFetching, isSuccess } = useFetchLiveTaggingSession(liveTaggingSessionId);
  const history = useHistory();
  const redirectToRecordingListPage = () => {
    const recordingListPath = generatePath(routes.TAGGING_TOOL);
    history.push(recordingListPath);
  };

  const { sendLiveTaggingSessionUpdate } = useUpdateLiveTaggingSession(liveTaggingSessionId, () =>
    redirectToRecordingListPage(),
  );

  const { t } = useTranslation();

  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    if (isError || (!isFetching && !isPageReady)) {
      if (isSuccess) {
        setIsPageReady(true);
      } else {
        setIsPageReady(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isError]);

  const showLoading = !isPageReady && isFetching;
  const showPage = isPageReady && isSuccess;
  const showError = !isPageReady && isError;

  const onSubmit = (params: string) => {
    sendLiveTaggingSessionUpdate(params);
  };

  return (
    <SidebarLayout>
      {showLoading && (
        <Container>
          <Spinner isFullPage />
        </Container>
      )}
      {showPage && (
        <Container>
          {data && (
            <LiveTaggingSessionEditForm
              initialData={data}
              onCancel={() => redirectToRecordingListPage()}
              onSubmit={onSubmit}
            />
          )}
        </Container>
      )}
      {showError && <h4 className={styles.error}>{t('live-session-edit:error')}</h4>}
    </SidebarLayout>
  );
};

const LiveTaggingSessionEditContainer = (props: RouteComponentProps<RouteParams>) => {
  const {
    match: {
      params: { recordingId },
    },
  } = props;

  return <LiveTaggingSessionEditPage liveTaggingSessionId={recordingId} />;
};

type LiveTaggingSessionEditPageProps = {
  liveTaggingSessionId: string;
};

export default LiveTaggingSessionEditContainer;
