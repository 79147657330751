import { TaggingEvents } from 'shared/types';
import { getDefaultVideoSource } from 'shared/utils/get-video-sources';

import { transformRecordingVideoSources } from '../../../match/transformers/match/matchWithEpisodes';
import { TaggingEventsResponse } from '../../types';

export const transformTaggingEvents = (response: TaggingEventsResponse): TaggingEvents => {
  const videoSources = transformRecordingVideoSources(response.videoSources);

  return {
    tags: response.data.map((tag) => ({
      description: tag.description,
      id: tag.id,
      name: tag.name,
      recordingId: tag.recordingId,
      time: tag.time,
      timeAfter: tag.timeAfter,
      timeBefore: tag.timeBefore,
      typeOfPlay: tag.typeOfPlay,
      typeOfPlaySource: tag.typeOfPlaySource,
      userId: tag.userId,
    })),
    clientIds: response.clientIds,
    videoSources: videoSources,
    matchVideoSource: getDefaultVideoSource(videoSources),
    name: response.name,
    date: response.date,
    startRecordingTime: response.startRecordingTime,
    usersInfo: response.usersInfo.map((userInfo) => ({
      userId: userInfo.userId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    })),
  };
};
