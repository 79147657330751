import { Box } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { Dash, Player } from '@vime/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useUserPresets } from 'api/user-presets/use-user-presets';
import { routes } from 'kognia/router/routes';
import { READY_STATES } from 'shared/components/video-player/state/states';
import NextVideoSourceButton from 'shared/components/video-player/video-player-component/components/next-video-source-button';
import { PlayerSkeleton } from 'shared/components/video-player/video-player-component/components/player-skeleton';
import PreviousVideoSourceButton from 'shared/components/video-player/video-player-component/components/previous-video-source-button';
import ProgressBarVideoSource from 'shared/components/video-player/video-player-component/components/progress-bar-video-source';
import ProgressTimeVideoSource from 'shared/components/video-player/video-player-component/components/progress-time-video-source';
import { videoPlayerSpeedPreset } from 'shared/constants/user-presets/userPresetsVideoPlayer';
import { isUserAdmin, useFeatureFlag } from 'shared/contexts/app-state';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishFrameRateEvent } from 'shared/streams/eventEmitter';
import { SegmentConfig } from 'shared/types/segment/types';
import { UserPresetScope } from 'shared/types/user-preset/types';
import { getPreset } from 'shared/utils/user-presets/getPreset';

import Backward5Button from './components/backward-5-button';
import ButtonControls from './components/button-controls';
import ButtonControlsLeft from './components/button-controls-left';
import ButtonControlsRight from './components/button-controls-right';
import DownloadButton from './components/download-button';
import Forward5Button from './components/forward-5-button';
import FullscreenButton from './components/fullscreen-button';
import ForwardButton from './components/next-button';
import PlayBackButton from './components/playback-button';
import BackwardButton from './components/previous-button';
import ProgressBar from './components/progress-bar';
import ProgressTime from './components/progress-time';
import { Rules } from './components/rules';
import { SpeedButton } from './components/speed-button';
import { TextOverlay } from './components/text-overlay';
import VideoControls from './components/video-controls';
import { VideoPlayerDebug } from './components/video-player-debug';
import VideoSpinner from './components/video-spinner';
import { aspectRatioFromSize, DEFAULT_VIDEO_ASPECT_RATIO, useVideoAspectRatio } from './hooks/use-video-aspect-ratio';
import styles from './VideoPlayer.module.scss';
import {
  useCurrentPlaylistItem,
  useVideoPlayerContainerRef,
  useVideoPlayerId,
  useVideoPlayerIsPlaying,
  useVideoPlayerPlayingMode,
} from '../hooks';
import { useRenderFrameRate } from '../hooks/use-overlays-controller';
import { useSetVideoPlayerSpeed, useVideoPlayerSpeed } from '../hooks/use-video-player-speed';
import { usePlayerCurrentSource, useVideoPlayerActions, useVideoPlayerRef, useVideoPlayerState } from '../index';
import { VideoOverlays } from '../overlays';
import { useSetCurrentTime } from '../state/atoms/hooks';
import { PlayingMode, PlayingModes } from '../types';
import { VideoPlayerBar } from '../video-player-bar';

interface Props {
  matchSegments?: SegmentConfig[];
  onDownloadSource?: () => void;
  showRules?: boolean;
  showVideoSourceControls?: boolean;
  showVideoPlayerBar?: boolean;
  showTacticDrawingsOnly?: boolean;
  showTacticDrawings?: boolean;
  disabledTacticDrawings?: boolean;
  onPlayingModeChange?: (playingMode: PlayingMode) => void;
}

const VideoPlayerComponent = ({
  matchSegments,
  onDownloadSource,
  showRules,
  showVideoSourceControls = false,
  showVideoPlayerBar = false,
  showTacticDrawingsOnly = false,
  showTacticDrawings = true,
  disabledTacticDrawings,
  onPlayingModeChange,
}: Props) => {
  const videoPlayerContainerRef = useRef<HTMLDivElement>(null);
  const showCustomOverlays = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);
  const isPageWhitelisted = useRouteMatch([
    routes.RECORDING_PLAYLIST_DETAIL,
    routes.PLAYER_PROFILE_DASHBOARD,
    routes.DASHBOARD_PREVIEW,
  ]);
  const videoPlayerId = useVideoPlayerId();
  const isAdmin = isUserAdmin();
  const actions = useVideoPlayerActions();
  const playbackRate = useVideoPlayerSpeed();
  const containerRef = useVideoPlayerContainerRef();
  const videoPlayerRef = useVideoPlayerRef();
  const videoSource = usePlayerCurrentSource();
  const playlistItem = useCurrentPlaylistItem();
  const state = useVideoPlayerState();
  const isPlaying = useVideoPlayerIsPlaying();
  const [showFullScreen, setShowFullScreen] = useState('idle');
  const currentPlaylistItem = useCurrentPlaylistItem();
  const videoPlayerPlayingMode = useVideoPlayerPlayingMode();
  const setCurrentTime = useSetCurrentTime(videoPlayerId);
  const { videoAspectRatio } = useVideoAspectRatio({ containerRef });
  const areOverlaysEnabledInTimeline = useRouteMatch([routes.PERFORMANCE_REPORT_TIMELINE]);
  const setVideoPlayerSpeed = useSetVideoPlayerSpeed();
  const frameRate = useRenderFrameRate();

  useEffect(() => {
    if (isPlaying) {
      publishFrameRateEvent({ type: ActionTypes.LOG_OVERLAYS_FRAME_RATE, payload: frameRate });
    }
  }, [frameRate, isPlaying]);

  useUserPresets({
    scope: UserPresetScope.videoPlayer,
    onSuccess: (data) => {
      const speedPreset = getPreset(data, videoPlayerSpeedPreset.key);
      if (speedPreset !== undefined) setVideoPlayerSpeed(speedPreset);
    },
  });

  const handleChangePlayingMode = useCallback(
    (playingMode: PlayingMode) => {
      if (onPlayingModeChange) {
        onPlayingModeChange(playingMode);
      } else {
        actions.changePlayingMode(playingMode);
      }
    },
    [actions, onPlayingModeChange],
  );

  useLayoutEffect(() => {
    actions.refresh();
  }, [actions]);

  const handleTogglePlaying = useCallback(() => {
    isPlaying ? actions.pause() : actions.play();
  }, [actions, isPlaying]);

  const handleTimeUpdate = useCallback(
    (event: { detail: number }) => {
      if (state.readyState && isPlaying && videoPlayerRef.current?.paused) {
        actions.play();
      }

      if (state.readyState && !isPlaying && !videoPlayerRef.current?.paused) {
        actions.pause();
      }

      if (
        (state.readyState === READY_STATES.ENDED ||
          state.readyState === READY_STATES.PLAYING ||
          state.readyState === READY_STATES.PAUSED) &&
        videoPlayerRef.current?.ready
      ) {
        if (
          videoPlayerPlayingMode.mode === PlayingModes.TACTICAL_CAMERA &&
          !videoPlayerPlayingMode.useEffectiveTime &&
          playlistItem.videoTypes[0].videoSources.length <= 1
        ) {
          event.detail >= videoSource.endTime ? actions.nextPlaylistItem() : setCurrentTime(event.detail);
        } else {
          event.detail >= videoSource.endTime ? actions.nextVideoSource() : setCurrentTime(event.detail);
        }
      }
    },
    [
      state.readyState,
      isPlaying,
      videoPlayerRef,
      actions,
      playlistItem,
      videoPlayerPlayingMode.mode,
      videoPlayerPlayingMode.useEffectiveTime,
      videoSource.endTime,
      setCurrentTime,
    ],
  );

  const isFullScreen = showFullScreen === 'visible';
  const showVideoSourceTimeInFullscreen =
    isFullScreen &&
    matchSegments &&
    videoSource?.startTimeInMatch &&
    videoSource.startTimeInMatch >= 0 &&
    videoSource.endTimeInMatch &&
    videoSource?.endTimeInMatch >= 0;

  const videoSourceSrc = videoSource?.src;
  const isTextOverlayVisible = Boolean(currentPlaylistItem.recordingName) && Boolean(currentPlaylistItem.name);

  const handleNextVideoSource = useCallback(() => actions.nextVideoSource(), [actions]);

  return (
    <div id={videoPlayerId} className={classNames(styles.videoPlayerContainer)} ref={containerRef}>
      {showRules && <Rules />}

      {state.isPlaylistEmpty || !videoSourceSrc ? (
        <PlayerSkeleton />
      ) : (
        <>
          {showVideoPlayerBar && !isFullScreen ? (
            <VideoPlayerBar
              showTacticDrawingsOnly={showTacticDrawingsOnly}
              showTacticDrawings={showTacticDrawings}
              disabledTacticDrawings={disabledTacticDrawings}
              setPlayingMode={handleChangePlayingMode}
            />
          ) : null}
          <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
            <Box
              ref={videoPlayerContainerRef}
              style={{
                width: `${videoAspectRatio.width}px`,
                maxHeight: '100%',
                position: 'relative',
                overflow: 'hidden',
                margin: '0 auto',
              }}
            >
              <Player
                aspectRatio={aspectRatioFromSize(DEFAULT_VIDEO_ASPECT_RATIO, ':')}
                autoplay={false}
                muted
                onVmPlaybackEnded={handleNextVideoSource}
                onVmCurrentTimeChange={handleTimeUpdate}
                playbackRate={playbackRate}
                ref={videoPlayerRef}
                onClick={handleTogglePlaying}
              >
                <Dash src={videoSourceSrc} version='3.2.2' />
              </Player>
              {isTextOverlayVisible ? (
                <TextOverlay
                  prefix={currentPlaylistItem.recordingName || ''}
                  description={currentPlaylistItem.name || ''}
                  fullScreen={isFullScreen}
                />
              ) : null}
              {(isPageWhitelisted || areOverlaysEnabledInTimeline) &&
              showCustomOverlays &&
              playlistItem.hasHomographies &&
              !disabledTacticDrawings ? (
                <VideoOverlays
                  videoPlayerContainerRef={videoPlayerContainerRef}
                  disabled={!videoPlayerPlayingMode.showOverlays}
                />
              ) : null}
            </Box>
            <VideoControls onClick={handleTogglePlaying}>
              <ButtonControls>
                <ButtonControlsLeft>
                  {showVideoSourceControls ? <PreviousVideoSourceButton /> : <BackwardButton />}
                  <Backward5Button />
                  <PlayBackButton />
                  <Forward5Button />
                  {showVideoSourceControls ? <NextVideoSourceButton /> : <ForwardButton />}
                </ButtonControlsLeft>

                {showVideoSourceTimeInFullscreen ? <ProgressBarVideoSource /> : <ProgressBar />}

                {showVideoSourceTimeInFullscreen ? (
                  <ProgressTimeVideoSource />
                ) : (
                  <ProgressTime matchSegments={matchSegments} />
                )}

                <ButtonControlsRight>
                  <DownloadButton onDownloadSource={onDownloadSource} />
                  <SpeedButton playbackRate={playbackRate} container={containerRef.current} />
                  <FullscreenButton
                    showFullScreen={showFullScreen}
                    setShowFullScreen={setShowFullScreen}
                    containerRef={containerRef}
                  />
                </ButtonControlsRight>
              </ButtonControls>
            </VideoControls>
            <VideoSpinner />
          </Box>
        </>
      )}
      {isAdmin && <VideoPlayerDebug />}
    </div>
  );
};

const VideoPlayerWithErrorBoundary = (props: JSX.IntrinsicAttributes & Props) => {
  return (
    <ErrorBoundary fallback={<PlayerSkeleton />}>
      <VideoPlayerComponent {...props} />
    </ErrorBoundary>
  );
};

export default VideoPlayerWithErrorBoundary;
