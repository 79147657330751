import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';

import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';

import { CompetitionsListItem } from './styled';
import { useDeleteCompetition } from '../../../../api/competitions/use-delete-competition';
import { Competition } from '../../../../types/competitions';
import { CompetitionFormFieldsNames } from '../competition-form/types';
import { CompetitionModal } from '../competition-modal';

interface Props {
  competition: Competition;
}

export const CompetitionItem = ({ competition }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteCompetition } = useDeleteCompetition();
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteCompetition = useCallback(() => {
    deleteCompetition({
      competitionId: competition.id,
    });
  }, [competition.id, deleteCompetition]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [CompetitionFormFieldsNames.id]: competition.id,
    [CompetitionFormFieldsNames.name]: competition.name,
    [CompetitionFormFieldsNames.country]: competition.country ? competition.country : '',
  };

  return (
    <>
      <CompetitionsListItem key={competition.id}>
        <Typography fontWeight={fontWeight['500']}>{competition.name}</Typography>
        <Typography>{competition.country}</Typography>
        <KebabMenu options={options} />
      </CompetitionsListItem>
      {isEditModalOpen ? (
        <CompetitionModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete "${competition.name}" competition?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteCompetition}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
