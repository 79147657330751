import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { ProcessGameParamsData, useProcessGame } from '../../../../api/operations/use-process-game';
import { ProcessGameFieldsNames, ProcessGameForm } from '../types/processGameForm.types';
import { isEventsFile } from '../utils/is-event-file';
import { isTrackingFile } from '../utils/is-tracking-file';

type Options = {
  onSubmit: () => void;
};
export const useHandleProcessGameSubmit = ({ onSubmit }: Options) => {
  const { processGame } = useProcessGame();

  return useCallback(
    (formData: ProcessGameForm) => {
      const {
        [ProcessGameFieldsNames.recordingId]: recordingId,
        [ProcessGameFieldsNames.videoPath]: videoPath,
        [ProcessGameFieldsNames.bucket]: bucket,
        [ProcessGameFieldsNames.pipelineType]: pipelineType,
        [ProcessGameFieldsNames.trackingProvider]: trackingProvider,
        [ProcessGameFieldsNames.eventsProvider]: eventsProvider,
        [ProcessGameFieldsNames.runProcessing]: runProcessing,
        [ProcessGameFieldsNames.restartProcessing]: restartProcessing,
        [ProcessGameFieldsNames.showOverlays]: showOverlays,
        [ProcessGameFieldsNames.autoRunTacticalAnalysis]: autoRunTacticalAnalysis,
        [ProcessGameFieldsNames.sportlogiqTrackingType]: sportlogiqTrackingType,
        ...restData
      } = formData;

      onSubmit();
      const filesNonEmptyData = Object.entries(restData).reduce(
        (acc, [key, value]) => {
          if (!isEmpty(value)) {
            if (isTrackingFile(key)) {
              return { ...acc, tracking: { ...acc.tracking, [key]: value } };
            } else if (isEventsFile(key)) {
              return { ...acc, events: { ...acc.events, [key]: value } };
            }
          }

          return acc;
        },
        { tracking: {}, events: {} },
      );

      const data: ProcessGameParamsData = {
        recording_id: recordingId,
        video_path: videoPath,
        bucket,
        pipeline_type: pipelineType,
        settings: {
          providers: {
            video: 'third-party-tactical',
            tracking: trackingProvider,
            events: eventsProvider,
            ...(sportlogiqTrackingType && {
              options: {
                sportlogiq_tracking_type: sportlogiqTrackingType,
              },
            }),
          },
          run_processing: runProcessing,
          postprocess: {
            show_overlays: showOverlays,
            auto_run_tactical_analysis: autoRunTacticalAnalysis,
          },
        },
        restart_processing: restartProcessing,
        ...(!isEmpty(filesNonEmptyData.tracking) && {
          tracking_files: filesNonEmptyData.tracking,
        }),
        ...(!isEmpty(filesNonEmptyData.events) && {
          events_files: filesNonEmptyData.events,
        }),
      };

      processGame({ data });
    },
    [processGame, onSubmit],
  );
};
