import { ReactNode } from 'react';

import LinkWithExternal from 'kognia/router/link-with-external';
import { SolutionCardVariants } from 'pages/home/types/solutionCardVariants';

import { SolutionCardWrapper } from './ui/solution-card-wrapper/SolutionCardWrapper';

interface Props {
  variant: SolutionCardVariants;
  children: ReactNode;
  disabled?: boolean;
  to: string;
}

const SolutionCard = ({ children, variant, disabled, to }: Props) => {
  return (
    <SolutionCardWrapper variant={variant} disabled={disabled}>
      <LinkWithExternal
        onClick={disabled ? (event) => event.preventDefault() : undefined}
        to={to}
        data-testid={`solution-card-${variant}`}
      >
        {children}
      </LinkWithExternal>
    </SolutionCardWrapper>
  );
};

export default SolutionCard;
