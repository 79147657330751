import { zodResolver } from '@hookform/resolvers/zod';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { DialogNew } from 'shared/components/dialog-new';
import { FormInputField } from 'shared/components/form/form-input-field';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { FormSwitchField } from 'shared/components/form/form-switch-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';
import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import IconDelete from 'shared/components/icons/icon-delete';

import { BundesligaForm } from './components/BundesligaForm';
import { ChyronHegoForm } from './components/ChyronHegoForm';
import { HawkeyeForm } from './components/HawkeyeForm';
import { OptaForm } from './components/OptaForm';
import { SecondSpectrumForm } from './components/SecondSpectrumForm';
import { SignalityForm } from './components/SignalityForm';
import { SportlogiqForm } from './components/SportlogiqForm';
import { useHandleProcessGameSubmit } from './hooks/useHandleProcessGameSubmit';
import {
  eventsFilesFieldsNames,
  ProcessGameEventsProvider,
  ProcessGameFieldsNames,
  ProcessGameForm,
  processGameFormSchema,
  ProcessGameFormSchema,
  ProcessGamePipelineType,
  ProcessGameTrackingProvider,
  trackingFilesFieldsNames,
} from './types/processGameForm.types';
import { ProcessGamePreset } from '../../../entities/process-game/types/processGame.types';

interface Props {
  selectedPreset: ProcessGamePreset;
  defaultValues: Partial<ProcessGameForm>;
}

export const ProcessGameFormWidget = ({ defaultValues, selectedPreset }: Props) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const handleProcessGameSubmit = useHandleProcessGameSubmit({ onSubmit: () => setConfirmationDialogOpen(false) });
  const handleConfirmDialogOpen = useCallback(() => setConfirmationDialogOpen(true), []);
  const handleConfirmDialogClose = useCallback(() => setConfirmationDialogOpen(false), []);

  const {
    register,
    formState: { errors },
    unregister,
    getValues,
    handleSubmit,
    watch,
  } = useForm<ProcessGameFormSchema>({
    resolver: zodResolver(processGameFormSchema),
    defaultValues: defaultValues,
  });

  const showProviderField =
    watch(ProcessGameFieldsNames.pipelineType) === ProcessGamePipelineType.THIRD_PARTY_TACTICAL_TRACKING;
  const showTrackingProviderFields = (trackingProvider: ProcessGameTrackingProvider) =>
    showProviderField && watch(ProcessGameFieldsNames.trackingProvider) === trackingProvider;
  const showEventsProviderFields = (eventsProvider: ProcessGameEventsProvider) =>
    selectedPreset !== ProcessGamePreset.SCOUTING &&
    showProviderField &&
    watch(ProcessGameFieldsNames.eventsProvider) === eventsProvider;

  const handleConfirmSubmit = useCallback(() => {
    handleProcessGameSubmit(getValues());
  }, [handleProcessGameSubmit, getValues]);

  const handleFormSubmit = useCallback(
    (values: ProcessGameForm) => {
      if (values[ProcessGameFieldsNames.restartProcessing]) {
        handleConfirmDialogOpen();
      } else {
        handleProcessGameSubmit(values);
      }
    },
    [handleConfirmDialogOpen, handleProcessGameSubmit],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormInputField
        id={ProcessGameFieldsNames.recordingId}
        label='Recording ID'
        error={!!errors[ProcessGameFieldsNames.recordingId]}
        fullWidth
        helperText={
          errors[ProcessGameFieldsNames.recordingId] && <>{errors[ProcessGameFieldsNames.recordingId]?.message}</>
        }
        {...register(ProcessGameFieldsNames.recordingId)}
      />

      <FormInputField
        id={ProcessGameFieldsNames.videoPath}
        label='Video path'
        error={!!errors[ProcessGameFieldsNames.videoPath]}
        fullWidth
        helperText={
          errors[ProcessGameFieldsNames.videoPath] && <>{errors[ProcessGameFieldsNames.videoPath]?.message}</>
        }
        {...register(ProcessGameFieldsNames.videoPath)}
      />

      <FormInputField
        id={ProcessGameFieldsNames.bucket}
        label='Bucket'
        error={!!errors[ProcessGameFieldsNames.bucket]}
        fullWidth
        helperText={errors[ProcessGameFieldsNames.bucket] && <>{errors[ProcessGameFieldsNames.bucket]?.message}</>}
        {...register(ProcessGameFieldsNames.bucket)}
      />

      <FormSelectField
        id={ProcessGameFieldsNames.pipelineType}
        label='Pipeline type'
        error={!!errors[ProcessGameFieldsNames.pipelineType]}
        defaultValue={defaultValues[ProcessGameFieldsNames.pipelineType]}
        disabled={Boolean(defaultValues[ProcessGameFieldsNames.pipelineType])}
        fullWidth
        options={getTypeOptions({
          options: Object.values(ProcessGamePipelineType).map((value) => ({
            value,
          })),
        })}
        helperText={
          errors[ProcessGameFieldsNames.pipelineType] && <>{errors[ProcessGameFieldsNames.pipelineType]?.message}</>
        }
        {...register(ProcessGameFieldsNames.pipelineType)}
        onChange={(event) => {
          Object.values(trackingFilesFieldsNames).forEach((name) => unregister(name));
          Object.values(eventsFilesFieldsNames).forEach((name) => unregister(name));
          return register(ProcessGameFieldsNames.pipelineType).onChange(event);
        }}
      />

      {showProviderField ? (
        <FormSelectField
          id={ProcessGameFieldsNames.trackingProvider}
          label='Tracking provider'
          error={!!errors[ProcessGameFieldsNames.trackingProvider]}
          defaultValue={defaultValues[ProcessGameFieldsNames.trackingProvider]}
          disabled={Boolean(defaultValues[ProcessGameFieldsNames.trackingProvider])}
          fullWidth
          options={getTypeOptions({
            options: Object.values(ProcessGameTrackingProvider).map((value) => ({
              value,
            })),
          })}
          helperText={
            errors[ProcessGameFieldsNames.trackingProvider] && (
              <>{errors[ProcessGameFieldsNames.trackingProvider]?.message}</>
            )
          }
          {...register(ProcessGameFieldsNames.trackingProvider)}
          onChange={(event) => {
            Object.values(trackingFilesFieldsNames).forEach((name) => unregister(name));
            return register(ProcessGameFieldsNames.trackingProvider).onChange(event);
          }}
        />
      ) : null}

      {showTrackingProviderFields(ProcessGameTrackingProvider.SPORTLOGIQ) && (
        <SportlogiqForm
          register={register}
          errors={errors}
          defaultValue={defaultValues[ProcessGameFieldsNames.sportlogiqTrackingType]}
        />
      )}

      {showTrackingProviderFields(ProcessGameTrackingProvider.HAWKEYE) && (
        <HawkeyeForm register={register} errors={errors} />
      )}

      {showTrackingProviderFields(ProcessGameTrackingProvider.CHYRONHEGO) && (
        <ChyronHegoForm register={register} errors={errors} />
      )}

      {showTrackingProviderFields(ProcessGameTrackingProvider.SIGNALITY) && (
        <SignalityForm register={register} errors={errors} />
      )}

      {showTrackingProviderFields(ProcessGameTrackingProvider.SECOND_SPECTRUM) && (
        <SecondSpectrumForm register={register} errors={errors} />
      )}

      {showProviderField ? (
        <FormSelectField
          id={ProcessGameFieldsNames.eventsProvider}
          label='Events provider'
          error={!!errors[ProcessGameFieldsNames.eventsProvider]}
          defaultValue={defaultValues[ProcessGameFieldsNames.eventsProvider]}
          disabled={Boolean(defaultValues[ProcessGameFieldsNames.eventsProvider])}
          fullWidth
          options={getTypeOptions({
            options: Object.values(ProcessGameEventsProvider).map((value) => ({
              value,
            })),
          })}
          helperText={
            errors[ProcessGameFieldsNames.eventsProvider] && (
              <>{errors[ProcessGameFieldsNames.eventsProvider]?.message}</>
            )
          }
          {...register(ProcessGameFieldsNames.eventsProvider)}
          onChange={(event) => {
            Object.values(eventsFilesFieldsNames).forEach((name) => unregister(name));
            return register(ProcessGameFieldsNames.eventsProvider).onChange(event);
          }}
        />
      ) : null}

      {showEventsProviderFields(ProcessGameEventsProvider.OPTA) && <OptaForm register={register} errors={errors} />}

      {showEventsProviderFields(ProcessGameEventsProvider.BUNDESLIGA) && (
        <BundesligaForm register={register} errors={errors} />
      )}

      <Accordion sx={{ marginBottom: 2 }}>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Optional</AccordionSummary>
        <AccordionDetails>
          <FormSwitchField
            id={ProcessGameFieldsNames.runProcessing}
            label='Run processing'
            defaultChecked={defaultValues[ProcessGameFieldsNames.runProcessing]}
            {...register(ProcessGameFieldsNames.runProcessing)}
          />
          <FormSwitchField
            id={ProcessGameFieldsNames.restartProcessing}
            label='Restart processing'
            defaultChecked={defaultValues[ProcessGameFieldsNames.restartProcessing]}
            {...register(ProcessGameFieldsNames.restartProcessing)}
          />
          <FormSwitchField
            id={ProcessGameFieldsNames.showOverlays}
            label='Show overlays'
            defaultChecked={defaultValues[ProcessGameFieldsNames.showOverlays]}
            {...register(ProcessGameFieldsNames.showOverlays)}
          />
          <FormSwitchField
            id={ProcessGameFieldsNames.autoRunTacticalAnalysis}
            label='Auto run tactical analysis'
            defaultChecked={defaultValues[ProcessGameFieldsNames.autoRunTacticalAnalysis]}
            {...register(ProcessGameFieldsNames.autoRunTacticalAnalysis)}
          />
        </AccordionDetails>
      </Accordion>

      <DialogNew
        title='Confirmation'
        icon={<IconDelete />}
        onCancel={handleConfirmDialogClose}
        onClose={handleConfirmDialogClose}
        onSubmit={handleConfirmSubmit}
        buttonSubmitText='Delete'
        open={confirmationDialogOpen}
        error
      >
        This will delete the current data for <strong>{getValues(ProcessGameFieldsNames.recordingId)}</strong> and
        restart the process.
      </DialogNew>

      <Stack direction='row' justifyContent='flex-end' spacing={1}>
        <Button variant='contained' type='submit'>
          Submit
        </Button>
      </Stack>
    </form>
  );
};
