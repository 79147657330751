import { Stack } from '@mui/material';
import countBy from 'lodash/countBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMetrics } from 'kognia/metrics/hooks/use-metrics';
import { RecordingFilters } from 'pages/recordings-list/types';
import { FilterLabel } from 'shared/components/filter-label';
import Filter, { FilterOption } from 'shared/components/filter-v2';
import CheckBoxMenu from 'shared/components/filter-v2-menu/checkbox';
import IconStrawberry from 'shared/components/icons/icon-strawberry';
import { FilterOptions, FiltersList } from 'shared/types';
import { MetricsData, MetricsNames } from 'shared/types/metrics';

import { ApplyFilterPayloadType } from '../hooks/useRecordingsFilters/reducer';

type FilterDropdownsProps = {
  applyFilters: (payload: ApplyFilterPayloadType) => void;
  filtersList: FiltersList;
};

const Filters = ({ applyFilters, filtersList }: FilterDropdownsProps): JSX.Element => {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState<string>();
  const { pushEvent } = useMetrics();

  const mapFilterOptions = useCallback(
    (filterKey: string, options: FilterOptions): FilterOption[] => {
      const mappedOptions = [];
      for (const [key, value] of Object.entries(options)) {
        if (filterKey === RecordingFilters.TYPE) {
          mappedOptions.push({
            id: key,
            isSelected: value.isApplied,
            title: t(`filters:${key}`),
          } as FilterOption);
        } else {
          mappedOptions.push({ id: key, isSelected: value.isApplied, title: value.title } as FilterOption);
        }
      }

      if (filterKey === RecordingFilters.TEAM) {
        mappedOptions.sort((a: FilterOption, b: FilterOption) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
        );
      }
      return mappedOptions;
    },
    [t],
  );

  const handleOpenFilter = useCallback(
    (key: string) => {
      pushEvent<MetricsData[MetricsNames.RECORDINGS_OPEN_FILTER]>(MetricsNames.RECORDINGS_OPEN_FILTER, {
        filter_name: key,
      });
      return setOpenFilter(key);
    },
    [pushEvent],
  );

  const handleSubmit = useCallback(
    (key: string, filterOptions: FilterOption[]) => {
      const selectedOptions = filter(filterOptions, (option) => option.isSelected).map((option) => option.id);
      pushEvent<MetricsData[MetricsNames.RECORDINGS_APPLY_FILTER]>(MetricsNames.RECORDINGS_APPLY_FILTER, {
        filter_name: key,
      });
      setOpenFilter(undefined);
      applyFilters({ key: key, selectedFilters: selectedOptions });
    },
    [applyFilters, pushEvent],
  );

  return (
    <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
      <FilterLabel>
        <IconStrawberry size='small' />
        {t('filters:title')}
      </FilterLabel>
      {map(filtersList, (filter, key) => {
        const mappedOptions = mapFilterOptions(key, filter.options);
        const hasSelectedOptions = countBy(mappedOptions, (option) => option.isSelected).true > 0;
        return (
          <Filter
            key={key}
            isOpen={key === openFilter}
            onClickOpen={() => handleOpenFilter(key)}
            onClose={() => setOpenFilter(undefined)}
            displayName={t(`filters:${key}`)}
            hasSelectedOptions={hasSelectedOptions}
          >
            <CheckBoxMenu
              initialOptions={mappedOptions}
              submitOptions={(options: FilterOption[]) => handleSubmit(key, options)}
            />
          </Filter>
        );
      })}
    </Stack>
  );
};

export default Filters;
