import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback, useState } from 'react';

import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import IconPlus from 'shared/components/icons/icon-plus';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';

import { CompetitionModal } from './components/competition-modal';
import { CompetitionsList } from './components/competitions-list';
import { useCompetitions } from '../../api/competitions/use-competitions';
import { ListSkeleton } from '../../components/list-skeleton';

const DEBOUNCE_TIME = 300;

export const Competitions = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { items, page, fetchNextPage, isFetching, setFilters } = useCompetitions();

  const handleCreateModalOpen = useCallback(() => setIsCreateModalOpen(true), []);
  const handleCreateModalClose = useCallback(() => setIsCreateModalOpen(false), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ name: event.target.value });
    }, DEBOUNCE_TIME),
    [setFilters],
  );

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Competitions</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateModalOpen}
        >
          New competition
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search competitions' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>
        <Box display='grid' gridTemplateColumns='1fr 1fr 32px' alignItems={'center'}>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Name
          </Typography>
          <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
            Country
          </Typography>
          <div>&nbsp;</div>
        </Box>
      </ListHeader>
      {!isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isFetching ? <ListSkeleton /> : <CompetitionsList competitions={items} />}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          getStatsText={(displayed, total) => `${displayed} of ${total} competitions`}
        />
      ) : null}
      <CompetitionModal open={isCreateModalOpen} onClose={handleCreateModalClose} />
    </BackofficeContentContainer>
  );
};
