import { FrameInfo, TacticId, useOverlayGenerator } from 'overlay-generator';
import { useCallback, useEffect, useRef, useState } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { useCurrentPlaylistItem, useVideoPlayerActions, useVideoPlayerId, useVideoPlayerRef } from '../';

const areOverlaysReady = atomFamily<boolean, string>({
  key: 'overlays-ready',
  default: false,
});

const frameRate = atomFamily<number, string>({
  key: 'overlays-render-frame-rate',
  default: 0,
});

const frameInfo = atomFamily<FrameInfo, string>({
  key: 'overlays-frame-info',
  default: {
    frameNumber: 0,
    frameTactics: [],
    overlayElementsDebugInfo: [],
  },
});

export const useSetAreOverlaysReady = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(areOverlaysReady(playerId));
};

export const useAreOverlaysReady = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(areOverlaysReady(playerId));
};

export const useSetOverlaysFrameInfo = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(frameInfo(playerId));
};

export const useOverlaysFrameInfo = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(frameInfo(playerId));
};

export const useRenderFrameRate = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(frameRate(playerId));
};

export const useOverlaysController = (videoPlayerContainerRef: React.RefObject<HTMLDivElement>) => {
  const playlistItem = useCurrentPlaylistItem();
  const container = useRef<HTMLDivElement>(null);
  const { overlayGenerator, frameRate, frameInfo, isReady } = useOverlayGenerator({
    id: playlistItem.recordingId,
  });

  const videoPlayerRef = useVideoPlayerRef();
  const setAreOverlaysReady = useSetAreOverlaysReady();
  const setOverlaysFrameInfo = useSetOverlaysFrameInfo();
  const actions = useVideoPlayerActions();
  const [matrix3dTransformation, setMatrix3dTransformation] = useState<number[] | undefined>([]);

  useEffect(() => {
    isReady ? actions.resumeStandBy() : actions.handleStandBy();
    setAreOverlaysReady(isReady);
  }, [actions, setAreOverlaysReady, isReady]);

  useEffect(() => {
    setOverlaysFrameInfo(frameInfo);
  }, [setOverlaysFrameInfo, frameInfo]);

  const handleUpdateFrame = useCallback(
    async ({
      frame,
      tactics,
      videoPlayerWidth,
      videoPlayerHeight,
      overlayContainer,
    }: {
      frame: number;
      tactics: TacticId[] | undefined;
      videoPlayerWidth: number;
      videoPlayerHeight: number;
      overlayContainer: HTMLDivElement;
    }) => {
      await overlayGenerator.drawFrameInCanvas(overlayContainer, frame, {
        tactics,
      });

      const matrix3d = overlayGenerator.getTransformationMatrix(frame, {
        width: videoPlayerWidth,
        height: videoPlayerHeight,
      });

      setMatrix3dTransformation(matrix3d);
      return Boolean(matrix3d);
    },
    [overlayGenerator],
  );

  const handleVideoTimeUpdate = useCallback(async () => {
    const videoPlayerWidth = videoPlayerRef.current?.offsetWidth;
    const videoPlayerHeight = videoPlayerRef.current?.offsetHeight;
    if (!container.current || !videoPlayerRef.current || !videoPlayerWidth || !videoPlayerHeight) return;

    const videoFrame = Math.round(videoPlayerRef.current.currentTime * frameRate) - 1;

    const tactics =
      playlistItem.fundamentalsSelected && playlistItem.fundamentalsSelected.fundamentalsSelected[0] === 'all'
        ? undefined
        : (playlistItem.fundamentalsSelected.fundamentalsSelected as TacticId[]);

    return await handleUpdateFrame({
      frame: videoFrame,
      tactics,
      videoPlayerWidth,
      videoPlayerHeight,
      overlayContainer: container.current,
    });
  }, [container, frameRate, handleUpdateFrame, playlistItem.fundamentalsSelected, videoPlayerRef]);

  useEffect(() => {
    if (!videoPlayerContainerRef?.current) return;

    const video = videoPlayerRef.current;
    const videoPlayerContainer = videoPlayerContainerRef.current;

    const update = () => {
      handleVideoTimeUpdate();
    };

    const observer = new ResizeObserver(update);
    observer.observe(videoPlayerContainer as HTMLElement);
    video?.addEventListener('vmFullscreenChange', update);
    video?.addEventListener('vmCurrentTimeChange', update);
    video?.addEventListener('vmBufferingChange', update);
    video?.addEventListener('vmCurrentSrcChange', update);
    video?.addEventListener('vmDurationChange', update);
    video?.addEventListener('vmPausedChange', update);
    video?.addEventListener('vmSeeked', update);

    return () => {
      observer.unobserve(video as HTMLElement);
      video?.removeEventListener('vmFullscreenChange', update);
      video?.removeEventListener('vmCurrentTimeChange', update);
      video?.removeEventListener('vmBufferingChange', update);
      video?.removeEventListener('vmCurrentSrcChange', update);
      video?.removeEventListener('vmDurationChange', update);
      video?.removeEventListener('vmPausedChange', update);
      video?.removeEventListener('vmSeeked', update);
      observer.disconnect();
    };
  }, [handleVideoTimeUpdate, videoPlayerRef, videoPlayerContainerRef]);

  return { container, overlayGenerator, matrix3dTransformation };
};
