import { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { routes } from 'kognia/router/routes';
import AccountPage from 'pages/account/AccountPageContainer';
import HomeContainer from 'pages/home/HomeContainer.page';
import LiveTaggingSessionEditPage from 'pages/live-tagging-sessions/components/live-tagging-session-edit-page';
import PlaylistDetailContainer from 'pages/playlist-detail/PlaylistDetailContainer.page';
import { PlaylistsContainer } from 'pages/playlists/PlaylistsContainer';
import RecordingEditPage from 'pages/recording-edit/components/recording-edit-page';
import RecordingPlaylistsContainer from 'pages/recording-playlists/RecordingPlaylistsContainer';
import RecordingsListPage from 'pages/recordings-list/RecordingsListPageContainer';
import { TacticalAnalysisContainer } from 'pages/tactical-analysis/TacticalAnalysisContainer';
import { KeypadEditPage } from 'pages/tagging-tool-keypad-edit';
import { KeypadListPage } from 'pages/tagging-tool-keypad-list';
import { TaggingToolTagContainer } from 'pages/tagging-tool-tag-recording/TaggingToolTagContainer';
import { ProtectedRoute } from 'shared/components/protected-route';
import { useIsAppSettled } from 'shared/contexts/app-state';

import useIsLanguageDetectionReady from './kognia/i18n/hooks/use-is-language-detection-ready';
import { BackofficeContainer } from './pages/backoffice/BackofficeContainer';
import { DashboardPreviewPage } from './pages/dashboard-preview/DashboardPreview.page';
import { PlayerProfileDashboardPage } from './pages/player-profile-dashboard/PlayerProfileDashboard.page';
import { RecordingAlignment } from './pages/tagging-tool-align-recording';
import { AlignmentSourceListContainer } from './pages/tagging-tool-aling-sources/AlignmentSourceListContainer';
import { TaggingToolRecordingList } from './pages/tagging-tool-recording-list';
import { UploadVideoStep1Screen } from './pages/tagging-tool-upload-video-step-one';
import { UploadVideoStep2Screen } from './pages/tagging-tool-upload-video-step-two/UploadVideoStep2Screen';
import { useBranding } from './shared/hooks/use-branding/useBranding';

const Routes = () => {
  const isLanguageReady = useIsLanguageDetectionReady();
  const isAppSettled = useIsAppSettled();
  const branding = useBranding();

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (isAppSettled && branding.faviconUrl && favicon && favicon.getAttribute('href') !== branding.faviconUrl) {
      favicon.setAttribute('href', branding.faviconUrl);
    }
  }, [branding.faviconUrl, isAppSettled]);

  if (!isAppSettled || !isLanguageReady) return null;

  return (
    <Switch>
      <ProtectedRoute path={routes.HOME_PAGE} component={HomeContainer} exact />
      <ProtectedRoute path={routes.RECORDING_LIST} exact component={RecordingsListPage} />
      <ProtectedRoute path={routes.RECORDING_EDIT} exact component={RecordingEditPage} />
      <ProtectedRoute path={routes.PLAYLISTS} component={PlaylistsContainer} exact />
      <ProtectedRoute path={routes.RECORDING_PLAYLISTS} exact component={RecordingPlaylistsContainer} />
      <ProtectedRoute path={routes.PERFORMANCE_REPORT_TIMELINE} component={TacticalAnalysisContainer} />
      <ProtectedRoute path={routes.RECORDING_PLAYLIST_DETAIL} component={PlaylistDetailContainer} />
      <ProtectedRoute path={routes.ACCOUNT} component={AccountPage} />
      <ProtectedRoute path={routes.TAGGING_TOOL} exact component={TaggingToolRecordingList} />
      <ProtectedRoute path={routes.TAGGING_TOOL_UPLOAD_STEP_ONE} exact component={UploadVideoStep1Screen} />
      <ProtectedRoute path={routes.TAGGING_TOOL_UPLOAD_STEP_TWO} exact component={UploadVideoStep2Screen} />
      <ProtectedRoute path={routes.TAGGING_TOOL_ALIGN_SOURCES} exact component={AlignmentSourceListContainer} />
      <ProtectedRoute
        path={[routes.TAGGING_TOOL_ALIGN_RECORDING_WITH_SOURCE, routes.TAGGING_TOOL_ALIGN_RECORDING]}
        exact
        component={RecordingAlignment}
      />
      <ProtectedRoute
        path={[routes.TAGGING_TOOL_TAG, routes.TAGGING_TOOL_RECORDING]}
        exact
        component={TaggingToolTagContainer}
      />
      <ProtectedRoute path={routes.KEYPADS_EDIT} component={KeypadEditPage} />
      <ProtectedRoute path={routes.KEYPADS} component={KeypadListPage} />
      <ProtectedRoute path={routes.LIVE_TAGGING_SESSION_EDIT} exact component={LiveTaggingSessionEditPage} />
      <ProtectedRoute onlyAdminAccess path={routes.BACKOFFICE} component={BackofficeContainer} />
      <ProtectedRoute
        component={PlayerProfileDashboardPage}
        path={routes.PLAYER_PROFILE_DASHBOARD}
        featureFlag={FEATURE_FLAG.APP_DASHBOARDS}
      />
      <ProtectedRoute
        component={DashboardPreviewPage}
        path={routes.DASHBOARD_PREVIEW}
        featureFlag={FEATURE_FLAG.APP_DASHBOARD_PREVIEW}
      />
      <Redirect to={routes.HOME_PAGE} />
    </Switch>
  );
};

export default Routes;
