import { useCallback } from 'react';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { PlaylistItemType, VideoSourceType } from 'shared/components/video-player/types';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { Playlist } from 'shared/types';
import { getVideoSources } from 'shared/utils/get-video-sources';

import { generateVideoSourcesFromVideoTypes } from './utils/generateVideoSourcesFromVideoTypes';

export const useMapVideos = () => {
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);

  return useCallback(
    (playlist: Playlist): PlaylistItemType[] => {
      return playlist.playlistItems.map((playlistItem) => {
        const playlistItemRecording = playlist.recordings.find(
          (recording) => recording.id === playlistItem.recordingId,
        );
        const videoSources = getVideoSources(playlistItemRecording?.videoSources || []);
        const videoTypes: VideoSourceType[] = generateVideoSourcesFromVideoTypes({
          startTime: playlistItem.startTime,
          endTime: playlistItem.endTime,
          episodesVideos: playlistItem.episodesVideos,
          videoSources,
          useCustomOverlays: customOverlaysFeatureFlag && playlistItem.hasHomographies,
        });

        return {
          id: playlistItem.id,
          name: playlistItem.name,
          index: playlistItem.index,
          duration: playlistItem.endTime - playlistItem.startTime,
          videoTypes,
          recordingName: playlistItemRecording?.name,
          recordingMatchday: playlistItemRecording?.matchDay,
          recordingId: playlistItem.recordingId,
          hasHomographies: playlistItem.hasHomographies,
          fundamentalsSelected: playlistItem.fundamentalsSelected,
        };
      });
    },
    [customOverlaysFeatureFlag],
  );
};
