import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { taggingEventBaseUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { TypeOfPlay, TypeOfPlaySource } from 'shared/types/type-of-play/types';

import { FetchCreateTaggingEventResponse } from '../../../tagging-tool/service/taggingEvent';

interface CreateNewTaggingEventBody {
  description?: string | null;
  id: string;
  name: string;
  recordingId: string;
  time: number;
  timeAfter: number;
  timeBefore: number;
  typeOfPlay: TypeOfPlay;
  typeOfPlaySource?: TypeOfPlaySource | null;
  isLive: boolean;
}

export const useCreateTaggingEvent = () => {
  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<
    FetchCreateTaggingEventResponse,
    FetchCreateTaggingEventResponse
  >({
    type: HTTPMethod.POST,
    errorMessage: t('api:create-tagging-event.error'),
  });

  const createTaggingEvent = ({
    data,
    onSuccess,
  }: {
    data: CreateNewTaggingEventBody;
    onSuccess: (res: FetchCreateTaggingEventResponse) => void;
  }) => {
    mutate({ url: taggingEventBaseUrl, data }, { onSuccess });
  };

  return { createTaggingEvent, isLoading, isError, isSuccess };
};
