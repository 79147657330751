import { useCallback } from 'react';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useCurrentPlaylistItem, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import {
  EPISODES_PLAYING_MODE,
  getPlayingMode,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingModes } from 'shared/components/video-player/types';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { useFeatureFlag } from 'shared/contexts/app-state';

import { useTacticalAnalysisEpisodes } from '../../../hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisMatch } from '../../../hooks/use-tactical-analysis-match';
import { useTacticalAnalysisMatchSegments } from '../../../hooks/use-tactical-analysis-match-segments';
import { useTimelinePlayingMode } from '../../../hooks/use-timeline-playing-mode';
import { TimelineOverlaysSelectorPanel } from '../timeline-overlays-selector-panel';

type Props = {
  recordingId: string;
};

export const TacticalAnalysisVideoPlayer = ({ recordingId }: Props) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  const match = useTacticalAnalysisMatch(recordingId);
  const playingMode = useVideoPlayerPlayingMode();
  const playlistItem = useCurrentPlaylistItem();
  const { setPlayingMode } = useTimelinePlayingMode(recordingId);
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);

  const handleToggle = useCallback(() => {
    if (!match.showOverlays) {
      return setPlayingMode(TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE);
    }
    if (playlistItem.hasHomographies) {
      return setPlayingMode(
        getPlayingMode({
          playingMode,
          showOverlays: !playingMode.showOverlays,
        }),
      );
    }

    return setPlayingMode(
      playingMode.mode === PlayingModes.EPISODES
        ? TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE
        : EPISODES_PLAYING_MODE,
    );
  }, [playlistItem.hasHomographies, setPlayingMode, playingMode, match.showOverlays]);

  const showTacticDrawings = episodes.length > 0;
  const showOverlays = customOverlaysFeatureFlag && match.hasHomographies && match.showOverlays;
  return (
    <>
      <VideoPlayerComponent
        matchSegments={matchSegments}
        onPlayingModeChange={handleToggle}
        showTacticDrawings={showTacticDrawings}
        showTacticDrawingsOnly
        showVideoPlayerBar
        showVideoSourceControls
        disabledTacticDrawings={!showOverlays}
      />
      {showOverlays ? <TimelineOverlaysSelectorPanel key={match.id} recordingId={recordingId} /> : null}
    </>
  );
};
