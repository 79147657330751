import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { playlistsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { routes } from 'kognia/router/routes';
import { NotificationLink } from 'shared/components/NotificationLink';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { Playlist } from 'shared/types/playlist/types';

import { transformPlaylist } from '../transformers';
import { PlaylistApiResponse, PostNewPlaylist } from '../types';

export const generateFetchPlaylistsQueryRef = (recordingId: string) => [`fetchPlaylists-recordingId:${recordingId}`];

export const useCreatePlaylist = () => {
  const triggerNotification = useNotifications();
  const { t } = useTranslation();

  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<PlaylistApiResponse, Playlist>({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-add-playlist.error'),
    transformer: transformPlaylist,
  });

  const handleAddToPlaylistSuccess = useCallback(
    (playlist: Playlist) => {
      const translation = (
        <Trans
          i18nKey='playlist-detail:save-playlist.success-message'
          components={{
            playlistLink: (
              <NotificationLink to={generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: playlist.id })} />
            ),
          }}
          values={{ playlistName: playlist.name }}
        />
      );

      triggerNotification({
        type: NotificationType.SUCCESS,
        message: translation,
      });
    },
    [triggerNotification],
  );

  const createPlaylist = ({
    data,
    onSuccess = () => {},
  }: {
    data: PostNewPlaylist;
    onSuccess?: (res: Playlist) => void;
  }) => {
    mutate(
      { url: playlistsUrl, data },
      {
        onSuccess: (res: Playlist) => {
          onSuccess && onSuccess(res);
          handleAddToPlaylistSuccess(res);
        },
      },
    );
  };

  return { createPlaylist, isLoading, isError, isSuccess };
};
