import { SvgIconProps } from '@mui/material';

import { SolutionSvgIconContainer } from '../solution-svg-icon-container/SolutionSvgIconContainer';

export const IconMatchAnalysis = ({ sx, ...props }: SvgIconProps) => {
  return (
    <SolutionSvgIconContainer
      inheritViewBox
      sx={{
        width: '165px',
        color: 'text.secondary',
        ...sx,
      }}
      {...props}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='165' height='93' fill='none'>
        <g stroke='currentColor'>
          <path d='M91 70.5L62 46L92 24' strokeDasharray='1 1' />
          <g strokeWidth='3'>
            <path d='M1.5 1.5h162v90H1.5z' />
            <path d='M1 22h23.5v51H1m163.5-51H141v51h23.5m-81-73v92' />
          </g>
        </g>
        <g fill='currentColor'>
          <circle cx='116.5' cy='80.5' r='4.5' />
          <circle cx='123.5' cy='47.5' r='4.5' />
          <circle cx='115.5' cy='14.5' r='4.5' />
          <circle cx='52.5' cy='13.5' r='4.5' />
          <circle cx='52.5' cy='83.5' r='4.5' />
          <circle cx='37.5' cy='32.5' r='4.5' />
          <circle cx='61.5' cy='46.5' r='4.5' />
          <circle cx='92.5' cy='23.5' r='4.5' />
          <circle cx='92.5' cy='70.5' r='4.5' />
          <circle cx='37.5' cy='60.5' r='4.5' />
        </g>
        <g stroke='currentColor'>
          <path d='M116 15l8 32.5-7 34.5m-64.5 2l-15-23m0-28.5l15-17.5' strokeDasharray='1 1' />
          <path d='M37.5 34v24.5m55-31.5v41' />
          <circle cx='82.5' cy='46.5' r='10.5' strokeWidth='2' />
        </g>
      </svg>
    </SolutionSvgIconContainer>
  );
};
