import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { DialogNew } from 'shared/components/dialog-new';
import { IconUser } from 'shared/components/icons/icon-user';

import { useCreateSeason } from '../../../../api/seasons/use-create-season';
import { useUpdateSeason } from '../../../../api/seasons/use-update-season';
import { SeasonCompetitionsForm } from '../season-competition-form';
import { seasonCompetitionFormSchema } from '../season-competition-form/form';
import {
  SeasonCompetitionForm,
  SeasonCompetitionFormFieldsNames,
  SeasonFormSchema,
} from '../season-competition-form/types';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: SeasonCompetitionForm;
}

const seasonsFormId = 'seasons-form';
const EMPTY_VALUES = {
  [SeasonCompetitionFormFieldsNames.id]: '',
  [SeasonCompetitionFormFieldsNames.year]: new Date().getFullYear(),
  [SeasonCompetitionFormFieldsNames.crossesYear]: true,
  [SeasonCompetitionFormFieldsNames.competition]: undefined,
};

export const SeasonCompetitionModal = ({ open, onClose, defaultValues }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm<SeasonFormSchema>({
    resolver: zodResolver(seasonCompetitionFormSchema),
    defaultValues: defaultValues || EMPTY_VALUES,
  });

  const { create } = useCreateSeason();
  const { update } = useUpdateSeason();

  const handleCreate = useCallback(
    (data: SeasonCompetitionForm) => {
      const { year, crossesYear, competition } = data;
      create({
        data: {
          year,
          crosses_year: crossesYear,
          competition_uuid: competition?.id ?? '',
        },
        onSuccess: () => {
          onClose();
          reset();
        },
      });
    },
    [create, onClose, reset],
  );

  const handleUpdate = useCallback(
    (data: SeasonCompetitionForm) => {
      if (!data?.id) return;
      const { id, year, crossesYear, competition } = data;
      update({
        data: {
          year,
          crosses_year: crossesYear,
          competition_uuid: competition?.id ?? '',
        },
        id,
        onSuccess: () => {
          onClose();
          reset();
        },
      });
    },
    [onClose, reset, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: SeasonCompetitionForm) => {
      if (defaultValues) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit season' : 'New season'}
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={seasonsFormId}
    >
      <form id={seasonsFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <SeasonCompetitionsForm register={register} errors={errors} control={control} />
      </form>
    </DialogNew>
  );
};
