import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconPlaySpeed = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M18.9118 9.22709l-1.0164 1.48741c.9956 1.932.9273 4.2215-.1818 6.0942H6.2606c-1.45725-2.4597-1.08466-5.558.9168-7.62387s5.1583-2.61072 7.768-1.34059l1.5287-.9889c-3.203-1.99835-7.39455-1.61033-10.15277.93987S2.95126 14.3985 4.8393 17.6126c.29344.4946.83442.8006 1.4213.804h11.4447c.5928.0023 1.1415-.3045 1.4379-.804 1.5523-2.6164 1.4662-5.8587-.2232-8.39355l-.0082.00804zm-8.3255 6.04441c.372.3624.8768.566 1.4031.566s1.0312-.2036 1.4031-.566l5.6125-8.19093-8.4187 5.46063c-.3724.3619-.5816.8531-.5816 1.3652s.2092 1.0032.5816 1.3651z' />
    </SvgIcon>
  );
};

export default IconPlaySpeed;
