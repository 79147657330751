import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { useBackendApi, UseBackendApiParams } from 'api/hooks/useBackendApi';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

import { HTTPMethod } from '../../types';

export type UseApiFetchOptions = {
  enabled?: boolean;
  refetchOnReconnect?: boolean;
  refetchOnWindowFocus?: boolean;
  retry?: number | boolean;
  staleTime?: number;
  refetchInterval?: number;
  cacheTime?: number;
  keepPreviousData?: boolean;
};

interface UseApiFetchInterface {
  errorMessage?: string;
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: (...args: any[]) => void;
  refetch?: () => void;
  queryRef: string[];
  successMessage?: string;
  transformer?: (response: any) => void;
  url: string;
  options?: UseApiFetchOptions;
  data?: UseBackendApiParams;
  method?: HTTPMethod.GET | HTTPMethod.POST;
}

export const useFetchRequest = <T>({
  queryRef,
  url,
  onSuccess,
  onError,
  onSettled,
  options,
  transformer = (transformerData) => transformerData,
  errorMessage,
  successMessage,
  data,
  method = HTTPMethod.GET,
}: UseApiFetchInterface): UseQueryResult<T> => {
  const triggerNotification = useNotifications();

  return useQuery<T>(queryRef, () => useBackendApi(url, method, transformer, data), {
    ...options,
    onError: () => {
      if (onError) onError();
      if (errorMessage) {
        triggerNotification({ type: NotificationType.ERROR, message: errorMessage });
      }
    },
    onSuccess: (response: any) => {
      if (onSuccess) onSuccess(response);
      if (successMessage) {
        triggerNotification({ type: NotificationType.SUCCESS, message: successMessage });
      }
    },
    onSettled: () => {
      if (onSettled) onSettled();
    },
  });
};
