import { Stack, styled } from '@mui/material';
import { boxShadows } from 'kognia-ui';

import { PLAYLIST_TIMELINE_HEADER_HEIGHT } from '../config/Playlist.config';

export const PlaylistTimelineHeader = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: theme.spacing(0, 2),
  boxShadow: boxShadows[2],
  height: PLAYLIST_TIMELINE_HEADER_HEIGHT,
  background: theme.palette.common.white,
}));
