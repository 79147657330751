import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconTag = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M7.667 8.215a4.07 4.07 0 0 1 4.063-4.063 4.07 4.07 0 0 1 4.063 4.063c0 1.3-.613 2.452-1.566 3.191l-.126.097 2.595 1.297c.862.435 1.332 1.361 1.173 2.301l-.606 3.566c-.113.686-.699 1.181-1.392 1.181h-4.769c-.443 0-.871-.179-1.178-.492l-3.784-3.784.99-1.004a1.4 1.4 0 0 1 .996-.417 1.1 1.1 0 0 1 .223.021l.075.015h.001l1.512.321v-2.652l-.054-.028c-1.315-.672-2.216-2.039-2.216-3.613zm4.063-2.749c-1.516 0-2.749 1.233-2.749 2.749a2.72 2.72 0 0 0 .786 1.917l.171.174V8.215c0-.989.803-1.792 1.792-1.792s1.792.803 1.792 1.792v2.091l.171-.174a2.72 2.72 0 0 0 .786-1.917c0-1.516-1.233-2.749-2.749-2.749zm0 2.271c-.267 0-.479.211-.479.478v7.908l-3.425-.717 3.023 3.023.006.005c.064.055.147.1.247.1h4.853l.62-3.649c.06-.369-.129-.737-.47-.908l-2.64-1.32h-1.256V8.215c0-.267-.211-.478-.479-.478z' />
    </SvgIcon>
  );
};

export default IconTag;
