import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import {
  useDuration,
  useNonReactiveCurrentTime,
  useVideoPlayerPlayingMode,
} from 'shared/components/video-player/hooks';

import { useSetIsTimelineReadyToRender } from '../../../../../hooks/use-is-timeline-ready-to-render';
import { useTacticalAnalysisEpisodes } from '../../../../../hooks/use-tactical-analysis-episodes';
import { useTimelineInitialTime } from '../../../../../hooks/use-timeline-initial-time';
import { useTimelineZoomLevel } from '../../../../../hooks/use-timeline-zoom-level';
import { getPlayHeadPositionByTime } from '../../../../../utils/get-play-head-position-by-time';
import { useTimelineInnerWidth } from '../use-generate-timeline-width';
import { getCenterToPlayHeadLeftPosition } from '../use-sync-row-content';

type Options = {
  recordingId: string;
  visibleContentRef: RefObject<HTMLDivElement>;
  headerContentRef: RefObject<HTMLDivElement>;
};

export type PlayHeadPositioningActions = {
  center: () => void;
};

export const usePlayHeadPositionInTimeline = ({
  recordingId,
  visibleContentRef,
  headerContentRef,
}: Options): PlayHeadPositioningActions => {
  const duration = useDuration();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const timelineInnerWidth = useTimelineInnerWidth(recordingId);
  const setIsTimelineReadyToRender = useSetIsTimelineReadyToRender(recordingId);
  const timelineInitialTime = useTimelineInitialTime(recordingId);
  const { zoomLevel } = useTimelineZoomLevel();
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const nonReactiveCurrentTime = useNonReactiveCurrentTime();
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();

  const handleCenterPlayHead = useCallback(() => {
    if (!visibleContentRef.current || !headerContentRef.current || timelineInnerWidth === 0) {
      return;
    }

    const time = isFirstLoad && timelineInitialTime >= 0 ? timelineInitialTime : nonReactiveCurrentTime.time;
    if (time === 0) return (visibleContentRef.current.scrollLeft = 0);

    const scrollLeft = getCenterToPlayHeadLeftPosition(
      getPlayHeadPositionByTime(time, effectiveTime, episodes, zoomLevel),
      visibleContentRef.current.offsetWidth,
    );

    const maxScroll = visibleContentRef.current.scrollWidth - visibleContentRef.current.offsetWidth;

    visibleContentRef.current.scrollLeft = scrollLeft > maxScroll ? maxScroll : scrollLeft;
  }, [
    timelineInnerWidth,
    headerContentRef,
    timelineInitialTime,
    nonReactiveCurrentTime,
    visibleContentRef,
    effectiveTime,
    episodes,
    zoomLevel,
    isFirstLoad,
  ]);

  useEffect(() => {
    if (duration === 0) return;
    if (timelineInnerWidth === 0) return;
    if (isFirstLoad) setIsFirstLoad(false);

    handleCenterPlayHead();
    setIsTimelineReadyToRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, timelineInnerWidth]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && (event.code === 'KeyC' || event.code === 'keyc')) {
        event.preventDefault();
        event.stopPropagation();
        handleCenterPlayHead();
      }
    },
    [handleCenterPlayHead],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return useMemo(
    () => ({
      center: handleCenterPlayHead,
    }),
    [handleCenterPlayHead],
  );
};
