import { Button, ButtonProps } from '@mui/material';
import React from 'react';

import IconChevronDown from '../icons/icon-chevron-down';
import IconChevronUp from '../icons/icon-chevron-up';

interface Props {
  disabled?: boolean;
  type?: ButtonProps['type'];
  fullWidth?: ButtonProps['fullWidth'];
  isOpen: boolean;
  isSelected: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  title: string;
  size?: ButtonProps['size'];
  minWidth?: number;
}
export const ButtonDropdown = ({
  disabled,
  isOpen,
  isSelected,
  onClick,
  title,
  size,
  type,
  fullWidth,
  minWidth = 192,
}: Props) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      variant='outlined'
      color={isSelected || isOpen ? 'primary' : 'secondary'}
      sx={{ minWidth, justifyContent: 'space-between' }}
      size={size}
      endIcon={
        isOpen ? (
          <IconChevronUp size='small' sx={{ color: 'secondary.light' }} />
        ) : (
          <IconChevronDown size='small' sx={{ color: 'secondary.light' }} />
        )
      }
    >
      {title}
    </Button>
  );
};
