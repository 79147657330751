import React, { useEffect } from 'react';

import styles from './Overlays.module.scss';
import { useCurrentPlaylistItem, useIsSeeking, useVideoPlayerState } from '../hooks';
import { useAreOverlaysReady, useOverlaysController } from '../hooks/use-overlays-controller';
import { READY_STATES } from '../state/states';

type Props = {
  disabled: boolean;
  videoPlayerContainerRef: React.RefObject<HTMLDivElement>;
};

export const VideoOverlays = ({ disabled, videoPlayerContainerRef }: Props) => {
  const { container, overlayGenerator, matrix3dTransformation } = useOverlaysController(videoPlayerContainerRef);
  const { fundamentalsSelected, recordingId } = useCurrentPlaylistItem();
  const isSeeking = useIsSeeking();
  const areOverlaysReady = useAreOverlaysReady();
  const { readyState } = useVideoPlayerState();

  useEffect(() => {
    if (fundamentalsSelected.tacticalAnalysisId) {
      overlayGenerator.init({ tacticalAnalysisId: fundamentalsSelected.tacticalAnalysisId, recordingId });
    }
  }, [fundamentalsSelected.tacticalAnalysisId, overlayGenerator, recordingId]);

  const areOverlaysVisible =
    !disabled &&
    !!matrix3dTransformation &&
    areOverlaysReady &&
    !isSeeking &&
    fundamentalsSelected.tacticalAnalysisId &&
    (readyState === READY_STATES.PLAYING || readyState === READY_STATES.PAUSED || readyState === READY_STATES.STAND_BY);

  return (
    <div
      ref={container}
      key={recordingId}
      className={styles.overlays}
      style={{
        display: areOverlaysVisible ? 'block' : 'none',
        transform: matrix3dTransformation ? `matrix3d(${matrix3dTransformation.join(',')})` : 'none',
      }}
    />
  );
};
