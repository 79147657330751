import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconForward5 = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M17.5 13.8333c0 3.0342-2.4658 5.5-5.5 5.5s-5.50001-2.4658-5.50001-5.5S8.96582 8.33331 12 8.33331V12l4.5833-4.58335L12 2.83331v3.66667c-4.05168 0-7.33334 3.28167-7.33334 7.33332S7.94832 21.1666 12 21.1666s7.3333-3.2816 7.3333-7.3333H17.5zm-5.06 1.9708c-.0458.0642-.1008.1192-.165.1559s-.1558.055-.2475.055c-.1558 0-.2842-.0459-.385-.1375s-.1558-.22-.1742-.3759h-.77c.0092.1834.0459.3392.1192.4859s.1742.2566.2933.3575.2659.1741.4217.22.3208.0733.4858.0733c.22 0 .4217-.0367.5867-.11s.3025-.165.4125-.2842.1925-.2566.2475-.4125.0825-.3208.0825-.495c0-.2016-.0275-.3941-.0825-.55s-.1283-.3025-.2292-.4125-.2291-.2016-.3758-.2566-.3117-.0917-.5042-.0917c-.0641 0-.1283.0092-.1833.0183s-.1192.0184-.165.0367-.0917.0275-.1375.0458-.0733.0367-.1008.0459l.1008-.8434h1.5583v-.6508h-2.1908l-.2292 1.9892.6142.1558c.0275-.0275.055-.055.0917-.0825s.0641-.0458.11-.0642.0916-.0366.1375-.0458.1191-.0183.1833-.0183c.11 0 .2017.0183.275.0458s.1467.0825.1925.1375.0917.1283.1192.22.0366.1742.0366.2842-.0091.2016-.0275.2841-.055.1559-.1008.22z' />
    </SvgIcon>
  );
};

export default IconForward5;
