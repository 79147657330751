import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAddUsersToClients } from 'api/backoffice/user/use-add-clients-to-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { IconChevronRight } from 'shared/components/icons/icon-chevron-right';

import {
  AddUsersToClientsForm,
  AddUsersToClientsFormFieldsNames,
  addUsersToClientsFormSchema,
  AddUsersToClientsFormSchema,
} from './utils/add-users-to-clients-form';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';
import { SelectClients } from '../../components/select-clients';
import { SelectUsers } from '../../components/select-users';

export const AddUserToEnvironmentContainer = () => {
  const {
    reset,
    setValue,
    handleSubmit: handleFormSubmit,
    watch,
  } = useForm<AddUsersToClientsFormSchema>({
    defaultValues: {
      [AddUsersToClientsFormFieldsNames.CLIENT_IDS]: [],
      [AddUsersToClientsFormFieldsNames.KOGNIA_USERS]: [],
    },
    resolver: zodResolver(addUsersToClientsFormSchema),
  });
  const [updatedKogniaUsers, setUpdateKogniaUsers] = useState<KogniaUser[]>([]);

  const { clientIds: selectedClients, kogniaUsers: selectedKogniaUsers } = watch();

  const handleAddOnSuccess = useCallback(
    (kogniaUsers: KogniaUser[]) => {
      setValue(AddUsersToClientsFormFieldsNames.CLIENT_IDS, []);
      setValue(AddUsersToClientsFormFieldsNames.KOGNIA_USERS, []);
      setUpdateKogniaUsers(kogniaUsers);
    },
    [setValue],
  );
  const { addUsersToClients, isLoading } = useAddUsersToClients({ onSuccess: handleAddOnSuccess });

  const isValidSelection = selectedKogniaUsers.length > 0 && selectedClients.length > 0;

  const setSelectedKogniaUsers = useCallback(
    (clients: KogniaUser[]) => {
      setValue(AddUsersToClientsFormFieldsNames.KOGNIA_USERS, clients);
    },
    [setValue],
  );

  const setSelectedClients = useCallback(
    (clientIds: string[]) => {
      setValue(AddUsersToClientsFormFieldsNames.CLIENT_IDS, clientIds);
    },
    [setValue],
  );

  const handleAddUsersToClients = useCallback(
    (data: AddUsersToClientsForm) => {
      addUsersToClients(
        data[AddUsersToClientsFormFieldsNames.KOGNIA_USERS],
        data[AddUsersToClientsFormFieldsNames.CLIENT_IDS],
      );
    },
    [addUsersToClients],
  );

  return (
    <BackofficeContentContainer>
      <Stack direction={'column'} spacing={2}>
        <Typography variant={'h6'} sx={{ fontWeight: fontWeight['400'] }} display='flex' gap={0.5} alignItems='center'>
          <span>Users</span>
          <IconChevronRight size='small' color='secondary' />
          <span>Add user(s) to environment(s)</span>
        </Typography>
        <Card>
          <CardContent>
            <form onSubmit={handleFormSubmit(handleAddUsersToClients)}>
              <Stack direction='column' spacing={2}>
                <SelectUsers kogniaUsers={selectedKogniaUsers} setKogniaUsersOnChange={setSelectedKogniaUsers} />
                <SelectClients clientIds={selectedClients} onChange={setSelectedClients} />
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button onClick={() => reset()}>Reset</Button>
                  <LoadingButton
                    color={'primary'}
                    disabled={!isValidSelection}
                    loading={isLoading}
                    size={'large'}
                    type={'submit'}
                    variant={'contained'}
                  >
                    Send
                  </LoadingButton>
                </CardActions>
              </Stack>
            </form>
          </CardContent>
        </Card>
        {updatedKogniaUsers.map((kogniaUser) => (
          <Card key={kogniaUser.id} sx={{ background: Colors.twilight }}>
            <CardContent>
              <Typography variant={'h5'} marginBottom={2}>
                {kogniaUser.firstName} {kogniaUser.lastName}
                <Typography component={'span'} variant={'body1'} color={Colors.green} marginBottom={2}>
                  (id: {kogniaUser.id})
                </Typography>
              </Typography>
              <Stack direction={'column'} spacing={1}>
                <Typography variant={'body1'} sx={{ fontWeight: fontWeight['500'] }}>
                  Client ids
                </Typography>
                {kogniaUser.clientIds.map((clientId) => (
                  <Box key={clientId} sx={{ color: Colors.storm }}>
                    {clientId}
                  </Box>
                ))}
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </BackofficeContentContainer>
  );
};
