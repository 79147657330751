import { Playlist } from 'shared/types/index';

import { CopyToDialog } from './CopyToDialog';

type Props = {
  anchor: HTMLElement | null;
  onClose: () => void;
  playlist: Playlist;
  selectedItems: string[];
};

export const SelectedItemsCopyToDialog = ({ playlist, selectedItems, onClose, anchor }: Props) => {
  return (
    <CopyToDialog
      playlistItems={selectedItems.map((id) => {
        const playlistItem = playlist.playlistItems.find((item) => item.id === id);

        return {
          startTime: playlistItem?.startTime ?? 0,
          endTime: playlistItem?.endTime ?? 0,
          name: playlistItem?.name ?? '',
          recordingId: playlistItem?.recordingId ?? '',
          fundamentalsSelected: playlistItem?.fundamentalsSelected ?? {
            tacticalAnalysisId: undefined,
            fundamentalsSelected: [],
          },
        };
      })}
      onClose={onClose}
      anchorEl={anchor}
    />
  );
};
