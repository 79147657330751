import { RecordingVideoSourceAPI } from 'pages/recordings-list/api/types';
import { Episode, Match, MatchApi } from 'shared/types';
import { MatchWithEpisodes } from 'shared/types/match/types';
import { PlayBackTypes, VideoSource, ViewTypes } from 'shared/types/recording/types';
import { getDefaultVideoSource } from 'shared/utils/get-video-sources';
import { iso8601DurationToSeconds } from 'shared/utils/iso-8601-duration-to-time';
import { round } from 'shared/utils/round';

import { enrichEpisodesWithName, generateMatchSegments } from './utils';
import { TacticalAnalysesPlaylistItemsFiltersAPI } from '../../../../recording/useEpisodesWithFilters/types';
import { transformFilters } from '../../../../recording/utils';

export const transformRecordingVideoSources = (videoSources: RecordingVideoSourceAPI[]): VideoSource[] => {
  return videoSources.map((video) => ({
    id: video.id,
    recordingId: video.recordingId,
    src: video.src,
    srcDownload: video.srcDownload,
    poster: video.poster,
    duration: iso8601DurationToSeconds(video.duration),
    playBackType: video.playBackType as PlayBackTypes,
    videoView: {
      type: video.videoView.type as ViewTypes,
    },
  }));
};

export interface MatchWithEpisodesAPIResponse {
  match: MatchApi;
  episodes: Episode[];
  filters: TacticalAnalysesPlaylistItemsFiltersAPI;
}

export const transformMatchWithEpisodes = ({
  match,
  episodes,
  filters,
}: MatchWithEpisodesAPIResponse): MatchWithEpisodes => {
  const videoSources = transformRecordingVideoSources(match.videoSources);

  const defaultVideoSource = getDefaultVideoSource(videoSources);
  const recordingMatch: Match = {
    id: match.id,
    clientIds: match.clientIds,
    title: match.title,
    videoSources,
    hasHomographies: match.hasHomographies,
    segments: match.segments.map((segment) => ({
      length: round(segment.length),
      start: round(segment.start),
      segment: {
        length: round(segment.segmentType.length),
        name: segment.segmentType.name,
        start: round(segment.segmentType.start),
      },
    })),
    teams: match.teams,
    defaultVideoSource,
    showOverlays: match.showOverlays,
  };

  const recordingEpisodes = enrichEpisodesWithName(episodes);

  return {
    matchSegments: generateMatchSegments(recordingEpisodes),
    match: recordingMatch,
    episodes: recordingEpisodes,
    filters: Object.keys(filters).length === 0 ? undefined : transformFilters(filters),
  };
};
