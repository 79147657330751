import {
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  Popover,
  PopoverOrigin,
} from '@mui/material';
import { fontSizes } from 'kognia-ui';
import React from 'react';

import styles from './ConfirmPopoverDialog.module.scss';
import IconClose from '../icons/icon-close';

interface Props {
  cancelLabel: string;
  confirmLabel: string;
  description: string;
  onCancel?: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  buttonColor?: ButtonProps['color'];
  anchorEl: HTMLDivElement | HTMLElement | null;
  setIsOpen: (isOpen: boolean) => void;
  anchorOrigin?: PopoverOrigin | undefined;
  transformOrigin?: PopoverOrigin | undefined;
}

const ConfirmPopoverDialog = ({
  anchorEl,
  isOpen,
  cancelLabel,
  confirmLabel,
  description,
  onCancel,
  onConfirm,
  setIsOpen,
  buttonColor = 'error',
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'center',
    horizontal: 'left',
  },
}: Props) => {
  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onCancel) onCancel();
    setIsOpen(false);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onConfirm();
    setIsOpen(false);
  };

  return (
    <Popover
      className={styles.popOver}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleCancel}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div className={styles.dialog} onClick={(event) => event.stopPropagation()}>
        <div className={styles.close} onClick={handleCancel}>
          <IconClose size='small' sx={{ color: 'secondary.light' }} />
        </div>
        <DialogContent>
          <DialogContentText
            id='confirm-dialog-description'
            mt={4}
            // TODO use from theme
            fontSize={fontSizes.default}
            lineHeight={1.77}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleCancel} variant='outlined' color='secondary'>
            {cancelLabel}
          </Button>
          <Button
            onClick={handleConfirm}
            data-testid='confirm-dialog-confirm-button'
            variant='contained'
            color={buttonColor}
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      </div>
    </Popover>
  );
};

export default ConfirmPopoverDialog;
