import { Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useRef } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { IconChevronRight } from 'shared/components/icons/icon-chevron-right';
import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';
import { Client } from 'shared/types/user/types';

import { useClients } from '../../../../api/backoffice/client/hooks/use-clients/useClients';
import { useEditBranding } from '../../../../api/backoffice/client/hooks/use-edit-branding/useEditBranding';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';
import { BrandingForm } from '../../features/branding-form/BrandingForm.feature';
import { BrandingFormRef } from '../../features/branding-form/types/brandingFormRef';
import { BrandingOnSubmit } from '../../features/branding-form/types/brandingOnSubmit';

export const EditEnvironmentBranding = () => {
  const { id } = useParams<{ id: string }>();
  const { edit, isLoading } = useEditBranding();
  const redirectTo = useRedirectTo();
  const editBrandingFormRef = useRef<BrandingFormRef>(null);
  const { items } = useClients({ initialFilters: { clientIds: [id] } });
  const environment = items?.[0];
  const defaultValues = environment?.branding;

  const handleAddOnSuccess = useCallback(
    (data: Client) => {
      if (editBrandingFormRef.current) {
        editBrandingFormRef.current.reset();
      }
      redirectTo(generatePath(routes.BACKOFFICE_ENVIRONMENT_DETAIL, { id: data.id }));
    },
    [redirectTo],
  );

  const handleCreateBranding = useCallback(
    ({ data }: BrandingOnSubmit) => {
      edit({
        data,
        onSuccess: handleAddOnSuccess,
        clientId: id,
      });
    },
    [edit, handleAddOnSuccess, id],
  );

  return (
    <BackofficeContentContainer>
      <Container fixed maxWidth='sm'>
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant={'h6'}
            sx={{ fontWeight: fontWeight['400'] }}
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <span>{environment?.name}</span>
            <IconChevronRight size='small' color='secondary' />
            <span>Edit branding</span>
          </Typography>
          <Card>
            <CardContent>
              {environment && defaultValues ? (
                <BrandingForm
                  isLoading={isLoading}
                  onSubmit={handleCreateBranding}
                  ref={editBrandingFormRef}
                  defaultValues={defaultValues}
                />
              ) : null}
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </BackofficeContentContainer>
  );
};
