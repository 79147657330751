import {
  Alert,
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useVideoPlayerPlayingMode } from '../../../../../hooks';
import { useOverlaysFrameInfo } from '../../../../../hooks/use-overlays-controller';

export const VideoOverlaysTab = () => {
  const { showOverlays } = useVideoPlayerPlayingMode();
  const { t } = useTranslation();
  const overlaysFrameInfo = useOverlaysFrameInfo();

  if (!showOverlays) {
    return (
      <Alert severity={'info'} variant={'filled'}>
        No overlays activated
      </Alert>
    );
  }
  return (
    <Stack gap={2}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <h4>Active tactics</h4>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {overlaysFrameInfo.frameTactics.length > 0 ? (
                  overlaysFrameInfo.frameTactics.map((tactic) => (
                    <TableRow key={tactic}>
                      <TableCell>
                        <Box>{t(`fundamentals:fundamentals.${tactic}`)}</Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Alert variant={'outlined'} color={'info'} sx={{ textAlign: 'center' }}>
                        No tactics in this frame
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={8}>
          <h4>Overlay elements</h4>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Players</TableCell>
                  <TableCell>Excluded Players</TableCell>
                  <TableCell>Visibility</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {overlaysFrameInfo.overlayElementsDebugInfo.length > 0 ? (
                  overlaysFrameInfo.overlayElementsDebugInfo.map((element, idx) => (
                    <TableRow key={idx}>
                      <TableCell title={element.id}>
                        <Box
                          sx={{
                            maxWidth: '260px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {element.id}
                        </Box>
                      </TableCell>
                      <TableCell>{element.priority}</TableCell>
                      <TableCell>{element.players.join(', ')}</TableCell>
                      <TableCell>{element.excludedPlayers.join(', ')}</TableCell>
                      <TableCell>
                        <Box sx={{ color: element.isVisible ? Colors.green : Colors.red }}>
                          {element.isVisible ? 'visible' : 'hidden'}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Alert variant={'outlined'} color={'info'} sx={{ textAlign: 'center' }}>
                        No overlay elements in this frame
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Stack>
  );
};
