import { Button, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconBackward5 from 'shared/components/icons/icon-backward-5';
import { useVideoPlayerIsInStandBy } from 'shared/components/video-player/hooks';

import { useVideoPlayerActions, useVideoPlayerState } from '../../../index';
import { PLAYER_STATES } from '../../../state/states';
import styles from '../Controls.module.scss';

const Backward5Button = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { state, readyState } = useVideoPlayerState();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();

  const isDisabled = !readyState || state === PLAYER_STATES.ENDED || isInStandBy;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    buttonRef?.current?.blur();
    actions.skipBackward5s();
  };

  return (
    <Tooltip enterDelay={2000} title={`${t('video-player:controls.skip-back')}`}>
      <span>
        <Button
          ref={buttonRef}
          className={classNames({ [styles.disabled]: isDisabled })}
          disabled={isDisabled}
          size={'small'}
          onClick={handleClick}
        >
          <IconBackward5 sx={{ color: 'common.white' }} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default Backward5Button;
