import { useTranslation } from 'react-i18next';

import { XMLDownloadUrl } from 'api/routes';

import ExportModal, { ExportVideoTypes } from '../export-modal';
import { ExportModals } from '../index';

type ExportXMLModalProps = {
  recordingId: string;
  tacticalAnalysisId?: string;
  onClose: () => void;
  enableFullMatch: boolean;
  enableEffectiveTime: boolean;
};

const ExportXMLModal = ({
  recordingId,
  tacticalAnalysisId,
  onClose,
  enableFullMatch,
  enableEffectiveTime,
}: ExportXMLModalProps) => {
  const { t } = useTranslation();

  const options = [
    {
      type: ExportVideoTypes.FULL_MATCH,
      displayValue: t('timeline:actions.export.xml.export-full-match-xml'),
      downloadUrl: XMLDownloadUrl(recordingId, tacticalAnalysisId),
      enabled: enableFullMatch,
    },
    {
      type: ExportVideoTypes.EFFECTIVE_TIME,
      displayValue: t('timeline:actions.export.xml.export-effective-time-xml'),
      downloadUrl: XMLDownloadUrl(recordingId, tacticalAnalysisId, true),
      enabled: enableEffectiveTime,
    },
  ];

  return (
    <ExportModal
      tacticalAnalysisId={tacticalAnalysisId}
      onClose={onClose}
      modalTitle={t('timeline:actions.export.xml.export-xml')}
      options={options}
      type={ExportModals.XML}
    />
  );
};

export default ExportXMLModal;
