import { Box, Button, Divider, Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverProps } from '@mui/material/Popover';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import React, { useCallback } from 'react';

import IconClose from '../icons/icon-close';
import { MenuListOption } from '../kebab-menu';

interface Props {
  isOpen: boolean;
  id?: string;
  onClose: (event: React.SyntheticEvent | React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => void;
  anchorEl: PopoverProps['anchorEl'];
  options: MenuListOption[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}
export const MenuList = ({
  id = '',
  anchorEl,
  isOpen,
  onClose,
  options,
  anchorOrigin = { vertical: 'top', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
}: Props) => {
  const handleOnClick = useCallback(
    (event: React.SyntheticEvent | React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => {
      event.stopPropagation();
    },
    [],
  );
  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      onClick={handleOnClick}
      TransitionComponent={Fade}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Stack direction='row' justifyContent='flex-end' pr={1}>
        <Button aria-controls={id} aria-haspopup='true' onClick={onClose} sx={{ minWidth: 'auto' }} size='small'>
          <IconClose size='small' sx={{ color: 'secondary.light' }} />
        </Button>
      </Stack>
      <Box py={1} minWidth='12rem'>
        {options.map((option, idx) =>
          !option.displayText ? (
            <Divider key={idx} />
          ) : (
            <MenuItem
              key={`${option.displayText}-${idx}`}
              selected={option.selected}
              onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                if (!option.avoidCloseOnClick) onClose(event);
                option.onClick && option.onClick(event);
              }}
              sx={{ display: 'flex', gap: 1 }}
              {...option.menuItemProps}
            >
              {option.icon}
              {option.displayText}
            </MenuItem>
          ),
        )}
      </Box>
    </Menu>
  );
};
