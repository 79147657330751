import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconMinus = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M16.333 12.333H7V11h9.333v1.333z' />
    </SvgIcon>
  );
};

export default IconMinus;
