import { Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import hotkeys, { KeyHandler } from 'hotkeys-js';
import { fontSizes } from 'kognia-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

import { Button } from './Button';
import styles from './TagButtonBase.module.scss';
import { HOTKEY_SPLIT_KEY, hotKeyToPrintableString } from '../../../../tagging-tool/utility/hotkeys';

export type TagButtonProps = {
  data: KeypadTag;
  className?: string;
  width?: 'full' | 'flex';
  // color
  green?: boolean;
  purple?: boolean;
  orange?: boolean;
};

export const TagButtonBase = (props: TagButtonProps & { onPress?: (tag: KeypadTag) => void }) => {
  const { data } = props;
  const { t } = useTranslation();

  const [pressing, setPressing] = useState<boolean>(false);
  const [animate, setAnimate] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  // effects

  useEffect(() => {
    const key = props.data?.hotKey ?? null;
    if (key === null) {
      return;
    }

    const handler: KeyHandler = (ev) => {
      ev.preventDefault();

      props.onPress?.(props.data);
      setAnimate(true);
      return false;
    };
    hotkeys(key, { splitKey: HOTKEY_SPLIT_KEY }, handler);

    return () => {
      // any since this unbind call it's neither documented nor in their typings
      hotkeys.unbind({ key, splitKey: HOTKEY_SPLIT_KEY } as any, handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.onPress]);

  // handlers

  const handleLongPress = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handlePressStart = useCallback(() => {
    setPressing(true);
  }, []);

  const handlePressEnd = useCallback(() => {
    setPressing(false);
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    }
  }, [showTooltip]);

  const handlePress = useCallback(() => {
    if (!props.data) {
      return;
    }

    props.onPress?.(props.data);
  }, [props]);

  const handleAnimationEnd = useCallback(() => {
    setAnimate(false);
  }, []);

  const isPurple =
    props.green !== true &&
    props.orange !== true &&
    (props.purple === true || props.data?.typeOfPlay === TypeOfPlay.Offense);
  const isOrange =
    props.green !== true &&
    props.purple !== true &&
    (props.orange === true || props.data?.typeOfPlay === TypeOfPlay.Transition);
  const isGreen =
    props.purple !== true &&
    props.orange !== true &&
    (props.green === true || props.data?.typeOfPlay === TypeOfPlay.Defense);
  const isWhite = !isPurple && !isOrange && !isGreen;

  if (!data || !data.isEnabled) {
    return null;
  }

  const tooltipText = data.hotKey ? (
    <>{hotKeyToPrintableString(data.hotKey)}</>
  ) : (
    <>{t('tagging-tool:keypad-tag.no-shortcut')}</>
  );

  return (
    <Tooltip title={tooltipText} open={showTooltip}>
      <Button
        onPress={handlePress}
        onPressStart={handlePressStart}
        onPressEnd={handlePressEnd}
        onLongPress={handleLongPress}
        onAnimationEnd={handleAnimationEnd}
        className={classNames(
          styles.tagButton,
          // width
          {
            [styles.full]: props.width === 'full',
            [styles.purple]: isPurple,
            [styles.green]: isGreen,
            [styles.orange]: isOrange,
            [styles.white]: isWhite,
            [styles.pressing]: pressing,
            ['animate-ktt-pulse']: animate,
          },
          props.className,
        )}
      >
        <span className={styles.name}>
          <Typography fontSize={fontSizes.default}>{data.name}</Typography>
        </span>
      </Button>
    </Tooltip>
  );
};
