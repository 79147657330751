import { IconButton, useMediaQuery } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { ReactNode } from 'react';

import IconClose from '../../components/icons/icon-close';

export enum NotificationType {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

const DEFAULT_AUTO_HIDE_TIME = 4000;
const ERROR_AUTO_HIDE_TIME = 6000;

const getAutoHideDurationFromNotificationType = (type: NotificationType) => {
  return type === NotificationType.ERROR ? ERROR_AUTO_HIDE_TIME : DEFAULT_AUTO_HIDE_TIME;
};

export interface TriggerNotificationOptions {
  message: string | ReactNode;
  persist?: boolean;
  type: NotificationType;
}

interface TriggerNotificationFunction {
  (options: TriggerNotificationOptions): void;
}

export function useNotifications() {
  const isMobile = useMediaQuery('(max-width:768px)');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeButton = (key: SnackbarKey) => {
    return (
      <IconButton size='small' disableRipple={false} onClick={() => closeSnackbar(key)}>
        <IconClose size='small' sx={{ color: 'common.white' }} />
      </IconButton>
    );
  };

  const triggerNotification: TriggerNotificationFunction = ({
    message,
    persist = false,
    type = NotificationType.DEFAULT,
  }) => {
    enqueueSnackbar(message, {
      variant: type,
      action: closeButton,
      preventDuplicate: true,
      autoHideDuration: persist ? null : getAutoHideDurationFromNotificationType(type),
      anchorOrigin: {
        vertical: isMobile ? 'bottom' : 'top',
        horizontal: 'center',
      },
    });
  };

  return triggerNotification;
}
