import { Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';

import ConfirmDialog from 'shared/components/confirm-dialog';
import IconDelete from 'shared/components/icons/icon-delete';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { SeasonClients } from 'shared/types/user/types';

import { SeasonClientsListItem } from './styled';
import { useDeleteSeasonClients } from '../../../../api/season-clients/use-delete-season-clients';
import { SeasonClientsFormFieldsNames } from '../season-clients-form/types';
import { SeasonClientsModal } from '../season-clients-modal';

interface Props {
  seasonClients: SeasonClients;
}

export const SeasonClientsItem = ({ seasonClients }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { deleteSeasonClients } = useDeleteSeasonClients();

  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const handleDeleteSeasonClients = useCallback(() => {
    deleteSeasonClients({ id: seasonClients.id });
  }, [seasonClients.id, deleteSeasonClients]);

  const options: MenuListOption[] = [
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: handleEditModalOpen,
    },
    {
      displayText: 'Delete',
      icon: <IconDelete size='small' color='secondary' />,
      onClick: () => setIsConfirmDeleteDialogOpen(true),
    },
  ];

  const defaultValues = {
    [SeasonClientsFormFieldsNames.id]: seasonClients.id,
    [SeasonClientsFormFieldsNames.originalClient]: seasonClients.originalClient,
    [SeasonClientsFormFieldsNames.season]: undefined,
    [SeasonClientsFormFieldsNames.clientIds]: seasonClients.clients.map((client) => client.id),
    [SeasonClientsFormFieldsNames.seasonName]: seasonClients.seasonName,
  };

  return (
    <>
      <SeasonClientsListItem key={seasonClients.id}>
        <Typography fontWeight={fontWeight['500']}>{seasonClients.originalClient.name}</Typography>
        <Typography>{seasonClients.seasonName}</Typography>
        <Typography>{seasonClients.clients.map((client) => client.name).join(', ')}</Typography>
        <KebabMenu options={options} />
      </SeasonClientsListItem>

      {isEditModalOpen ? (
        <SeasonClientsModal open={isEditModalOpen} onClose={handleEditModalClose} defaultValues={defaultValues} />
      ) : null}

      <ConfirmDialog
        type={'warning'}
        title={`You sure you want delete association from season "${seasonClients.seasonName}" and client "${seasonClients.originalClient.name}"?`}
        cancelLabel={'Cancel'}
        confirmLabel={'Delete'}
        onConfirm={handleDeleteSeasonClients}
        isOpen={isConfirmDeleteDialogOpen}
        setIsOpen={setIsConfirmDeleteDialogOpen}
      />
    </>
  );
};
