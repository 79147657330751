import { FieldErrors, UseFormRegister } from 'react-hook-form';

import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

import { ProcessGameFieldsNames, ProcessGameFormSchema } from '../types/processGameForm.types';
import { SportlogiqTrackingType } from '../types/processGameFormSubforms.types';

interface Props {
  register: UseFormRegister<ProcessGameFormSchema>;
  errors: FieldErrors<ProcessGameFormSchema>;
  defaultValue?: SportlogiqTrackingType;
}

export function SportlogiqForm({ errors, register, defaultValue }: Props) {
  return (
    <FormSelectField
      id={ProcessGameFieldsNames.sportlogiqTrackingType}
      label='Sportlogiq tracking type'
      error={!!errors[ProcessGameFieldsNames.sportlogiqTrackingType]}
      disabled={Boolean(defaultValue)}
      defaultValue={defaultValue}
      fullWidth
      options={getTypeOptions({
        options: Object.values(SportlogiqTrackingType).map((value) => ({
          value,
        })),
      })}
      helperText={
        errors[ProcessGameFieldsNames.sportlogiqTrackingType] && (
          <>{errors[ProcessGameFieldsNames.sportlogiqTrackingType]?.message}</>
        )
      }
      {...register(ProcessGameFieldsNames.sportlogiqTrackingType)}
    />
  );
}
