import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import trim from 'lodash/trim';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDuplicatePlaylist } from 'api/playlist/useDuplicatePlaylist';
import Input from 'shared/components/input';
import Spinner from 'shared/components/spinner';
import { Playlist } from 'shared/types';

import styles from './DuplicatePlaylistDialog.module.scss';

export interface Props {
  onCancel?: () => void;
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  playlist: Playlist;
}

const DuplicatePlaylistDialog = ({ onCancel, isOpen, onClose, playlist, onUpdate }: Props) => {
  const { t } = useTranslation();
  const { duplicatePlaylist, isSuccess, isError, isLoading } = useDuplicatePlaylist({ playlistId: playlist.id });

  useEffect(() => {
    if (isSuccess) {
      onUpdate && onUpdate();
      onClose();
    }

    if (isError) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  const nameGenerator = (name: string) => t('recording-playlists:duplicate-form.default-name-template', { name });

  const [name, setName] = useState<string>(nameGenerator(playlist.name));

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onCancel) onCancel();
    onClose();
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    duplicatePlaylist({ name });

    return false;
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby='form-dialog-title'
      aria-describedby='form-dialog-description'
    >
      <div className={styles.dialog}>
        <DialogTitle id='form-dialog-title'>{t('recording-playlists:duplicate-form.title')}</DialogTitle>
        <DialogContent>
          <Input autoSelect autoFocus defaultValue={name} id='edit-playlist-name' onChange={handleNameChange} />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button disabled={isLoading} onClick={handleCancel} variant='outlined' color='secondary'>
            {t('common:actions.cancel')} awd
          </Button>
          <Button
            variant='contained'
            onClick={handleSubmit}
            disabled={trim(name).length === 0}
            data-testid='duplicate-playlist-dialog-confirm-button'
          >
            {isLoading ? <Spinner size='small' sx={{ color: 'common.white' }} /> : t('common:actions.duplicate')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default DuplicatePlaylistDialog;
