import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconTrim = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        d='M12.4122 9.43875L9.40425 3.27332c-.11356-.23276-.32608-.36786-.55205-.38116s-.45329.09635-.59359.32251l-.18.29714-.39774.77211c-.27607.60301-.47447 1.20652-.55541 1.78675-.0643.46097-.05232.89762.05464 1.30009.1814.68255.57327 1.55891 1.12684 2.58633l.54956.97851 1.1934 1.9377.3933.5987.0607.0905-.55522 1.1683c-.49119-.2676-1.05519-.4197-1.65456-.4197-1.90107 0-3.44412 1.5299-3.44412 3.4195S6.39305 21.15 8.29412 21.15s3.44408-1.5299 3.44408-3.4194c0-.8801-.335-1.6826-.8849-2.2884l.7329-1.5423c.1231-.259.0995-.5923-.0615-.8302l-.0005-.0007-.1451-.2153-.3843-.585-1.16609-1.8932-.53145-.94614c-.52288-.97048-.8789-1.77208-1.03093-2.34412-.06403-.24095-.07428-.52924-.028-.86103.06341-.45459.22726-.95785.46506-1.47728l.08838-.1932 4.61973 8.70277c.0001.0001.0001.0001.0001.0002l.1881.3516.2305.4464c.0722.1602.0921.2581.0862.399-.0097.2341-.1689.4496-.3886.6279-.7723.6267-1.266 1.5805-1.266 2.649 0 1.8895 1.543 3.4194 3.4441 3.4194s3.4441-1.5299 3.4441-3.4194-1.543-3.4195-3.4441-3.4195c-.2981 0-.5875.0376-.8636.1084l-.4514-.9252.2416-.3801 1.2241-2.0337.524-.9474c.6132-1.14936 1.0388-2.11155 1.2166-2.8312.0968-.392.1038-.8147.0381-1.25931-.0863-.58384-.2942-1.19049-.5826-1.79647l-.4178-.78048-.196-.31092c-.1433-.22242-.3728-.32301-.5977-.302s-.4326.16103-.5414.39416l-2.8876 6.19187zm3.5292-4.90581l.0958.19118c.248.52123.4193 1.02577.4865 1.48092.047.31812.0403.59599-.0176.83026-.1481.59954-.5362 1.48371-1.118 2.57409l-.5085.91941-1.0666 1.781-.7675-1.573 2.8959-6.20386zm-.2355 15.49966c-1.2827 0-2.3206-1.0317-2.3206-2.302s1.0379-2.3021 2.3206-2.3021 2.3206 1.0317 2.3206 2.3021-1.0379 2.302-2.3206 2.302zm-7.41178 0c-1.28269 0-2.32059-1.0317-2.32059-2.302s1.0379-2.3021 2.32059-2.3021 2.32058 1.0317 2.32058 2.3021-1.03789 2.302-2.32058 2.302z'
        strokeWidth='.3'
      />
    </SvgIcon>
  );
};

export default IconTrim;
