import { IconUser } from 'shared/components/icons/icon-user';

import { PlayerPhotoSkeletonContainer } from './styled';

interface Props {
  small?: boolean;
}

export const PlayerPhotoSkeleton = ({ small }: Props) => {
  return (
    <PlayerPhotoSkeletonContainer small={small}>
      <IconUser color='secondary' size={small ? 'xsmall' : 'medium'} />
    </PlayerPhotoSkeletonContainer>
  );
};
