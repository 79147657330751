import React from 'react';

import { ButtonDropdown } from '../../../button-dropdown';

interface Props {
  isOpen: boolean;
  isSelected: boolean;
  label: string;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}

export const AutocompleteDropdownButton = ({
  isSelected,
  label,
  onClick,
  isOpen,
  disabled = false,
  fullWidth = false,
}: Props) => {
  return (
    <ButtonDropdown
      isOpen={isOpen}
      size='large'
      isSelected={isSelected}
      fullWidth={fullWidth}
      minWidth={250}
      onClick={onClick}
      disabled={disabled}
      title={label}
    />
  );
};
