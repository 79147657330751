import { useTranslation } from 'react-i18next';

import { LogoutUrl } from 'api/routes';

import { useFetchRequest } from '../../hooks/useFetchRequest';

export const logoutRedirect = (response: { logoutUrl: string }) => {
  if (response.logoutUrl) window.location.href = response.logoutUrl;
};

export const useLogOutAccount = () => {
  const { t } = useTranslation();
  const fetchQueryRef = ['logoutAccount'];
  return useFetchRequest({
    queryRef: fetchQueryRef,
    url: LogoutUrl,
    errorMessage: t('api:use-logout-account.error'),
    onSuccess: (response: any) => {
      logoutRedirect(response);
    },
    options: {
      enabled: false,
    },
  });
};
