import React from 'react';

import IconClose from 'shared/components/icons/icon-close';
import IconDone from 'shared/components/icons/icon-done';
import Spinner from 'shared/components/spinner';

import { TagEventActionButton, TagEventClipActionsContainer } from './styled';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export const TagEventClipActions = (props: Props) => {
  return (
    <TagEventClipActionsContainer>
      {props.loading ? (
        <Spinner />
      ) : (
        <TagEventActionButton
          disabled={props.disabled === true}
          onClick={props.onConfirm}
          variant='contained'
          size='small'
        >
          <IconDone size='small' sx={{ color: 'common.white' }} />
        </TagEventActionButton>
      )}
      <TagEventActionButton
        disabled={props.loading === true}
        onClick={props.onCancel}
        variant='contained'
        size='small'
        color='secondary'
      >
        <IconClose size='small' sx={{ color: 'common.white' }} />
      </TagEventActionButton>
    </TagEventClipActionsContainer>
  );
};
