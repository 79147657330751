import { Button, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconPause from 'shared/components/icons/icon-pause';
import IconPlay from 'shared/components/icons/icon-play';
import IconReplay from 'shared/components/icons/icon-replay';
import { useVideoPlayerIsInStandBy, useVideoPlayerIsPlaying } from 'shared/components/video-player/hooks';
import styles from 'shared/components/video-player/video-player-component/components/Controls.module.scss';

import { useVideoPlayerActions, useVideoPlayerState } from '../../../index';
import { PLAYER_STATES } from '../../../state/states';

const PlayBackButton = () => {
  const playButtonRef = useRef<HTMLButtonElement>(null);
  const pauseButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const { state } = useVideoPlayerState();
  const isPlaying = useVideoPlayerIsPlaying();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();

  const isEnded = state === PLAYER_STATES.ENDED;

  if (isEnded) {
    return (
      <Tooltip enterDelay={2000} title={`${t('video-player:controls.replay')}`}>
        <span>
          <Button
            disabled={isInStandBy}
            className={classNames({ [styles.disabled]: isInStandBy })}
            size={'small'}
            onClick={actions.replay}
          >
            <IconReplay sx={{ color: 'common.white' }} />
          </Button>
        </span>
      </Tooltip>
    );
  }

  const handleClickPlay = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    playButtonRef?.current?.blur();
    actions.play();
  };

  const handleClickPause = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    pauseButtonRef?.current?.blur();
    actions.pause();
  };

  const isDisabled = isInStandBy;

  return (
    <>
      {isPlaying ? (
        <Tooltip enterDelay={2000} title={`${t('video-player:controls.pause')}`}>
          <span>
            <Button
              disabled={isDisabled}
              className={classNames({ [styles.disabled]: isDisabled })}
              ref={playButtonRef}
              size={'small'}
              onClick={handleClickPause}
            >
              <IconPause sx={{ color: 'common.white' }} />
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Tooltip enterDelay={2000} title={`${t('video-player:controls.play')}`}>
          <span>
            <Button
              disabled={isDisabled}
              className={classNames({ [styles.disabled]: isDisabled })}
              ref={playButtonRef}
              size={'small'}
              onClick={handleClickPlay}
            >
              <IconPlay sx={{ color: 'common.white' }} />
            </Button>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default PlayBackButton;
