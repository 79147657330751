import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { animationDurations, animations, Colors, fontWeight } from 'kognia-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { FormFormLabel } from 'shared/components/form/form-form-label';
import { IconUser } from 'shared/components/icons/icon-user';
import { useDates } from 'shared/hooks/use-dates';
import { MatchTeamTypes } from 'shared/types/teams/types';

import { getLatestPlayersFromGames } from './utils/get-latest-players-from-games';
import { useTeam } from '../../../../../api/teams/use-team';
import { CreatePlayerModal } from '../../../../../components/create-player-modal';
import { CreateTeamModal } from '../../../../../components/create-team-modal';
import { useMetadataGameQueries } from '../../../../../hooks/use-metadata-game-queries';
import { MetadataTeam } from '../../../../../types/games';
import { MetadataPlayerSummary } from '../../../../../types/players';
import { FORM_IDS, NEW_GAME_FORM_STEPS, useSetTeamData, useTeamData } from '../../config';
import { gameTeamFormSchema, TeamFormFieldsNames, TeamFormFormSchema } from '../../forms';
import { GameFormRouteParams } from '../../types';
import { SelectableListOfPlayers } from '../last-games-players-selector';
import { SelectPlayers } from '../select-metadata-players';
import { SelectMetadataTeam } from '../select-metadata-team';
import { getJerseyNumber, SelectedPlayers } from '../selected-players';

type Props = {
  teamType: MatchTeamTypes;
  step: number;
};

export const TeamForm = ({ teamType, step }: Props) => {
  const { id } = useParams<GameFormRouteParams>();
  const { dateToString } = useDates();
  const [selectedFixtureIds, setSelectedFixtureIds] = useState<string[] | undefined>(undefined);
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false);
  const [isCreatePlayerModalOpen, setIsCreatePlayerModalOpen] = useState(false);
  const history = useHistory();
  const teamData = useTeamData(teamType);
  const setTeamData = useSetTeamData(teamType);
  const teamFormRef = useRef<HTMLFormElement>(null);
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<TeamFormFormSchema>({
    resolver: zodResolver(gameTeamFormSchema),
    defaultValues: teamData ? teamData : undefined,
  });

  const selectedPlayers = watch(TeamFormFieldsNames.players);
  const selectedTeam = watch(TeamFormFieldsNames.team);
  const team = useTeam({
    teamId: selectedTeam?.id,
    onSuccess: (team) => {
      setSelectedFixtureIds(team.fixtures.length > 0 ? team.fixtures.map((item) => item.id) : undefined);
    },
  });
  const games = useMetadataGameQueries(selectedFixtureIds);
  const isLoadingGames = games.reduce((acc, game) => acc || game.isLoading, false);

  useEffect(() => {
    if (selectedTeam) setTeamData({ team: selectedTeam, players: selectedPlayers ?? [] });
  }, [setTeamData, selectedPlayers, selectedTeam]);

  const handleAddPlayers = useCallback(
    (players: MetadataPlayerSummary[]) => {
      const selectedPlayersIds = (selectedPlayers ?? []).map((p) => p.id);
      const playersToAdd = players
        .filter((p) => !selectedPlayersIds.includes(p.id))
        .map((p) => ({
          ...p,
          jerseyNumber: getJerseyNumber(p),
        }));
      setValue(TeamFormFieldsNames.players, [...(selectedPlayers ?? []), ...playersToAdd]);
    },
    [setValue, selectedPlayers],
  );

  const handleAddPlayer = useCallback(
    (player: MetadataPlayerSummary) => {
      handleAddPlayers([player]);
    },
    [handleAddPlayers],
  );

  const handleUpdatePlayer = useCallback(
    (player: MetadataPlayerSummary) => {
      setValue(
        TeamFormFieldsNames.players,
        selectedPlayers.map((p) => {
          return p.id === player.id ? player : p;
        }),
      );
    },
    [setValue, selectedPlayers],
  );

  const handleRemovePlayers = useCallback(
    (players: MetadataPlayerSummary[]) => {
      const removePlayerIds = players.map((p) => p.id);
      setValue(
        TeamFormFieldsNames.players,
        (selectedPlayers ?? []).filter((p) => !removePlayerIds.includes(p.id)),
      );
    },
    [setValue, selectedPlayers],
  );

  const handleSelectTeam = useCallback(
    (team: MetadataTeam) => {
      setValue(TeamFormFieldsNames.team, team);
    },
    [setValue],
  );

  const handleSetTeamData = useCallback(
    (teamData: TeamFormFormSchema) => {
      setTeamData({
        team: teamData.team,
        players: teamData.players,
      });
      history.push(generatePath(NEW_GAME_FORM_STEPS[step + 1], { id }));
    },
    [id, step, history, setTeamData],
  );

  const handleOnSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    return handleSubmit(handleSetTeamData)(event);
  };

  const latestPlayersFromGames = getLatestPlayersFromGames({
    team: team.data,
    games,
  });

  return (
    <>
      <form id={FORM_IDS[step]} ref={teamFormRef} onSubmit={handleOnSubmit}>
        <Grid container justifyContent={'center'} sx={{ paddingBottom: 4 }}>
          <Grid item xs={12} md={12} xl={12}>
            <Stack gap={4}>
              <Stack gap={4}>
                <FormControl fullWidth>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <FormFormLabel>
                      {teamType === 'homeTeam' ? 'Select home team' : 'Select opponent team'}
                    </FormFormLabel>
                    <Box>
                      <Button onClick={() => setIsCreateTeamModalOpen(true)}>+ new team</Button>
                    </Box>
                  </Stack>
                  <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <SelectMetadataTeam
                          setTeamOnChange={(team: MetadataTeam | undefined) => {
                            if (!selectedTeam || !team || selectedTeam.id !== team.id) {
                              setValue(TeamFormFieldsNames.players, []);
                            }
                            onChange(team);
                          }}
                          team={value || undefined}
                        />
                        {error && <Typography color={Colors.red}>{error.message}</Typography>}
                      </>
                    )}
                    name={TeamFormFieldsNames.team}
                    control={control}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <FormFormLabel>Select players</FormFormLabel>
                    <Box>
                      <Button onClick={() => setIsCreatePlayerModalOpen(true)}>+ new player</Button>
                    </Box>
                  </Stack>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <>
                        <SelectPlayers
                          showPlayersSelectionList={false}
                          setPlayersOnChange={onChange}
                          players={value || []}
                        />
                      </>
                    )}
                    name={TeamFormFieldsNames.players}
                    control={control}
                  />
                </FormControl>
                {team.data && selectedTeam && selectedFixtureIds ? (
                  <FormControl fullWidth sx={{ animation: `${animations.fadeIn} ${animationDurations.fast} ease-out` }}>
                    <Stack gap={1}>
                      <Card>
                        <CardContent>
                          <Stack gap={2}>
                            <Stack direction={'row'} gap={2} alignItems={'center'}>
                              <Typography
                                sx={{
                                  fontWeight: fontWeight['500'],
                                  color: Colors.shark,
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <IconUser /> <span>Latest games</span>
                              </Typography>
                              {team.data ? (
                                <FormControl sx={{ width: 300 }}>
                                  <Select
                                    value={selectedFixtureIds}
                                    renderValue={(selected) => {
                                      return selected
                                        .map((s) => {
                                          return team.data.fixtures.find((f) => f.id === s)?.name;
                                        })
                                        .join(', ');
                                    }}
                                    multiple
                                    onChange={(event) => {
                                      setSelectedFixtureIds(event.target.value as string[]);
                                    }}
                                    sx={{ animation: `${animations.fadeIn} ${animationDurations.fast} ease-out` }}
                                  >
                                    {team.data.fixtures.map((fixture) => (
                                      <MenuItem value={fixture.id} key={`team-fixture-${fixture.id}`}>
                                        <Checkbox checked={selectedFixtureIds.indexOf(fixture.id) > -1} />
                                        <ListItemText>
                                          {fixture.name} - {dateToString(fixture.date)}
                                        </ListItemText>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : null}
                            </Stack>
                            <Divider />
                            <Stack gap={2} sx={{ maxHeight: '376px', overflowY: 'scroll' }}>
                              <SelectableListOfPlayers
                                isLoading={isLoadingGames}
                                displayOption={'checkbox'}
                                latestPlayers={latestPlayersFromGames ?? []}
                                selectedPlayers={(selectedPlayers ?? []).filter(
                                  (p) => latestPlayersFromGames?.map((p) => p.id).includes(p.id),
                                )}
                                addPlayers={handleAddPlayers}
                                removePlayers={handleRemovePlayers}
                              />
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Stack>
                  </FormControl>
                ) : null}
              </Stack>

              <SelectedPlayers
                selectedPlayers={selectedPlayers ?? []}
                removePlayers={handleRemovePlayers}
                onUpdatePlayer={handleUpdatePlayer}
                error={errors[TeamFormFieldsNames.players]?.message ? errors[TeamFormFieldsNames.players].message : ''}
              />
            </Stack>
          </Grid>
        </Grid>
      </form>
      {isCreateTeamModalOpen ? (
        <CreateTeamModal
          onSuccess={handleSelectTeam}
          open={isCreateTeamModalOpen}
          onClose={() => setIsCreateTeamModalOpen(false)}
        />
      ) : null}
      {isCreatePlayerModalOpen ? (
        <CreatePlayerModal
          onSuccess={handleAddPlayer}
          open={isCreatePlayerModalOpen}
          onClose={() => setIsCreatePlayerModalOpen(false)}
        />
      ) : null}
    </>
  );
};
