import { styled } from '@mui/material';

import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

interface Props {
  order?: 'asc' | 'desc';
}

interface PathProps {
  className?: string;
}

const AscArrowPath = ({ className }: PathProps) => (
  <path
    className={className}
    d='M16.077 12.755H7.909c-.388 0-.573.489-.314.778l4.084 4.598c.166.2.462.2.647 0l4.084-4.598c.259-.311.055-.778-.333-.778h0z'
  />
);
const DescArrowPath = ({ className }: PathProps) => (
  <path
    className={className}
    d='M16.417 10.467l-4.102-4.598c-.166-.2-.462-.2-.647 0l-4.084 4.598c-.259.289-.055.777.314.777h8.186c.388 0 .591-.467.333-.777h0z'
  />
);

const StyledAscPath = styled(AscArrowPath)<Props>(({ theme, order }) => ({
  color: order === 'asc' ? theme.palette.text.primary : theme.palette.secondary.main,
}));

const StyledDescPath = styled(DescArrowPath)<Props>(({ theme, order }) => ({
  color: order === 'desc' ? theme.palette.text.primary : theme.palette.secondary.main,
}));

const IconOrder = (props: Omit<SvgIconProps, 'children'> & Props): JSX.Element => {
  const { order, ...svgProps } = props;

  return (
    <SvgIcon {...svgProps}>
      <StyledAscPath order={order} />
      <StyledDescPath order={order} />
    </SvgIcon>
  );
};

export default IconOrder;
