import classNames from 'classnames';
import { motion, usePresence } from 'framer-motion';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getAnimationStatus, recordingVariants, Variants } from 'pages/recordings-list/components/recording-card/utils';
import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import IconDelete from 'shared/components/icons/icon-delete';
import { IconDownload } from 'shared/components/icons/icon-download';
import IconEdit from 'shared/components/icons/icon-edit';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { ListItem } from 'shared/components/list/list-item';
import Spinner from 'shared/components/spinner';
import useIsTaggingTool from 'shared/hooks/is-tagging-tool';
import useConfirmPopoverDialog from 'shared/hooks/use-confirm-popover-dialog';
import { Recording, RecordingTypes } from 'shared/types/recording/types';

import KebabMenuRecordings from '../kebab-menu-recordings';
import { RecordingsListItemDetails } from '../recordings-list-item-details';

interface Props {
  children: React.ReactNode;
  hasVideoSource: boolean;
  hideActions?: boolean;
  isDeleted: boolean;
  isLoading: boolean;
  menuOptions: MenuListOption[];
  onClick: () => void;
  onDelete: () => void;
  recording: Recording;
  editPath: string;
  title: React.ReactNode;
}

const RecordingCard = ({
  children,
  editPath,
  hasVideoSource,
  hideActions,
  isDeleted,
  isLoading,
  menuOptions,
  onClick,
  onDelete,
  recording,
  title,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isOpen, setIsOpen, ConfirmPopoverDialog } = useConfirmPopoverDialog();
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const [isPresent, safeToRemove] = usePresence();

  const isTaggingTool = useIsTaggingTool();

  const defaultMenuOptions = useMemo(() => {
    const options: MenuListOption[] = [
      {
        icon: <IconEdit size='small' color='secondary' />,
        displayText: t('recordings-list:recording-card.edit-recording'),
        onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          event.stopPropagation();
          history.push(editPath);
        },
      },
      {
        displayText: t('common:actions.delete'),
        icon: <IconDelete size='small' color='secondary' />,
        onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          event && event.stopPropagation();
          setIsOpen(true);
        },
      },
    ];

    if (recording.matchReportDownloadUrl) {
      options.push({
        displayText: (
          <a href={recording.matchReportDownloadUrl} target='_blank' rel='noreferrer' className={styles.downloadOption}>
            <IconDownload size='small' color='secondary' />
            {t('recordings-list:recording-card.download-report')}
          </a>
        ),
      });
    }
    return options;
  }, [editPath, history, setIsOpen, t, recording.matchReportDownloadUrl]);

  const handleDelete = () => {
    onDelete();
    setIsOpen(false);
  };

  return (
    <motion.div
      layout
      initial={Variants.IN}
      animate={getAnimationStatus(isPresent, isDeleted)}
      variants={recordingVariants}
      onAnimationComplete={() => !isPresent && safeToRemove && safeToRemove()}
      className={styles.container}
    >
      <ListItem
        className={classNames(styles.recordingCard, {
          [styles.deleting]: isDeleted,
          [styles.processing]: !hasVideoSource,
          [styles.recordingCardTraining]: recording.type === RecordingTypes.TRAINING,
        })}
        onClick={onClick}
      >
        {title}
        <RecordingsListItemDetails>
          {children}
          <div className={styles.recordingActions}>
            {isLoading && <Spinner size='small' />}
            {!isDeleted &&
              !isLoading &&
              !hideActions &&
              (isTaggingTool ? (
                <KebabMenu ref={kebabRef} options={[...defaultMenuOptions, ...menuOptions]} id={recording.id} />
              ) : (
                <KebabMenuRecordings
                  ref={kebabRef}
                  options={defaultMenuOptions}
                  extraOptions={menuOptions}
                  recording={recording}
                />
              ))}
          </div>
        </RecordingsListItemDetails>
        <ConfirmPopoverDialog
          anchorEl={kebabRef.current}
          cancelLabel={t('common:actions.cancel')}
          confirmLabel={t('common:actions.delete')}
          description={t('recordings-list:delete-confirm-modal.confirm-message')}
          isOpen={isOpen}
          onConfirm={handleDelete}
          setIsOpen={setIsOpen}
          buttonColor='warning'
        />
      </ListItem>
    </motion.div>
  );
};

export default RecordingCard;
