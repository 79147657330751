import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Episode } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

import { Timeline } from '../../api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { generateFiltersRow } from '../../api/use-tactical-analysis-data/generate-timeline-rows/utils/generateFiltersRow';
import { timelineMatchAtoms } from '../../atoms';
import { useSetTacticalAnalysisAppliedFilters } from '../use-tactical-analysis-applied-filters';
import { useTacticalAnalysisEpisodes } from '../use-tactical-analysis-episodes';
import { useSetTimelineTableData, useTimelineTableData } from '../use-timeline-table-data';

export const useTacticalAnalysisFilteredEpisodes = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.filteredEpisodes(recordingId));
};

export const useTacticalAnalysisClearFilters = (recordingId: string) => {
  const setTimelineData = useSetTimelineTableData(recordingId);
  const timelineTableData = useTimelineTableData(recordingId);
  const setAppliedFilters = useSetTacticalAnalysisAppliedFilters(recordingId);

  return useCallback(() => {
    const timelineData = { ...timelineTableData, filtersRow: undefined };
    setAppliedFilters({ recordingIds: [] });

    setTimelineData(timelineData);
  }, [setAppliedFilters, setTimelineData, timelineTableData]);
};

export const useSetTacticalAnalysisFiltersResults = (recordingId: string) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const setTimelineTableData = useSetTimelineTableData(recordingId);
  const timelineTableData = useTimelineTableData(recordingId);

  return useCallback(
    (filteredEpisodes: Episode[], appliedFilters: RecordingsFilters, updateTimelineData?: Timeline) => {
      const currentData = updateTimelineData || timelineTableData;
      const timelineData = {
        ...currentData,
        filtersRow: generateFiltersRow(episodes, filteredEpisodes, appliedFilters, recordingId),
      };

      setTimelineTableData(timelineData);
    },
    [episodes, recordingId, setTimelineTableData, timelineTableData],
  );
};
