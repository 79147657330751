import { Checkbox, FormControl, Stack, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import { Control, Controller, Path, UseFormRegister, FieldErrors } from 'react-hook-form';

import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

import { SeasonCompetitionFormFieldsNames } from './types';
import { getYears } from '../../../../utils/years';
import { SelectCompetition } from '../select-competition';

interface Props<TFormValues extends Record<string, any>> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
  control: Control<TFormValues>;
}

export function SeasonCompetitionsForm<TFormValues extends Record<string, any>>({
  register,
  errors,
  control,
}: Props<TFormValues>) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant={'body1'}>Year</Typography>
          <FormSelectField
            id={SeasonCompetitionFormFieldsNames.year}
            options={getTypeOptions({ options: getYears(10).map((year) => ({ value: year })) })}
            error={!!errors[SeasonCompetitionFormFieldsNames.year]}
            helperText={
              errors[SeasonCompetitionFormFieldsNames.year] && (
                <>{errors[SeasonCompetitionFormFieldsNames.year]?.message}</>
              )
            }
            defaultValue={new Date().getFullYear()}
            fullWidth={false}
            {...register(SeasonCompetitionFormFieldsNames.year as Path<TFormValues>)}
          />
        </Stack>
      </FormControl>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography variant={'body1'}>Crosses year</Typography>
            <Checkbox id={SeasonCompetitionFormFieldsNames.crossesYear} checked={value} onChange={onChange} />
          </Stack>
        )}
        name={SeasonCompetitionFormFieldsNames.crossesYear as Path<TFormValues>}
        control={control}
      />
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <SelectCompetition setCompetitionOnChange={onChange} competition={value} />
            {error && <Typography color={Colors.red}>{error.message}</Typography>}
          </>
        )}
        name={SeasonCompetitionFormFieldsNames.competition as Path<TFormValues>}
        control={control}
      />
    </>
  );
}
