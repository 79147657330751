import { Grid, Typography } from '@mui/material';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TacticalAnalysesTacticsFilters } from 'api/recording/useEpisodesWithFilters/types';
import { defaultRecordingFiltersScenariosOrTacticsInside } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { defensiveTactics, offensiveTactics } from 'pages/tactical-analysis/config';
import ActiveFilters from 'shared/components/active-filters';
import FilterBlock from 'shared/components/filter-block';
import FilterBlockClear from 'shared/components/filter-block-clear';
import FilterBlockStatus from 'shared/components/filter-block-status';
import FilterColumn from 'shared/components/filter-column';
import { FilterMultiSelect } from 'shared/components/filter-multi-select';
import FilterSelect from 'shared/components/filter-select';
import FilterZone from 'shared/components/filter-zone';
import { ListedTacticalScenarioType } from 'shared/types/episodes/types';
import {
  RecordingFiltersScenariosOrTacticsInside,
  RecordingFiltersTactic,
  ZoneValues,
} from 'shared/types/recording/types';
import { TacticsVariants } from 'shared/types/tactics/types';
import { MatchTeam } from 'shared/types/teams/types';
import { scenariosInsideAndEpisode } from 'shared/utils/recordings';

import { RecordingsFilterInsideAnEpisodeTactics } from './components/recordings-filters-inside-an-episode-tactics/RecordingsFilterInsideAnEpisodeTactics';

interface Props {
  disabled?: boolean;
  filters?: RecordingFiltersScenariosOrTacticsInside;
  onUpdate: (filters: RecordingFiltersScenariosOrTacticsInside) => void;
  scenarios: { id: string; name: string }[];
  tactics: TacticalAnalysesTacticsFilters;
  teams: MatchTeam[];
}

export const RecordingsFiltersInsideAnEpisode = ({
  disabled = false,
  filters = defaultRecordingFiltersScenariosOrTacticsInside,
  onUpdate,
  scenarios,
  tactics,
  teams,
}: Props) => {
  const { t } = useTranslation();
  const isFiltersApplied = useMemo(
    () => filters.teams.length || filters.scenario.length || filters.zones.length,
    [filters],
  );

  const clearFilters = useCallback(() => {
    onUpdate({
      ...filters,
      teams: [],
      scenario: '',
      zones: [],
    });
  }, [filters, onUpdate]);

  const handleUpdateZoneFilter = useCallback(
    (zones: ZoneValues) => {
      onUpdate({
        ...filters,
        zones: reduce(
          zones,
          (acc, zone, idx) => {
            const value = parseFloat(idx);
            if (zone) acc.push(value);

            return acc;
          },
          [] as number[],
        ),
      });
    },
    [onUpdate, filters],
  );

  const handleUpdateTeamFilter = useCallback(
    (teams: string[]) => {
      onUpdate({
        ...filters,
        teams,
      });
    },
    [onUpdate, filters],
  );

  const handleUpdateTacticalScenarioFilter = useCallback(
    (scenario: string) => {
      onUpdate({
        ...filters,
        scenario,
      });
    },
    [onUpdate, filters],
  );

  const handleUpdateOffensiveTacticsAndPlayers = useCallback(
    (offensiveTacticFilters: RecordingFiltersTactic[]) => {
      onUpdate({
        ...filters,
        tactics: {
          ...filters.tactics,
          offensive: offensiveTacticFilters,
        },
      });
    },
    [filters, onUpdate],
  );

  const handleUpdateDefensiveTacticsAndPlayers = useCallback(
    (defensiveTacticFilters: RecordingFiltersTactic[]) => {
      onUpdate({
        ...filters,
        tactics: {
          ...filters.tactics,
          defensive: defensiveTacticFilters,
        },
      });
    },
    [filters, onUpdate],
  );

  const scenariosOptions = useMemo(
    () => [
      {
        displayName: t('recordings:filters.scenarios-inside-episode.select-tactical-scenario'),
        value: '',
      },
      ...Array.from(scenarios.values())
        .filter((tacticalScenario) => {
          const key = tacticalScenario.id as ListedTacticalScenarioType;
          return !!scenariosInsideAndEpisode[key];
        })
        .sort(({ id: a }, { id: b }) => {
          return (
            scenariosInsideAndEpisode[a as ListedTacticalScenarioType] -
            scenariosInsideAndEpisode[b as ListedTacticalScenarioType]
          );
        })
        .map((tacticalScenario) => ({
          displayName: t(`fundamentals:tactical-scenario-types.${tacticalScenario.id}`),
          value: tacticalScenario.id.toString(),
        })),
    ],
    [scenarios, t],
  );

  const teamsOptions = useMemo(
    () => [
      {
        displayName: t(`recordings:filters.scenarios-inside-episode.select-team`),
        value: '',
      },
      ...map(teams, (team) => ({
        displayName: team.name,
        value: team.id,
      })),
    ],
    [teams, t],
  );

  return (
    <div>
      <Grid container spacing={2} direction={'column'}>
        <Grid item>
          <strong>{t('recordings:filters.scenarios-inside-episode.title')}</strong>
          <Typography variant={'subtitle2'}>{t('recordings:filters.scenarios-inside-episode.description')}</Typography>
        </Grid>
        <Grid item>
          <FilterBlock>
            <FilterBlockStatus active />
            <ActiveFilters>
              <FilterColumn>
                <FilterMultiSelect
                  disabled={disabled || teamsOptions.length === 1}
                  displayName={t(`recordings:filters.scenarios-inside-episode.select-team`)}
                  onUpdate={handleUpdateTeamFilter}
                  options={teamsOptions}
                  selectedValues={filters?.teams}
                />
              </FilterColumn>
              <FilterColumn>
                <FilterSelect
                  disabled={disabled || scenariosOptions.length === 1}
                  displayName={t('recordings:filters.scenarios-inside-episode.tactical-scenarios')}
                  onUpdate={handleUpdateTacticalScenarioFilter}
                  options={scenariosOptions}
                  selectedValue={filters.scenario}
                />
              </FilterColumn>
              <FilterColumn>
                <FilterZone
                  disabled={disabled || (teamsOptions.length === 1 && scenariosOptions.length === 1)}
                  selectedValue={{
                    1: Boolean(filters?.zones.includes(1)),
                    2: Boolean(filters?.zones.includes(2)),
                    3: Boolean(filters?.zones.includes(3)),
                    4: Boolean(filters?.zones.includes(4)),
                  }}
                  onUpdate={handleUpdateZoneFilter}
                  displayName={t('recordings:filters:zone')}
                />
              </FilterColumn>
              {isFiltersApplied > 0 && (
                <FilterColumn expand>
                  <FilterBlockClear onClick={clearFilters} />
                </FilterColumn>
              )}
            </ActiveFilters>
          </FilterBlock>
        </Grid>
        <Grid item>
          <strong>{t('recordings:filters.scenarios-inside-episode.select-fundamentals')}</strong>
          <RecordingsFilterInsideAnEpisodeTactics
            disabled={disabled}
            filters={filters?.tactics.offensive.filter(
              (tactic) =>
                offensiveTactics.includes(tactic.tacticalFundamentalType) || tactic.tacticalFundamentalType === '',
            )}
            onUpdate={handleUpdateOffensiveTacticsAndPlayers}
            tactics={tactics.offensive}
            teams={teams}
            variant={TacticsVariants.OFFENSIVE}
          />
          <RecordingsFilterInsideAnEpisodeTactics
            disabled={disabled}
            filters={filters?.tactics.defensive.filter(
              (tactic) =>
                defensiveTactics.includes(tactic.tacticalFundamentalType) || tactic.tacticalFundamentalType === '',
            )}
            onUpdate={handleUpdateDefensiveTacticsAndPlayers}
            tactics={tactics.defensive}
            teams={teams}
            variant={TacticsVariants.DEFENSIVE}
          />
        </Grid>
      </Grid>
    </div>
  );
};
