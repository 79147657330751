import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { PLAYLIST_TIMELINE_HEADER_HEIGHT } from '../config/Playlist.config';

export const ItemsListBulk = styled(Box)(({ theme }) => ({
  height: PLAYLIST_TIMELINE_HEADER_HEIGHT,
  backgroundColor: Colors.shark,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 1),
  position: 'absolute',
  top: 0,
  width: '100%',
}));
