import { getCssVariable } from 'shared/utils/get-css-variables';

import {
  CSS_HOME_TEAM_COLOR,
  CSS_OPPONENT_TEAM_COLOR,
} from '../../components/timeline/timeline-table/components/timeline-css-variables';

const teamColors: { home: string | undefined; opponent: string | undefined } = {
  home: undefined,
  opponent: undefined,
};

export const useTeamColors = () => {
  if (teamColors.home && teamColors.opponent) {
    return teamColors;
  }

  const homeTeamColor = getCssVariable(CSS_HOME_TEAM_COLOR);
  const opponentTeamColor = getCssVariable(CSS_OPPONENT_TEAM_COLOR);

  if (homeTeamColor && opponentTeamColor) {
    teamColors.home = homeTeamColor;
    teamColors.opponent = opponentTeamColor;
  }

  return {
    home: getCssVariable(CSS_HOME_TEAM_COLOR),
    opponent: getCssVariable(CSS_OPPONENT_TEAM_COLOR),
  };
};
