import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { SelectableClip } from '../clip';
import { ParentClip } from '../parent-clip/ParentClip';

export const BlockClipsList = ({
  parentClip,
  clips,
  recordingId,
}: {
  parentClip: Clip;
  clips?: Clip[];
  recordingId: string;
}) => {
  return (
    <>
      {clips?.map((innerClip) => {
        if (innerClip.type === 'parent-clip' && innerClip.clips) {
          return (
            <ParentClip
              key={innerClip.id}
              clip={innerClip}
              clips={innerClip.clips}
              parentClip={parentClip}
              recordingId={recordingId}
            />
          );
        }
        return <SelectableClip recordingId={recordingId} clip={innerClip} parentClip={parentClip} key={innerClip.id} />;
      })}
    </>
  );
};
