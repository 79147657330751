import React from 'react';

import { PlayCircle } from './play-circle';
import styles from './PlayerSkeleton.module.scss';
import IconBackward5 from '../../../../icons/icon-backward-5';
import { IconDownload } from '../../../../icons/icon-download';
import IconForward5 from '../../../../icons/icon-forward-5';
import IconFullScreen from '../../../../icons/icon-full-screen';
import IconPlay from '../../../../icons/icon-play';
import IconPlaySpeed from '../../../../icons/icon-play-speed';
import ButtonControls from '../button-controls';
import ButtonControlsLeft from '../button-controls-left';
import ButtonControlsRight from '../button-controls-right';
import VideoControls from '../video-controls';

export const PlayerSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.playCircleContainer}>
        <PlayCircle />
      </div>

      <VideoControls disabled>
        <ButtonControls>
          <ButtonControlsLeft>
            <IconBackward5 color='disabled' />
            <IconPlay color='disabled' />
            <IconForward5 color='disabled' />
          </ButtonControlsLeft>

          <div className={styles.progressBar}>
            <div className={styles.progressBarInner}></div>
          </div>

          <ButtonControlsRight>
            <IconPlaySpeed color='disabled' />
            <IconDownload color='disabled' />
            <IconFullScreen color='disabled' />
          </ButtonControlsRight>
        </ButtonControls>
      </VideoControls>
    </div>
  );
};
