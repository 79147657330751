import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { processEpisodeFormSchema } from './form';
import { ProcessEpisodeForm, ProcessEpisodeFormSchema } from './types';
import { useProcessEpisode } from '../../../../api/operations/use-process-episode';
import { AnnotationFormActions } from '../annotation-form-actions';
import { RecordingIdField } from '../form-fields/recording-id-field';

export const ProcessEpisode = () => {
  const { processEpisode } = useProcessEpisode();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ProcessEpisodeFormSchema>({
    resolver: zodResolver(processEpisodeFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ProcessEpisodeForm) => {
      processEpisode({ data });
    },
    [processEpisode],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
