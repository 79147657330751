import { HTTPMethod } from 'api/types';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';

import { useMutationRequest } from '../../../hooks/useMutationRequest';
import { userAuth0Url } from '../../../routes';
import { CreateKogniaUser, KogniaUser } from '../use-kognia-users/types';

export const useCreateUser = () => {
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<KogniaUser, KogniaUser, BackendApiError>({
    type: HTTPMethod.POST,
    errorMessage: (response) => backendApiErrorParser(response, 'Error creating user(s)'),
    successMessage: 'User created successfully',
  });

  const createUser = ({
    data,
    onSuccess = () => {},
  }: {
    data: CreateKogniaUser;
    onSuccess?: (res: KogniaUser) => void;
  }) => {
    mutate(
      {
        url: userAuth0Url,
        data,
      },
      { onSuccess: (res: KogniaUser) => onSuccess && onSuccess(res) },
    );
  };

  return { createUser, isLoading, isError, isSuccess };
};
