import { Backdrop } from '@mui/material';

import Spinner from '../spinner';

interface Props {
  isLoaded: boolean;
}

export const AppLoading = ({ isLoaded }: Props) => {
  return (
    <Backdrop
      data-testid={'app-loading'}
      sx={{ backgroundColor: (theme) => theme.palette.common.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!isLoaded}
      transitionDuration={500}
    >
      <Spinner isFullPage size='xlarge' />
    </Backdrop>
  );
};
