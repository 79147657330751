import { Grid, Skeleton } from '@mui/material';

import { PLAYLIST_HEADER_HEIGHT, PLAYLIST_TIMELINE_HEIGHT } from 'entities/playlist/config/Playlist.config';

import { PlaylistVideoPlayerContainer } from '../../../../entities/playlist/ui/PlaylistVideoPlayerContainer';

const PlaylistDetailSkeleton = () => {
  return (
    <Grid container height='100%' paddingX={2}>
      <Grid item xs={12} paddingTop={2} height={PLAYLIST_HEADER_HEIGHT}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
      <PlaylistVideoPlayerContainer item xs={12} paddingY={2}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </PlaylistVideoPlayerContainer>
      <Grid item xs={12} paddingBottom={2} height={PLAYLIST_TIMELINE_HEIGHT}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
    </Grid>
  );
};

export default PlaylistDetailSkeleton;
