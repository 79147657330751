import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useCreatePlaylist } from 'api/playlist/useCreatePlaylist';
import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog-new/dialog-actions';
import { DialogContent } from 'shared/components/dialog-new/dialog-content';
import { DialogHeader } from 'shared/components/dialog-new/dialog-header';
import { FormInputField } from 'shared/components/form/form-input-field';
import { IconSave } from 'shared/components/icons/icon-save';
import { VerticalIconButton } from 'shared/components/vertical-icon-button';
import { Playlist } from 'shared/types';

const INPUT_WIDTH = 450;

enum saveTemporaryPlaylistName {
  NAME = 'name',
}

export const saveTemporaryPlaylistSchema = z.object({
  [saveTemporaryPlaylistName.NAME]: z.string().min(3, 'playlist-detail:save-playlist.name-min-length-error'),
});

type SaveTemporaryPlaylistSchema = z.infer<typeof saveTemporaryPlaylistSchema>;

type Props = {
  playlist: Playlist;
};

export const SaveTemporaryPlaylistFeature = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { createPlaylist, isLoading } = useCreatePlaylist();

  const {
    register,
    formState: { errors },
    handleSubmit: handleFormSubmit,
  } = useForm<SaveTemporaryPlaylistSchema>({
    resolver: zodResolver(saveTemporaryPlaylistSchema),
  });

  const handleOnFormSubmit = (data: SaveTemporaryPlaylistSchema) => {
    createPlaylist({
      data: {
        name: data.name,
        items: playlist.playlistItems.map((item) => ({
          startTime: item.startTime ? item.startTime : 0,
          endTime: item.endTime ? item.endTime : 0,
          name: item.name ?? '',
          recordingId: item.recordingId,
        })),
      },
      onSuccess: () => {
        setShowModal(false);
      },
    });
  };

  return (
    <>
      <VerticalIconButton Icon={IconSave} onClick={() => setShowModal(true)}>
        {t('common:actions.save')}
      </VerticalIconButton>

      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <form onSubmit={handleFormSubmit(handleOnFormSubmit)}>
          <DialogHeader icon={<IconSave size='small' />}>Save as playlist</DialogHeader>
          <DialogContent>
            <Stack gap={2}>
              <FormInputField
                style={{ width: `${INPUT_WIDTH}px` }}
                label={t('playlists:forms.playlist-name')}
                id={saveTemporaryPlaylistName.NAME}
                defaultChecked
                {...register(saveTemporaryPlaylistName.NAME)}
                helperText={
                  errors[saveTemporaryPlaylistName.NAME] && (
                    <>{t(errors[saveTemporaryPlaylistName.NAME]?.message ?? '')}</>
                  )
                }
                fullWidth
                size='small'
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={isLoading} type={'submit'} variant='contained' color='primary'>
              Save playlist
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
