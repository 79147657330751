import { Grid, Typography } from '@mui/material';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultRecordingsFiltersEvents } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import ActiveFilters from 'shared/components/active-filters';
import FilterBlock from 'shared/components/filter-block';
import FilterBlockClear from 'shared/components/filter-block-clear';
import FilterBlockStatus from 'shared/components/filter-block-status';
import FilterColumn from 'shared/components/filter-column';
import { FilterMultiSelect } from 'shared/components/filter-multi-select';
import { FilterPlayer } from 'shared/components/filter-player';
import FilterSelect from 'shared/components/filter-select';
import FilterZone from 'shared/components/filter-zone';
import { RecordingsFiltersEvents, ZoneValues } from 'shared/types/recording/types';
import { MatchTeam } from 'shared/types/teams/types';
import { isEndingAPlayEvent, isStartingAPlayEvent } from 'shared/utils/recordings';

export enum EventType {
  STARTING = 'starting',
  ENDING = 'ending',
}

interface Props {
  disabled?: boolean;
  type: EventType;
  teams: MatchTeam[];
  actionsStarting: Set<string>;
  filters?: RecordingsFiltersEvents;
  onUpdate: (filters: RecordingsFiltersEvents | undefined) => void;
}

const checkAppliedFilters = (filters: RecordingsFiltersEvents) => {
  return Object.keys(filters).some((key) => filters[key as keyof RecordingsFiltersEvents].length);
};

export const FilterEvents = ({
  actionsStarting,
  disabled = false,
  filters = defaultRecordingsFiltersEvents,
  onUpdate,
  teams,
  type,
}: Props) => {
  const { t } = useTranslation();
  const [appliedFilter, setAppliedFilter] = useState<RecordingsFiltersEvents>(filters);
  const isAppliedFilters = useMemo(() => checkAppliedFilters(appliedFilter), [appliedFilter]);

  useEffect(() => {
    setAppliedFilter(filters);
  }, [filters]);

  useEffect(() => {
    onUpdate(appliedFilter);
  }, [appliedFilter, onUpdate]);

  const clearFilters = useCallback(() => {
    onUpdate(undefined);
    setAppliedFilter(defaultRecordingsFiltersEvents);
  }, [setAppliedFilter, onUpdate]);

  const handleUpdateZoneFilter = useCallback(
    (zones: ZoneValues) => {
      setAppliedFilter((appliedFilter) => ({
        ...appliedFilter,
        zones: reduce(
          zones,
          (acc, zone, idx) => {
            const value = parseFloat(idx);
            if (zone) acc.push(value);

            return acc;
          },
          [] as number[],
        ),
      }));
    },
    [setAppliedFilter],
  );

  const handleUpdateTeamFilter = useCallback(
    (teams: string[]) => {
      setAppliedFilter((appliedFilter) => ({
        ...appliedFilter,
        teams,
      }));
    },
    [setAppliedFilter],
  );

  const handleUpdateTacticalScenarioFilter = useCallback(
    (event: string) => {
      setAppliedFilter((appliedFilter) => ({
        ...appliedFilter,
        event,
      }));
    },
    [setAppliedFilter],
  );

  const handleUpdatePlayerFilter = useCallback(
    (players: string[]) => {
      setAppliedFilter((appliedFilter) => ({
        ...appliedFilter,
        players,
      }));
    },
    [setAppliedFilter],
  );

  const generateEventActionsOptions = useCallback(
    (action: Set<string>) => {
      return Array.from(action.values())
        .filter((eventAction) => {
          return type === EventType.STARTING ? isStartingAPlayEvent(eventAction) : isEndingAPlayEvent(eventAction);
        })
        .map((eventAction) => ({
          displayName: t(`fundamentals:${type}-event-fundamentals.${eventAction}`),
          value: eventAction.toString(),
        }));
    },
    [t, type],
  );

  const teamsOptions = useMemo(
    () => [
      {
        displayName: t(`recordings:filters.events-${type}-episode.select-team`),
        value: '',
      },
      ...map(teams, (team) => ({
        displayName: team.name,
        value: team.id,
      })),
    ],
    [type, t, teams],
  );

  const eventsOptions = useMemo(
    () => [
      {
        displayName: t(`recordings:filters.events-${type}-episode.select-tactical-scenario`),
        value: '',
      },
      ...generateEventActionsOptions(actionsStarting),
    ],
    [t, type, actionsStarting, generateEventActionsOptions],
  );

  const teamsWithPlayers = useMemo(() => teams.filter((team) => team.players.length > 0), [teams]);
  const hasPlayers = teamsWithPlayers.length > 0;

  return (
    <div>
      <Grid container spacing={2} direction={'column'}>
        <Grid item>
          <strong>{t(`recordings:filters.events-${type}-episode.title`)}</strong>
          <Typography variant={'subtitle2'}>{t(`recordings:filters.events-${type}-episode.description`)}</Typography>
        </Grid>
        <Grid item>
          <FilterBlock>
            <FilterBlockStatus active />
            <ActiveFilters>
              <FilterColumn>
                <FilterMultiSelect
                  disabled={disabled || teamsOptions.length === 1}
                  selectedValues={appliedFilter?.teams}
                  onUpdate={handleUpdateTeamFilter}
                  displayName={t(`recordings:filters.events-${type}-episode.select-offensive-team`)}
                  options={teamsOptions}
                />
              </FilterColumn>
              <FilterColumn>
                <FilterSelect
                  disabled={disabled || eventsOptions.length === 1}
                  selectedValue={appliedFilter?.event}
                  onUpdate={handleUpdateTacticalScenarioFilter}
                  displayName={t(`recordings:filters.events-${type}-episode.tactical-scenarios`)}
                  options={eventsOptions}
                />
              </FilterColumn>
              <FilterColumn>
                <FilterZone
                  disabled={disabled || (teamsOptions.length === 1 && eventsOptions.length === 1)}
                  selectedValue={{
                    1: Boolean(appliedFilter?.zones.includes(1)),
                    2: Boolean(appliedFilter?.zones.includes(2)),
                    3: Boolean(appliedFilter?.zones.includes(3)),
                    4: Boolean(appliedFilter?.zones.includes(4)),
                  }}
                  onUpdate={handleUpdateZoneFilter}
                  displayName={t('recordings:filters:zone')}
                />
              </FilterColumn>
              <FilterColumn>
                <FilterPlayer
                  idx={1}
                  disabled={disabled || !hasPlayers}
                  displayName={t('recordings:filters.scenarios-inside-episode.players')}
                  onUpdate={handleUpdatePlayerFilter}
                  teamsWithPlayers={teamsWithPlayers}
                  selectedValues={appliedFilter?.players}
                />
              </FilterColumn>
              {isAppliedFilters && (
                <FilterColumn expand>
                  <FilterBlockClear onClick={clearFilters} />
                </FilterColumn>
              )}
            </ActiveFilters>
          </FilterBlock>
        </Grid>
      </Grid>
    </div>
  );
};
