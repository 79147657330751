import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { UpdateAutocompleteValue } from 'shared/components/autocomplete';
import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import IconFolder from 'shared/components/icons/icon-folder';
import IconOpenIn from 'shared/components/icons/icon-open-in';
import {
  PlaylistMenuVerticalPosition,
  SelectPlaylistDialog,
} from 'shared/components/select-playlist-dialog/SelectPlaylistDialog';
import { Playlist } from 'shared/types';

import { PlaylistSelectorMenuLink, PlaylistSelectorMenuTitle } from './PlaylistSelectorMenu.styled';

interface Props {
  selectedPlaylist?: Playlist;
  onSelect: (playlist: Playlist) => void;
  verticalPosition?: PlaylistMenuVerticalPosition;
}

export const PlaylistSelectorMenu = ({
  selectedPlaylist,
  onSelect,
  verticalPosition = PlaylistMenuVerticalPosition.Center,
}: Props) => {
  const { t } = useTranslation();
  const [anchorSubmenu, setAnchorSubmenu] = useState<HTMLElement | null>(null);

  const title = selectedPlaylist
    ? `${selectedPlaylist.name} (${selectedPlaylist.playlistItems.length})`
    : t('timeline:playlist-selector-menu.title');

  const handleOpenSubmenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorSubmenu(event.currentTarget);
  }, []);

  const handleCloseSubmenu = useCallback(() => {
    setAnchorSubmenu(null);
  }, []);

  const handleSelectPlaylist: UpdateAutocompleteValue<Playlist | Playlist[] | null> = useCallback(
    (playlist) => {
      if (playlist === null || Array.isArray(playlist)) return;

      onSelect(playlist);
      handleCloseSubmenu();
    },
    [handleCloseSubmenu, onSelect],
  );

  return (
    <>
      <Grid
        container
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        py={0.5}
        px={1}
        bgcolor='secondary.main'
      >
        <Grid item>
          <PlaylistSelectorMenuTitle onClick={handleOpenSubmenu}>
            <IconFolder size='small' sx={{ color: 'common.white' }} />
            {title}
            <IconChevronDown size='small' sx={{ color: 'common.white' }} />
          </PlaylistSelectorMenuTitle>
        </Grid>
        {selectedPlaylist && (
          <Grid xs={1} container item justifyContent={'flex-end'}>
            <PlaylistSelectorMenuLink
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (!selectedPlaylist) event.preventDefault();
              }}
              to={generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: selectedPlaylist.id })}
              target='_blank'
            >
              <IconOpenIn size='small' sx={{ color: 'common.white' }} />
            </PlaylistSelectorMenuLink>
          </Grid>
        )}
      </Grid>
      <SelectPlaylistDialog
        verticalPosition={verticalPosition}
        anchorEl={anchorSubmenu}
        onClose={handleCloseSubmenu}
        onClickItem={handleSelectPlaylist}
      />
    </>
  );
};
