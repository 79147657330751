import { Alert, Box, Stack } from '@mui/material';
import { useRef } from 'react';

import { useQueryParams } from 'shared/hooks/use-query-params';

import { useEmbeddedDashboard } from './hooks/useEmbeddedDashboard';

type Props = {
  dashboardId: string;
};

export const EmbeddedDashboardFeature = ({ dashboardId }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const params = useQueryParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { dashboardId: dontPassThisParam, ...paramsObject } = Object.fromEntries(params.entries());

  const { isError, isSuccess } = useEmbeddedDashboard(dashboardId, containerRef, paramsObject);

  if (isError) {
    return (
      <Box
        sx={{
          height: '100%',
          padding: 2,
        }}
      >
        <Alert severity={'error'}>Error loading dashboard</Alert>
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        visibility: isSuccess ? 'visible' : 'hidden',
        height: '100%',
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          flex: 1,
          overflow: 'hidden',
          iframe: { border: 0, width: '100%', height: '100%' },
        }}
      />
    </Stack>
  );
};
