import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

export const IconTimeline = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <rect x='5.5' y='7.742' width='13' height='1.5' rx='.4' />
      <rect x='8.5' y='11.25' width='10' height='1.5' rx='.4' />
      <rect x='5.5' y='14.758' width='7' height='1.5' rx='.4' />
      <rect x='5.5' y='11.25' width='2' height='1.5' rx='.4' />
      <rect x='13.5' y='14.758' width='5' height='1.5' rx='.4' />
    </SvgIcon>
  );
};
