import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconDelete = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M15.3334 9.5v8.3333H8.66675V9.5h6.66665zm-1.25-5H9.91675l-.83333.83333H6.16675V7H17.8334V5.33333h-2.9167L14.0834 4.5zm2.9167 3.33333H7.00008v9.99997c0 .9167.75 1.6667 1.66667 1.6667h6.66665c.9167 0 1.6667-.75 1.6667-1.6667V7.83333z' />
    </SvgIcon>
  );
};

export default IconDelete;
