import { useEffect } from 'react';

import { useFetchAccount } from 'api/user/use-fetch-account';
import { useFetchFeatureFlags } from 'api/user/use-fetch-feature-flags';
import { registerTrackedUser } from 'kognia/error-tracking/register-user';
import { FeatureFlags, User } from 'shared/types';

export type UseAppDataType = {
  featureFlags: FeatureFlags | undefined;
  user: User | undefined;
};

interface UseAppDataInterface {
  data: UseAppDataType;
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  setQueryData: (...args: any[]) => void;
}

const useAppData = (onAuthorizationError: () => void): UseAppDataInterface => {
  const fetchAccount = useFetchAccount(onAuthorizationError, registerTrackedUser);
  const fetchFeatureFlags = useFetchFeatureFlags();

  useEffect(() => {
    if (fetchAccount.isSuccess && !fetchAccount.isFetching) {
      fetchFeatureFlags.refetch && fetchFeatureFlags.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAccount.isSuccess]);

  const setQueryData = (user: User, featureFlags: { [key in string]: string }) => {
    fetchAccount.setQueryData && fetchAccount.setQueryData(user);
    fetchFeatureFlags.setQueryData && fetchFeatureFlags.setQueryData(featureFlags);
  };

  return {
    data: {
      user: fetchAccount.data,
      featureFlags: fetchFeatureFlags.data,
    },
    isError: fetchAccount.isError || fetchFeatureFlags.isError,
    isSuccess: fetchAccount.isSuccess && fetchFeatureFlags.isSuccess,
    isFetching: fetchAccount.isFetching || fetchFeatureFlags.isFetching,
    isLoading: fetchAccount.isLoading || fetchFeatureFlags.isLoading,
    setQueryData,
  };
};

export default useAppData;
