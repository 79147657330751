import { useEffect, useState } from 'react';

import { useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { getVideoByVideoType } from 'shared/components/video-player/util';

import { useActivePlaylistItemIds } from './useActivePlaylistItemIds';
import { TacticsInRange, useTacticsInRange } from '../api/useTacticsInRange';

export const useSelectedItemsOverlayTactics = (playlistId: string) => {
  const [overlayTacticsAndSelectedItems, setOverlayTacticsAndSelectedItems] = useState<TacticsInRange | undefined>();
  const response = useTacticsInRange();
  const playingMode = useVideoPlayerPlayingMode();
  const playlistItemIds = useActivePlaylistItemIds(playlistId);
  const { getTacticsInRange } = response;

  useEffect(() => {
    const data = playlistItemIds.playlistItems
      .filter((item) => item.fundamentalsSelected.tacticalAnalysisId)
      .map((item) => {
        const { videoSources } = getVideoByVideoType(item, playingMode);

        return {
          startTime: videoSources[0].startTime,
          endTime: videoSources[0].endTime,
          itemId: item.id,
          tacticalAnalysisId: item.fundamentalsSelected.tacticalAnalysisId ?? '',
        };
      });

    getTacticsInRange(data, {
      onSuccess: setOverlayTacticsAndSelectedItems,
      onError: () => setOverlayTacticsAndSelectedItems(undefined),
    });
  }, [playingMode, getTacticsInRange, playlistItemIds]);

  return { ...response, data: overlayTacticsAndSelectedItems };
};
