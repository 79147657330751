import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconFilter = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='m6.4238 6.872h-1.4577c-.1236 0-.2422.0491-.3296.1365-.0874.0875-.1365.206-.1365.3296 0 .1237.0491.2422.1365.3296.0874.0875.206.1366.3296.1366h1.4577c.103.4006.3363.7557.6632 1.0092.3269.2535.7289.391 1.1426.391.4137 0 .8156-.1375 1.1425-.391.3269-.2535.5603-.6086.6633-1.0092h7.9826c.1236 0 .2422-.0491.3296-.1366.0874-.0874.1366-.2059.1366-.3296 0-.1236-.0492-.2421-.1366-.3296-.0874-.0874-.206-.1365-.3296-.1365h-7.9831c-.1031-.4005-.3365-.7552-.6634-1.0086-.3268-.2533-.7286-.3907-1.1422-.3907-.4135 0-.8153.1374-1.1421.3907-.3269.2534-.5603.6081-.6634 1.0086zm5.5745 4.6602h-7.0322c-.1236 0-.2422.0491-.3296.1365-.0874.0874-.1365.206-.1365.3296 0 .1236.0491.2422.1365.3296.0874.0874.206.1365.3296.1365h7.0322c.1032.4005.3366.7553.6634 1.0086.3269.2533.7287.3908 1.1422.3908.4135 0 .8153-.1375 1.1422-.3908.3268-.2533.5602-.6081.6634-1.0086h2.409c.1236 0 .2422-.0491.3296-.1365.0874-.0874.1365-.206.1365-.3296 0-.1236-.0491-.2422-.1365-.3296-.0874-.0874-.206-.1365-.3296-.1365h-2.4095c-.1032-.4005-.3365-.7553-.6634-1.0086-.3269-.2533-.7286-.3908-1.1422-.3908-.4135 0-.8153.1375-1.1422.3908-.3268.2533-.5597.6081-.6629 1.0086zm-5.5598 4.661h-1.4567c-.1237 0-.2422.0491-.3296.1366-.0875.0874-.1366.2059-.1366.3296 0 .1236.0491.2422.1366.3296.0874.0874.2059.1365.3296.1365h1.4567c.103.4007.3363.7557.6632 1.0092.3269.2535.7289.3911 1.1426.3911.4137 0 .8156-.1376 1.1425-.3911.3269-.2535.5603-.6085.6633-1.0092h7.9835c.1237 0 .2422-.0491.3296-.1365.0875-.0874.1366-.206.1366-.3296 0-.1237-.0491-.2422-.1366-.3296-.0874-.0875-.2059-.1366-.3296-.1366h-7.9835c-.103-.4006-.3364-.7557-.6633-1.0092-.3269-.2534-.7288-.391-1.1425-.391-.4137 0-.8157.1376-1.1426.391-.3269.2535-.5602.6086-.6632 1.0092z' />
    </SvgIcon>
  );
};

export default IconFilter;
