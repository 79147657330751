import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { ChangeEvent, useCallback } from 'react';

import { routes } from 'kognia/router/routes';
import IconPlus from 'shared/components/icons/icon-plus';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';
import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';
import Spinner from 'shared/components/spinner';

import { EnvironmentItemColumn } from './components/environment-item-column/EnvironmentItemColumn';
import { EnvironmentsList } from './components/environments-list/EnvironmentsList';
import { useClients } from '../../../../api/backoffice/client/hooks/use-clients/useClients';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';

const DEBOUNCE_TIME = 300;

export const Environments = () => {
  const { items, page, fetchNextPage, isFetching, setFilters } = useClients();
  const redirectTo = useRedirectTo();

  const handleCreateEnvironment = useCallback(() => redirectTo(routes.BACKOFFICE_CREATE_ENVIRONMENT), [redirectTo]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length === 1) return;
      setFilters({ name: event.target.value });
    }, DEBOUNCE_TIME),
    [setFilters],
  );

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Environments</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={handleCreateEnvironment}
        >
          New environment
        </Button>
      </Stack>

      <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
        <TextField size='small' placeholder='Search environments' onChange={handleSearchChange} />
      </Stack>

      <ListHeader>
        <Grid container>
          <EnvironmentItemColumn item xs={3}>
            <Typography variant='body2'>Name</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={3}>
            <Typography variant='body2'>ID</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={3}>
            <Typography variant='body2'>City</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={2}>
            <Typography variant='body2'>Country</Typography>
          </EnvironmentItemColumn>
          <EnvironmentItemColumn item xs={1}>
            <Typography variant='body2'>Action</Typography>
          </EnvironmentItemColumn>
        </Grid>
      </ListHeader>
      {!isFetching && items.length === 0 ? (
        <Box marginTop={4}>
          <NotFound header={'No results'} />
        </Box>
      ) : null}
      {isFetching ? <Spinner isFullPage /> : null}
      {items.length > 0 ? (
        <Box sx={{ py: 1 }}>
          <EnvironmentsList environments={items} />
        </Box>
      ) : null}
      {page ? (
        <Pagination
          total={page.totalElements}
          displayed={items.length}
          onShowMore={fetchNextPage}
          getStatsText={(displayed, total) => `${displayed} of ${total} environments`}
        />
      ) : null}
    </BackofficeContentContainer>
  );
};
