import { AutocompleteChangeReason } from '@mui/base';
import { Box, Popover, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

import MenuItemNewPlaylist from './menu-item-new-playlist/MenuItemNewPlaylist';
import { Playlist } from '../../types';
import { SearchPlaylistAutocomplete } from '../search-playlist-autocomplete';

export enum PlaylistMenuVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
}

export enum PlaylistMenuHorizontalPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

interface Props {
  anchorEl: HTMLElement | null;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onPopoverClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onClickItem?: (playlist: Playlist | null, name: string, reason: AutocompleteChangeReason) => void;
  verticalPosition?: PlaylistMenuVerticalPosition;
  horizontalPosition?: PlaylistMenuHorizontalPosition;
}

const AutoCompleteWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  padding: theme.spacing(3),
  width: '385px',
}));

const PLAYLIST_ID = 'search-playlist-dialog';

export const SelectPlaylistDialog = ({
  anchorEl,
  onClose = () => {},
  onPopoverClose,
  onClickItem = () => {},
  verticalPosition = PlaylistMenuVerticalPosition.Bottom,
  horizontalPosition = PlaylistMenuHorizontalPosition.Left,
}: Props) => {
  const openPopover = Boolean(anchorEl);

  return (
    <Popover
      anchorEl={anchorEl}
      open={openPopover}
      onClose={onPopoverClose || onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}
    >
      <AutoCompleteWrapper>
        <SearchPlaylistAutocomplete open onUpdateValue={onClickItem} playlistId={PLAYLIST_ID} />
      </AutoCompleteWrapper>
      <MenuItemNewPlaylist />
    </Popover>
  );
};
