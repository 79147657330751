import { MutateOptions } from '@tanstack/react-query';
import { TacticId } from 'overlay-generator';
import { useCallback } from 'react';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { tacticsInRange } from 'api/routes';

type TacticsInRangeApiResponse = { [key in TacticId]: { itemIds: string[]; teamIds: string[] } };
export type TacticsInRange = { tactics: TacticId[]; playlistItemsTactics: { [key in string]: TacticId[] } };

const transformTactics = (response: TacticsInRangeApiResponse): TacticsInRange => {
  const tacticsList = Object.keys(response) as TacticId[];

  return {
    tactics: tacticsList,
    playlistItemsTactics: tacticsList.reduce<{ [key in string]: TacticId[] }>((playlistItemsTactics, tacticId) => {
      response[tacticId].itemIds.forEach((playlistItemId: string) => {
        if (!playlistItemsTactics[playlistItemId]) playlistItemsTactics[playlistItemId] = [];
        playlistItemsTactics[playlistItemId].push(tacticId);
      });

      return playlistItemsTactics;
    }, {}),
  };
};

export const useTacticsInRange = () => {
  const { mutate, ...rest } = useMutationRequest<TacticsInRangeApiResponse, TacticsInRange, unknown>({
    transformer: transformTactics,
  });

  const getTacticsInRange = useCallback(
    (
      data: { startTime: number; endTime: number; itemId: string; tacticalAnalysisId: string }[],
      options?: MutateOptions<TacticsInRange, unknown, any, any>,
    ) => {
      mutate({ url: tacticsInRange, data }, options);
    },
    [mutate],
  );

  return { ...rest, getTacticsInRange };
};
