import { Button, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconBackward from 'shared/components/icons/icon-backward';
import { useVideoPlayerIsInStandBy } from 'shared/components/video-player/hooks';

import { usePlaylistItems, useVideoPlayerActions, useVideoPlayerState } from '../../../index';
import { PLAYER_STATES } from '../../../state/states';
import styles from '../Controls.module.scss';

const BackwardButton = () => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { state, readyState } = useVideoPlayerState();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();
  const playlistItems = usePlaylistItems();

  const isDisabled = !readyState || state === PLAYER_STATES.ENDED || isInStandBy;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    buttonRef?.current?.blur();
    actions.previousPlaylistItem();
  };

  if (playlistItems.length <= 1) return null;

  return (
    <Tooltip enterDelay={2000} title={`${t('video-player:controls.previous-video')}`}>
      <span>
        <Button
          ref={buttonRef}
          className={classNames({ [styles.disabled]: isDisabled })}
          disabled={isDisabled}
          size={'small'}
          onClick={handleClick}
        >
          <IconBackward size='medium' sx={{ color: 'common.white' }} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default BackwardButton;
