import { Box, Stack } from '@mui/material';
import React from 'react';
import { Switch, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import IconBall from 'shared/components/icons/icon-ball';
import { ProtectedRoute } from 'shared/components/protected-route';

import { GameFormNavigation } from './components/game-form-navigation';
import GameFormStepper from './components/game-form-stepper';
import { getCurrentStepFromPathName } from './config';
import { GameFormInfo } from './steps/game-info-form';
import { GameSummary } from './steps/game-summary';
import { HomeTeamForm } from './steps/home-team-form';
import { OpponentTeamForm } from './steps/opponent-team-form';
import { GameFormRouteParams } from './types';
import { BackofficeSection } from '../../../components/backoffice-section-header';

const getPageTitle = (id: string, step: number) => {
  if (step === 3) return 'GAME SUMMARY';

  return id ? 'EDIT GAME' : 'NEW GAME';
};

export const GameForm = () => {
  const { id } = useParams<GameFormRouteParams>();
  const currentStep = getCurrentStepFromPathName(location.pathname);

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column', background: '#fff' }}>
      <Stack sx={{ flexGrow: 1, overflowY: 'scroll', padding: '0 32px 0px' }} gap={4}>
        <BackofficeSection icon={<IconBall size='small' />}>{getPageTitle(id, currentStep)}</BackofficeSection>
        <GameFormStepper step={currentStep} />
        <Switch>
          <ProtectedRoute path={routes.BACKOFFICE_GAME_FORM_STEP_1} component={HomeTeamForm} exact />
          <ProtectedRoute path={routes.BACKOFFICE_GAME_FORM_STEP_2} component={OpponentTeamForm} exact />
          <ProtectedRoute path={routes.BACKOFFICE_GAME_FORM_STEP_3} component={GameFormInfo} exact />
          <ProtectedRoute path={routes.BACKOFFICE_NEW_GAME_SUMMARY} component={GameSummary} exact />
        </Switch>
      </Stack>
      <GameFormNavigation step={currentStep} />
    </Box>
  );
};
