import debounce from 'lodash/debounce';
import { RefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { videoPlayerBarContainerHeight } from '../../../video-player-bar/styled';

export const aspectRatioFromSize = (size: { width: number; height: number }, separator: string) => {
  return `${size.width}${separator}${size.height}`;
};

export const DEFAULT_VIDEO_ASPECT_RATIO = { width: 16, height: 9 };
export const defaultVideoAspectRatio = aspectRatioFromSize(DEFAULT_VIDEO_ASPECT_RATIO, '/');

interface Params {
  containerRef: RefObject<HTMLDivElement>;
}

const getAspectRatioSizes = (width: number, height: number): { width: number; height: number } => {
  const ratioWidth = Math.floor((height * 16) / 9);
  const ratioHeight = Math.floor((width * 9) / 16);

  if (ratioHeight > height) {
    const adjustedWidth = Math.floor((height * 16) / 9);
    return { width: adjustedWidth, height: Math.floor((adjustedWidth * 9) / 16) };
  }

  if (ratioWidth > width) {
    const adjustedWidth = Math.floor((width * 9) / 16);
    return { width: Math.floor((adjustedWidth * 16) / 9), height };
  }

  return { width: ratioWidth, height: ratioHeight - videoPlayerBarContainerHeight };
};

export const useVideoAspectRatio = ({ containerRef }: Params) => {
  const [videoAspectRatio, setVideoAspectRatio] = useState(DEFAULT_VIDEO_ASPECT_RATIO);

  const aspectRatioFromContainer = useCallback(
    () =>
      containerRef?.current
        ? getAspectRatioSizes(containerRef.current.clientWidth, containerRef.current.clientHeight)
        : DEFAULT_VIDEO_ASPECT_RATIO,
    [containerRef],
  );

  useLayoutEffect(() => {
    if (containerRef.current?.clientHeight && containerRef.current?.clientWidth) {
      setVideoAspectRatio(aspectRatioFromContainer());
    }
  }, [aspectRatioFromContainer, containerRef]);

  const handleUpdateSize = useCallback((element: HTMLDivElement) => {
    const { width, height } = element.getBoundingClientRect();

    setVideoAspectRatio(getAspectRatioSizes(width, height - videoPlayerBarContainerHeight));
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleContainerResize = useCallback(
    debounce(() => {
      containerRef.current && handleUpdateSize(containerRef.current);
    }, 10),
    [containerRef.current],
  );

  useEffect(() => {
    if (containerRef?.current !== null) {
      window.addEventListener('resize', handleContainerResize);

      const observer = new ResizeObserver(() => {
        handleContainerResize();
      });

      const containerElement: HTMLElement = containerRef.current;
      observer.observe(containerElement);

      return () => {
        window.removeEventListener('resize', handleContainerResize);
        observer.unobserve(containerElement);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  return {
    videoAspectRatio: videoAspectRatio,
  };
};
