import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconVerticalMenu = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M12 8.333a1.84 1.84 0 0 0 1.833-1.833A1.84 1.84 0 0 0 12 4.666a1.84 1.84 0 0 0-1.833 1.833A1.84 1.84 0 0 0 12 8.333zm0 1.833a1.84 1.84 0 0 0-1.833 1.833A1.84 1.84 0 0 0 12 13.833a1.84 1.84 0 0 0 1.833-1.833A1.84 1.84 0 0 0 12 10.166zm0 5.5a1.84 1.84 0 0 0-1.833 1.833A1.84 1.84 0 0 0 12 19.333a1.84 1.84 0 0 0 1.833-1.833A1.84 1.84 0 0 0 12 15.666z' />
    </SvgIcon>
  );
};

export default IconVerticalMenu;
