import { Box, Button, Stack, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';

import { useGenerateTemporaryPlaylist } from 'features/dashboard/embedded-dashboard/api/useGenerateTemporaryPlaylist';
import { EmbeddedDashboardFeature } from 'features/dashboard/embedded-dashboard/EmbeddedDashboard.feature';
import { useDashboardEvents } from 'features/dashboard/embedded-dashboard/hooks/useDashboardEvents';
import Container from 'shared/components/container';
import { Dialog } from 'shared/components/dialog';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { Playlist } from 'shared/types';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

import { useQueryParams } from '../../shared/hooks/use-query-params';

export const DashboardPreviewPage = () => {
  const query = useQueryParams();
  const dashboardIdQueryParam = query.get('dashboardId');
  const [dashboardId, setDashboardId] = useState<string>(dashboardIdQueryParam || '');
  const [temporalPlaylist, setTemporalPlaylist] = useState<Playlist | null>(null);
  const generateTemporaryPlaylist = useGenerateTemporaryPlaylist({ onSuccess: setTemporalPlaylist });

  useDashboardEvents({ onEvent: generateTemporaryPlaylist });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDashboardId(event.currentTarget.dashboardId.value);
  };

  return (
    <SidebarLayout>
      <Container fullScreen>
        <form onSubmit={handleFormSubmit} style={{ height: '100%' }}>
          {!dashboardIdQueryParam && (
            <Stack direction={'row'} alignItems={'center'} gap={2} padding={2} height={'100px'}>
              <TextField id={'dashboardId'} size={'small'} sx={{ width: '380px' }} />
              <Button color={'primary'} variant={'contained'} size={'large'} type={'submit'}>
                Select Dashboard
              </Button>
            </Stack>
          )}
          {dashboardId && <EmbeddedDashboardFeature key={dashboardId} dashboardId={dashboardId} />}
          <Dialog onClose={() => setTemporalPlaylist(null)} open={!!temporalPlaylist} fullWidth maxWidth={false}>
            <Box
              height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
              width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
            >
              {temporalPlaylist && <TemporaryPlaylistWidget playlist={temporalPlaylist} />}
            </Box>
          </Dialog>
        </form>
      </Container>
    </SidebarLayout>
  );
};
