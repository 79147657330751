import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

export const IconScrollUp = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M6,11.333l0,6.333c0,3.484 2.7,6.334 6,6.334c3.3,0 6,-2.85 6,-6.334l0,-6.333c0,-3.483 -2.7,-6.333 -6,-6.333c-3.3,-0 -6,2.85 -6,6.333Zm6,-4.926c2.567,-0 4.667,2.217 4.667,4.926l-0,6.333c-0,2.71 -2.1,4.927 -4.667,4.927c-2.567,-0 -4.667,-2.217 -4.667,-4.927l0,-6.333c0,-2.709 2.1,-4.926 4.667,-4.926Z' />
      <path d='M12,13c0.55,0 1,-0.36 1,-0.8l0,-2.4c0,-0.44 -0.45,-0.8 -1,-0.8c-0.55,0 -1,0.36 -1,0.8l0,2.4c0,0.44 0.45,0.8 1,0.8Z' />
      <path d='M12.5,0.2c-0.143,-0.133 -0.322,-0.2 -0.5,-0.2c-0.179,-0 -0.357,0.067 -0.5,0.2l-1.786,1.667c-0.286,0.266 -0.286,0.666 0,0.933c0.286,0.267 0.714,0.267 1,0l1.286,-1.2l1.286,1.2c0.285,0.267 0.714,0.267 1,0c0.285,-0.267 0.285,-0.667 -0,-0.933l-1.786,-1.667Z' />
    </SvgIcon>
  );
};
