import Alert from '@mui/material/Alert';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Locales } from 'kognia/i18n/types';
import Input from 'shared/components/input';
import InputList, { InputListOption } from 'shared/components/input-list';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { User } from 'shared/types';

import styles from './AccountForm.module.scss';

type AccountFormProps = {
  user: User;
};

const AccountForm = ({ user }: AccountFormProps): JSX.Element => {
  const { t } = useTranslation();
  const branding = useBranding();
  const languageOptions = useMemo(
    () =>
      [
        { label: t('languages:english'), value: Locales['en-US'] },
        { label: t('languages:spanish'), value: Locales['es-ES'] },
      ] as InputListOption[],
    [t],
  );

  const getSelectedLocale = (locale: string, options: InputListOption[]) => {
    return options.find((option) => option.value === locale);
  };

  const selectedLocale = useMemo(() => getSelectedLocale(user.locale, languageOptions), [languageOptions, user.locale]);

  return (
    <div className={styles.formContainer}>
      <div className={styles.form}>
        <fieldset className={styles.formGroup}>
          <Input id='firstName' label={t('account:first-name')} value={user.firstName} onChange={() => {}} disabled />
        </fieldset>

        <fieldset className={styles.formGroup}>
          <Input id='lastName' label={t('account:last-name')} value={user.lastName} onChange={() => {}} disabled />
        </fieldset>

        <fieldset className={styles.formGroup}>
          <Input id='email' label={t('account:email')} type='email' value={user.email} onChange={() => {}} disabled />
        </fieldset>

        <fieldset className={styles.listGroup}>
          <InputList
            id='locale'
            options={languageOptions}
            label={t('account:language')}
            onSelect={() => {}}
            selectedOption={selectedLocale}
            disabled
          />
        </fieldset>

        <div className={styles.formBreaker} />
        <Alert variant='filled' severity='info' sx={{ alignItems: 'center' }}>
          {t('account:info-alert', {
            clientDisplayName: branding.displayName,
          })}
        </Alert>
      </div>
    </div>
  );
};

export default AccountForm;
