import Grid from '@mui/material/Grid';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import Spinner from 'shared/components/spinner';

import {
  KeypadEditKeypadActions,
  KeypadEditKeypadActionsVariant,
  KeypadEditKeypadContainer,
  KeypadEditKeypadFault,
  KeypadEditKeypadHContent,
  KeypadEditKeypadMiddle,
  KeypadEditKeypadVContent,
  KeypadEditOffenseBottom,
  KeypadEditSubTitle,
} from './index.styled';
import styles from './KeypadEditScreen.module.scss';
import { ActionType, useKeypadEditScreenState } from './state';
import { CustomButtonPanel } from '../custom-buttons';
import { EventButton } from '../EventButton/EventButton';
import { GoalVariant, KeypadGoal } from '../keypad-goal';
import { KeypadTagFormModal } from '../keypad-tag-form-modal';
import { RemoveButtonModal } from '../remove-button-modal';

interface Props {
  id: string;
}

export const KeypadEdit = ({ id }: Props) => {
  const { t } = useTranslation();
  const [
    state,
    { dispatch, handleEnabledChange, handleUpdateKeypadTag, handleCreateKeypadTag, handleRemoveKeypadTag },
  ] = useKeypadEditScreenState({ id });

  const { data, customButtonsData } = state;

  const removeModalOpen = state.removeModalShown === true;
  const createButtonModalOpen = state.editModalShown === true;

  const handleEdit = useCallback(
    (payload: KeypadTag) => dispatch({ type: ActionType.EDIT_MODAL_SHOW, payload }),
    [dispatch],
  );
  const handleAddNew = useCallback(() => dispatch({ type: ActionType.ON_ADD_NEW }), [dispatch]);
  const handleModalCancel = useCallback(() => dispatch({ type: ActionType.EDIT_MODAL_CANCEL }), [dispatch]);
  const handleModalClosed = useCallback(() => dispatch({ type: ActionType.EDIT_MODAL_CLOSED }), [dispatch]);
  const handleRemoveModalShow = useCallback(
    (payload: KeypadTag) => dispatch({ type: ActionType.REMOVE_MODAL_SHOW, payload }),
    [dispatch],
  );
  const handleRemoveModalCancel = useCallback(() => dispatch({ type: ActionType.REMOVE_MODAL_CANCEL }), [dispatch]);
  const handleRemoveModalClosed = useCallback(() => dispatch({ type: ActionType.REMOVE_MODAL_CLOSED }), [dispatch]);

  return (
    <>
      <div>
        {data === undefined && <Spinner isFullPage />}
        {data !== undefined && (
          <Grid container direction={'column'} spacing={3}>
            <Grid item>
              <h4>{t('tagging-tool:keypad.generic-buttons-title')}</h4>
              <p>{t('tagging-tool:keypad.generic-buttons')}</p>
            </Grid>
            {/* main container */}
            <Grid item container spacing={3}>
              {/* left column:  offense */}
              <Grid item xs={5}>
                <KeypadEditSubTitle variant={'h6'}>{t('tagging-tool:keypad.offense')}</KeypadEditSubTitle>
                {/* left top */}
                <Grid container item flexWrap={'wrap'} spacing={2}>
                  <EventButton
                    data={data['OFFENSE_L_CORNER']}
                    className={styles.flex}
                    fullLong
                    onEdit={handleEdit}
                    onEnabledChange={handleEnabledChange}
                  />
                  <Grid item container direction={'column'} spacing={1} sx={{ flex: 1 }}>
                    <EventButton
                      data={data['OFFENSE_DIRECT_FAULT']}
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                    <EventButton
                      data={data['OFFENSE_INDIRECT_FAULT']}
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                    <EventButton
                      data={data['OFFENSE_PENALTY']}
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                  </Grid>
                  <EventButton
                    data={data['OFFENSE_R_CORNER']}
                    className={styles.flex}
                    fullLong
                    onEdit={handleEdit}
                    onEnabledChange={handleEnabledChange}
                  />
                </Grid>
                {/* left bottom */}
                <KeypadEditOffenseBottom>
                  {/* left bottom left */}
                  <EventButton
                    data={data['OFFENSE_THROW_IN_L']}
                    flip
                    onEdit={handleEdit}
                    onEnabledChange={handleEnabledChange}
                  />
                  {/* left bottom center */}
                  <KeypadEditKeypadMiddle>
                    <KeypadGoal variant={GoalVariant.NORTH} />
                    <KeypadGoal variant={GoalVariant.SOUTH} />
                    <KeypadEditKeypadHContent>
                      <EventButton
                        fullLong
                        data={data['OFFENSE_L_CROSS']}
                        className={styles.flex}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                      <KeypadEditKeypadVContent>
                        <EventButton
                          data={data['OFFENSE_GOAL_FAVOR']}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                        <EventButton
                          data={data['OFFENSE_GOAL_CHANCE']}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                      </KeypadEditKeypadVContent>
                      <EventButton
                        data={data['OFFENSE_R_CROSS']}
                        fullLong
                        className={styles.flex}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                    </KeypadEditKeypadHContent>
                    <KeypadEditKeypadActions variant={KeypadEditKeypadActionsVariant.TOP}>
                      <EventButton
                        mLong
                        data={data['OFFENSE_FINALIZATION']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                      <EventButton
                        xlLong
                        data={data['OFFENSE_OFFENSE']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                      <EventButton
                        mLong
                        data={data['OFFENSE_GK_SHORT']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                      <EventButton
                        data={data['OFFENSE_GK_LONG']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                    </KeypadEditKeypadActions>
                  </KeypadEditKeypadMiddle>
                  {/* left bottom right */}
                  <EventButton
                    data={data['OFFENSE_THROW_IN_R']}
                    flip
                    onEdit={handleEdit}
                    onEnabledChange={handleEnabledChange}
                  />
                </KeypadEditOffenseBottom>
              </Grid>
              {/* middle column: transitions */}
              <Grid direction={'column'} justifyContent={'center'} item container sx={{ flex: 1 }} xs={2}>
                <Grid item>
                  <KeypadEditSubTitle variant={'h6'}>{t('tagging-tool:keypad.transitions')}</KeypadEditSubTitle>
                </Grid>
                <Grid
                  item
                  container
                  sx={{ flex: 1, gap: '8px', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}
                >
                  <EventButton
                    data={data['TRANSITION_LOST_BALL']}
                    fullLong
                    fullWidth
                    className={styles.eventButton}
                    onEdit={handleEdit}
                    onEnabledChange={handleEnabledChange}
                  />
                  <EventButton
                    data={data['TRANSITION_BALL_RECOVERY']}
                    fullLong
                    fullWidth
                    className={styles.eventButton}
                    onEdit={handleEdit}
                    onEnabledChange={handleEnabledChange}
                  />
                </Grid>
              </Grid>
              {/* right column:  */}
              <Grid direction={'column'} item sx={{ flex: 1 }} xs={5}>
                <Grid item>
                  <KeypadEditSubTitle variant={'h6'}>{t('tagging-tool:keypad.defense')}</KeypadEditSubTitle>
                </Grid>
                <Grid item>
                  {/* right top */}
                  <KeypadEditKeypadContainer>
                    {/* right top left */}
                    <EventButton
                      data={data['DEFENSE_THROW_IN_L']}
                      flip
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                    {/* right top middle */}
                    <KeypadEditKeypadMiddle>
                      <KeypadGoal variant={GoalVariant.NORTH} />
                      <KeypadGoal variant={GoalVariant.SOUTH} />
                      <KeypadEditKeypadActions variant={KeypadEditKeypadActionsVariant.BOTTOM}>
                        <EventButton
                          data={data['DEFENSE_GK_LONG']}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                        <EventButton
                          mLong
                          data={data['DEFENSE_HIGH_PRESS']}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                        <EventButton
                          xlLong
                          data={data['DEFENSE_DEFENSE']}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                        <EventButton
                          mLong
                          data={data['DEFENSE_FINALIZATION']}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                      </KeypadEditKeypadActions>
                      <KeypadEditKeypadHContent>
                        <EventButton
                          data={data['DEFENSE_L_CROSS']}
                          fullLong
                          className={styles.flex}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                        <KeypadEditKeypadVContent>
                          <EventButton
                            data={data['DEFENSE_GOAL_CHANCE']}
                            onEdit={handleEdit}
                            onEnabledChange={handleEnabledChange}
                          />
                          <EventButton
                            data={data['DEFENSE_GOAL_AGAINST']}
                            onEdit={handleEdit}
                            onEnabledChange={handleEnabledChange}
                          />
                        </KeypadEditKeypadVContent>
                        <EventButton
                          data={data['DEFENSE_R_CROSS']}
                          fullLong
                          className={styles.flex}
                          onEdit={handleEdit}
                          onEnabledChange={handleEnabledChange}
                        />
                      </KeypadEditKeypadHContent>
                    </KeypadEditKeypadMiddle>
                    {/* right top right */}
                    <EventButton
                      data={data['DEFENSE_THROW_IN_R']}
                      flip
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                  </KeypadEditKeypadContainer>
                  {/* right bottom */}
                  <KeypadEditKeypadContainer>
                    <EventButton
                      fullLong
                      data={data['DEFENSE_L_CORNER']}
                      className={styles.flex}
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                    <KeypadEditKeypadFault>
                      <EventButton
                        data={data['DEFENSE_DIRECT_FAULT']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                      <EventButton
                        data={data['DEFENSE_INDIRECT_FAULT']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                      <EventButton
                        data={data['DEFENSE_PENALTY']}
                        onEdit={handleEdit}
                        onEnabledChange={handleEnabledChange}
                      />
                    </KeypadEditKeypadFault>
                    <EventButton
                      fullLong
                      data={data['DEFENSE_R_CORNER']}
                      className={styles.flex}
                      onEdit={handleEdit}
                      onEnabledChange={handleEnabledChange}
                    />
                  </KeypadEditKeypadContainer>
                </Grid>
              </Grid>
            </Grid>
            {/* custom buttons */}
            <CustomButtonPanel
              data={customButtonsData}
              handleAddNew={handleAddNew}
              handleEdit={handleEdit}
              handleEnabledChange={handleEnabledChange}
              handleRemove={handleRemoveModalShow}
            />
          </Grid>
        )}
      </div>
      {createButtonModalOpen ? (
        <KeypadTagFormModal
          open={createButtonModalOpen}
          data={state.targetTagData}
          hotKeyResolver={state.hotKeyResolver}
          onSave={state.creating === true ? handleCreateKeypadTag : handleUpdateKeypadTag}
          onClosed={handleModalClosed}
          onCancel={handleModalCancel}
        />
      ) : null}
      {removeModalOpen ? (
        <RemoveButtonModal
          open={removeModalOpen}
          data={state.targetTagData}
          onDelete={handleRemoveKeypadTag}
          onCancel={handleRemoveModalCancel}
          onClosed={handleRemoveModalClosed}
        />
      ) : null}
    </>
  );
};
