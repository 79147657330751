import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { TaggingEvent } from 'api/tagging-tool/types';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import {
  DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
  PLAYLIST_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { VideoSourceType } from 'shared/components/video-player/types';

import { AlignRecording } from './components/align-recording';
import { Meta } from './types';
import { fetchTaggingEvents } from '../../tagging-tool/service/taggingEvent.service';

const ALIGNMENT_PLAYER_ID = 'alignment-player';

export const RecordingAlignment = () => {
  const { recordingId, sourceRecordingId } = useParams<{
    recordingId: string;
    sourceRecordingId?: string;
  }>();

  // state
  const { search } = useLocation();
  const { isLive } = queryString.parse(search);

  const [events, setEvents] = useState<undefined | Array<TaggingEvent>>();

  const [aligning, setAligning] = useState<boolean>(true);
  const [canAlign, setCanAlign] = useState<boolean>(false);
  const [canFinish, setCanFinish] = useState<boolean>(false);
  const [alignmentDiff, setAlignmentDiff] = useState<number>(0);
  const [selectedAlignmentEvent, setSelectedAlignmentEvent] = useState<undefined | TaggingEvent>();

  const [meta, setMeta] = useState<Meta | undefined>();

  useEffect(() => {
    // reset state if we change recordingId and the component (screen) is reused
    setAligning(true);
    setCanFinish(false);
    setCanAlign(false);
    setAlignmentDiff(0);
    setMeta(undefined);
    setEvents(undefined);
    setSelectedAlignmentEvent(undefined);

    fetchTaggingEvents({ recordingId, asc: true, isLive: isLive === 'true' }).then((res) => {
      if (res.error) {
        return;
      }

      if (sourceRecordingId === undefined) {
        // we're not merging, so fill meta
        setMeta({
          recordingId: recordingId,
          name: res.data.name,
          date: res.data.date,
          videoSources: res.data.videoSources,
          matchVideoSource: res.data.matchVideoSource,
        });
      }
      setEvents(res.data.data);
    });

    if (!sourceRecordingId) {
      return;
    }

    // we're merging

    fetchTaggingEvents({ recordingId: sourceRecordingId, asc: true }).then((res) => {
      if (res.error) {
        return;
      } else if (!res.data.matchVideoSource?.src) {
        console.warn(`${sourceRecordingId} has no video source`);
        return;
      }

      setMeta({
        recordingId: sourceRecordingId,
        name: res.data.name,
        date: res.data.date,
        videoSources: res.data.videoSources,
        matchVideoSource: res.data.matchVideoSource,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingId, sourceRecordingId]);

  // handlers
  const generateVideoTypes = useCallback(
    (startTime: number, endTime: number): VideoSourceType[] => [
      {
        playingMode: DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
        videoSources: [
          {
            id: meta?.matchVideoSource?.id ?? '',
            endTime: endTime,
            startTime: startTime,
            src: meta?.matchVideoSource?.src ?? '',
          },
        ],
      },
    ],
    [meta],
  );

  if (!meta?.matchVideoSource?.src) return null;

  return (
    <SidebarLayout>
      <Container>
        <VideoPlayerStateProvider
          playerId={`${ALIGNMENT_PLAYER_ID}-${recordingId}`}
          playlistItems={[
            {
              id: 'tagging-tool-item',
              duration: 0,
              videoTypes: generateVideoTypes(0, 0),
              name: '',
              index: 0,
              recordingId,
              hasHomographies: false,
              fundamentalsSelected: {
                tacticalAnalysisId: undefined,
                fundamentalsSelected: [],
              },
            },
          ]}
          playingMode={PLAYLIST_WITHOUT_OVERLAYS_PLAYING_MODE}
        >
          <AlignRecording
            aligning={aligning}
            alignmentDiff={alignmentDiff}
            canAlign={canAlign}
            canFinish={canFinish}
            events={events}
            meta={meta}
            recordingId={recordingId}
            selectedAlignmentEvent={selectedAlignmentEvent}
            setAligning={setAligning}
            setAlignmentDiff={setAlignmentDiff}
            setCanAlign={setCanAlign}
            setCanFinish={setCanFinish}
            setEvents={setEvents}
            setMeta={setMeta}
            setSelectedAlignmentEvent={setSelectedAlignmentEvent}
            sourceRecordingId={sourceRecordingId}
          />
        </VideoPlayerStateProvider>
      </Container>
    </SidebarLayout>
  );
};
