import React, { useCallback } from 'react';

import IconPause from 'shared/components/icons/icon-pause';
import IconPlay from 'shared/components/icons/icon-play';
import {
  useVideoPlayerActions,
  useVideoPlayerIsPlaying,
  useVideoPlayerState,
} from 'shared/components/video-player/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';

interface Props {
  playlistItem: PlaylistItemType;
}

export const PlayButton = ({ playlistItem }: Props) => {
  const isPlaying = useVideoPlayerIsPlaying();
  const { isEnded } = useVideoPlayerState();
  const actions = useVideoPlayerActions();

  const handlePause = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();
      actions.pause();
    },
    [actions],
  );

  const handlePlay = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();
      !isPlaying && !isEnded ? actions.play() : actions.setPlaylistItem(playlistItem.id, true);
    },
    [playlistItem, isPlaying, actions, isEnded],
  );

  return isPlaying ? (
    <IconPause onClick={handlePause} isButton={true} size='small' color='primary' />
  ) : (
    <IconPlay onClick={handlePlay} isButton size='small' color='primary' />
  );
};
