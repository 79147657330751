import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconOpenIn = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M13.3125 5.975h3.9524l-7.83247 7.8324c-.20991.2099-.20991.5503 0 .7602s.55023.2099.76017 0l7.8324-7.83246v3.95236c0 .2969.2406.5375.5375.5375s.5375-.2406.5375-.5375v-5.25c0-.29685-.2406-.5375-.5375-.5375h-5.25c-.2969 0-.5375.24065-.5375.5375s.2406.5375.5375.5375zM15.4 11.5625v6.4625H5.975V8.6h6.4625c.2969 0 .5375-.24065.5375-.5375s-.2406-.5375-.5375-.5375h-7c-.29685 0-.5375.24065-.5375.5375v10.5c0 .2969.24065.5375.5375.5375h10.5c.2969 0 .5375-.2406.5375-.5375v-7c0-.2969-.2406-.5375-.5375-.5375s-.5375.2406-.5375.5375z' />
    </SvgIcon>
  );
};

export default IconOpenIn;
