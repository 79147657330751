import { SvgIconProps } from '@mui/material';

import { SolutionSvgIconContainer } from '../solution-svg-icon-container/SolutionSvgIconContainer';

export const IconPlaylists = ({ sx, ...props }: SvgIconProps) => {
  return (
    <SolutionSvgIconContainer
      inheritViewBox
      sx={{
        width: '85px',
        color: 'text.secondary',
        ...sx,
      }}
      {...props}
    >
      <svg width='85' height='132' viewBox='0 0 85 132' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='Ilustration 1*1 85/132'>
          <g id='clips'>
            <rect
              id='Rectangle 175'
              x='0.5'
              y='81.4999'
              width='84'
              height='14'
              rx='2.2335'
              stroke='currentColor'
              strokeDasharray='1 1'
            />
            <rect
              id='Rectangle 426'
              x='0.5'
              y='99.4999'
              width='84'
              height='14'
              rx='2.2335'
              stroke='currentColor'
              strokeDasharray='1 1'
            />
            <rect
              id='Rectangle 427'
              x='0.5'
              y='117.5'
              width='84'
              height='14'
              rx='2.2335'
              stroke='currentColor'
              strokeDasharray='1 1'
            />
            <path
              id='Play icon'
              d='M7.15846 91.3877C7.27573 91.462 7.40898 91.4999 7.54222 91.4999C7.65363 91.4999 7.7657 91.4734 7.86865 91.4208L12.247 89.1709C12.4943 89.0437 12.6503 88.7842 12.6503 88.5C12.6503 88.2157 12.4943 87.9563 12.247 87.829L7.86865 85.5791C7.64224 85.4629 7.37413 85.475 7.15847 85.6119C6.94346 85.7489 6.8125 85.9899 6.8125 86.2501V90.7499C6.8125 91.01 6.94345 91.2511 7.15846 91.3877ZM8.27199 87.4638L10.2892 88.5L8.27199 89.5363V87.4638Z'
              fill='currentColor'
            />
            <path
              id='Play icon_2'
              d='M7.15846 109.388C7.27573 109.462 7.40898 109.5 7.54222 109.5C7.65363 109.5 7.7657 109.473 7.86865 109.421L12.247 107.171C12.4943 107.044 12.6503 106.784 12.6503 106.5C12.6503 106.216 12.4943 105.956 12.247 105.829L7.86865 103.579C7.64224 103.463 7.37413 103.475 7.15847 103.612C6.94346 103.749 6.8125 103.99 6.8125 104.25V108.75C6.8125 109.01 6.94346 109.251 7.15847 109.388L7.15846 109.388ZM8.27199 105.464L10.2892 106.5L8.27199 107.536V105.464Z'
              fill='currentColor'
            />
            <path
              id='Play icon_3'
              d='M7.15846 127.388C7.27573 127.462 7.40898 127.5 7.54222 127.5C7.65363 127.5 7.7657 127.473 7.86865 127.421L12.247 125.171C12.4943 125.044 12.6503 124.784 12.6503 124.5C12.6503 124.216 12.4943 123.956 12.247 123.829L7.86865 121.579C7.64224 121.463 7.37413 121.475 7.15847 121.612C6.94346 121.749 6.8125 121.99 6.8125 122.25V126.75C6.8125 127.01 6.94346 127.251 7.15847 127.388L7.15846 127.388ZM8.27199 123.464L10.2892 124.5L8.27199 125.536V123.464Z'
              fill='currentColor'
            />
          </g>
          <g id='Video'>
            <path
              id='Play icon_4'
              d='M36.6045 51.4211L46.0438 45.0005L36.6045 38.58V51.4211ZM47.4929 42.8668C48.6702 43.6677 48.9762 45.272 48.176 46.4503C47.9935 46.7192 47.7616 46.951 47.4929 47.134L38.0536 53.5542C36.8762 54.355 35.2732 54.0491 34.473 52.8708C34.1826 52.4432 34.0273 51.9379 34.0273 51.4209V38.5801C34.0273 37.1556 35.1811 36.0006 36.6048 36.0006C37.1213 36.0006 37.6262 36.156 38.0535 36.4466L47.4929 42.8668Z'
              fill='currentColor'
            />
            <rect
              id='Rectangle 435'
              x='4.5'
              y='20.5006'
              width='76'
              height='49'
              rx='3.14467'
              stroke='currentColor'
              strokeDasharray='1 1'
            />
            <rect
              id='Rectangle 436'
              x='0.911168'
              y='16.9118'
              width='83.1777'
              height='56.1777'
              rx='2.7335'
              stroke='currentColor'
              strokeWidth='1.82234'
            />
          </g>
          <g id='Lines'>
            <rect id='Rectangle 437' y='7' width='85' height='2' rx='1' fill='currentColor' />
            <rect id='Rectangle 438' x='10' width='66' height='2' rx='1' fill='currentColor' />
          </g>
        </g>
      </svg>
    </SolutionSvgIconContainer>
  );
};
