import { useEffect } from 'react';

import {
  DashboardPlaylistGenericPayload,
  DashboardPlaylistGenericPayloadSchema,
} from '../types/embeddedDashboard.types';

enum DASHBOARD_EVENTS {
  SENTRY_ERROR = 'dashboard-sentry-error',
  PLAYLIST_ITEM_CLICKED = 'dashboard-playlist-item-clicked',
}

type Options = {
  onEvent: (payload: DashboardPlaylistGenericPayload) => void;
};

export const useDashboardEvents = ({ onEvent }: Options) => {
  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      switch (event.data.event) {
        case DASHBOARD_EVENTS.SENTRY_ERROR:
          throw new Error(`Dashboard: ${JSON.stringify(event.data.payload)}`);
        case DASHBOARD_EVENTS.PLAYLIST_ITEM_CLICKED: {
          const result = DashboardPlaylistGenericPayloadSchema.safeParse(event.data.payload);
          if (result.success) {
            onEvent(result.data);
          }
          break;
        }
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [onEvent]);
};
