import { Grid, styled } from '@mui/material';

import { PLAYLIST_HEADER_HEIGHT } from '../config/Playlist.config';

export const PlaylistHeaderGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 8, 1, 4),
  height: PLAYLIST_HEADER_HEIGHT,
}));
