import { useMemo } from 'react';

import styles from './ClipBlockContainer.module.scss';
import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { BlockClipsList } from '../block-clips-list';
import { CSS_CLIP_ZOOM_WIDTH } from '../timeline-css-variables';

type Props = {
  clip: Clip;
  recordingId: string;
};

export const ClipBlockContainer = ({ clip, recordingId }: Props) => {
  const size = useMemo(() => {
    return { width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})` };
  }, [clip]);

  return (
    <div className={styles.wrapper} style={size}>
      <div className={styles.content}>
        <BlockClipsList parentClip={clip} clips={clip.clips} recordingId={recordingId} />
      </div>
    </div>
  );
};
