import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SUPERSET_PLAYER_PROFILE_DASHBOARD_ID } from 'config';
import { useGenerateTemporaryPlaylist } from 'features/dashboard/embedded-dashboard/api/useGenerateTemporaryPlaylist';
import { EmbeddedDashboardFeature } from 'features/dashboard/embedded-dashboard/EmbeddedDashboard.feature';
import { useDashboardEvents } from 'features/dashboard/embedded-dashboard/hooks/useDashboardEvents';
import Container from 'shared/components/container';
import { Dialog } from 'shared/components/dialog';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { Playlist } from 'shared/types';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

export const PlayerProfileDashboardPage = () => {
  const triggerNotification = useNotifications();
  const { t } = useTranslation();
  const [temporalPlaylist, setTemporalPlaylist] = useState<Playlist | null>(null);
  const generateTemporaryPlaylist = useGenerateTemporaryPlaylist({ onSuccess: setTemporalPlaylist });

  useDashboardEvents({ onEvent: generateTemporaryPlaylist });

  useEffect(() => {
    if (!SUPERSET_PLAYER_PROFILE_DASHBOARD_ID) {
      triggerNotification({
        type: NotificationType.ERROR,
        message: t('common:error.player-profile'),
      });

      throw new Error('Player profile not found due to local configuration issue');
    }
  }, [t, triggerNotification]);

  return (
    <SidebarLayout>
      <Container fullScreen>
        {SUPERSET_PLAYER_PROFILE_DASHBOARD_ID ? (
          <>
            <EmbeddedDashboardFeature
              key={SUPERSET_PLAYER_PROFILE_DASHBOARD_ID}
              dashboardId={SUPERSET_PLAYER_PROFILE_DASHBOARD_ID}
            />
            <Dialog onClose={() => setTemporalPlaylist(null)} open={!!temporalPlaylist} fullWidth maxWidth={false}>
              <Box
                height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
                width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
              >
                {temporalPlaylist && <TemporaryPlaylistWidget playlist={temporalPlaylist} />}
              </Box>
            </Dialog>
          </>
        ) : null}
      </Container>
    </SidebarLayout>
  );
};
