import { SvgIcon, type SvgIconProps } from '../svg-icon/SvgIcon';

const IconSort = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M15.222 13.038c.375-.066.519.144.323.467l-3.188 5.251c-.197.324-.517.323-.713 0l-3.188-5.251c-.197-.324-.054-.534.323-.467l2.542.449c.375.066.982.067 1.359 0l2.542-.449zm-6.444-2.076c-.375.066-.519-.144-.323-.467l3.188-5.251c.197-.324.517-.323.713 0l3.188 5.251c.197.324.054.534-.323.467l-2.542-.449c-.375-.066-.982-.067-1.359 0l-2.542.449z' />
    </SvgIcon>
  );
};

export default IconSort;
