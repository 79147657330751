import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { usePlaylists } from 'api/playlist/useFetchPlaylists';
import { routes } from 'kognia/router/routes';
import { NotificationLink } from 'shared/components/NotificationLink';
import {
  PlaylistMenuHorizontalPosition,
  PlaylistMenuVerticalPosition,
  SelectPlaylistDialog,
} from 'shared/components/select-playlist-dialog/SelectPlaylistDialog';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { FundamentalsSelection, Playlist } from 'shared/types/index';

import { PostNewPlaylistItem } from '../../api/playlist/types';

export interface CopyToPlaylistItemDetails {
  endTime: number;
  name: string;
  startTime: number;
  recordingId: string;
  fundamentalsSelected: FundamentalsSelection;
}

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onSettled?: () => void;
  playlistItems: CopyToPlaylistItemDetails[];
}

export const CopyToDialog = ({ anchorEl, playlistItems, onClose, onSettled }: Props) => {
  const { addManyToPlaylist } = useAddManyToPlaylist({ successMessage: '' });
  const { invalidateQuery } = usePlaylists({ refetchInterval: false, enabled: false });
  const triggerNotification = useNotifications();

  const handleAddToPlaylistSuccess = useCallback(
    (playlist: Playlist) => {
      const translation = (
        <Trans
          i18nKey='playlist-detail:copy-to.success-message'
          components={{
            playlistLink: (
              <NotificationLink to={generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: playlist.id })} />
            ),
          }}
          values={{ playlistName: playlist.name }}
        />
      );

      triggerNotification({
        type: NotificationType.SUCCESS,
        message: translation,
      });
    },
    [triggerNotification],
  );

  const handleAddToPlaylistSettle = useCallback(() => {
    invalidateQuery && invalidateQuery();
    onSettled && onSettled();
    onClose();
  }, [invalidateQuery, onClose, onSettled]);

  const handleCopyToPlaylist = useCallback(
    (playlist: Playlist | null) => {
      if (playlist === null) return;

      const data: PostNewPlaylistItem[] = playlistItems.map((item) => {
        return {
          startTime: item.startTime,
          endTime: item.endTime,
          playlistId: playlist.id,
          name: item.name,
          recordingId: item.recordingId ?? '',
          fundamentalsSelected: item.fundamentalsSelected,
        };
      });

      addManyToPlaylist({
        items: data,
        options: {
          onSuccess: handleAddToPlaylistSuccess,
          onSettled: handleAddToPlaylistSettle,
        },
      });
    },
    [addManyToPlaylist, handleAddToPlaylistSettle, handleAddToPlaylistSuccess, playlistItems],
  );

  return (
    <SelectPlaylistDialog
      anchorEl={anchorEl}
      onClose={onClose}
      verticalPosition={PlaylistMenuVerticalPosition.Bottom}
      horizontalPosition={PlaylistMenuHorizontalPosition.Center}
      onClickItem={handleCopyToPlaylist}
    />
  );
};
