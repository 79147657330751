import { PopoverActions } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import head from 'lodash/head';
import React, { useEffect, useRef, useState } from 'react';

import IconClose from 'shared/components/icons/icon-close';
import { NotificationCircle } from 'shared/components/notification-circle';

import styles from './FilterSelect.module.scss';
import { ButtonDropdown } from '../button-dropdown';

type FilterSelectOption = {
  disabled?: boolean;
  displayName: string;
  value: string;
};

export type FilterProps = {
  disabled?: boolean;
  displayName: string;
  invalid?: boolean;
  onRemove?: () => void;
  onUpdate: (value: string) => void;
  options: FilterSelectOption[];
  selectedValue?: string;
  showRemoveButton?: boolean;
};

const FilterSelect = ({
  disabled,
  displayName,
  invalid = false,
  onRemove,
  onUpdate,
  options,
  selectedValue = undefined,
  showRemoveButton,
}: FilterProps) => {
  const [value, setValue] = useState<string | undefined>(selectedValue);
  const [isOpen, setIsOpen] = useState(false);
  const actionRef = useRef<PopoverActions | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const filterId = 'filter-menu';

  useEffect(() => {
    if (value !== selectedValue) {
      setValue(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    if (!anchorEl) return;

    setAnchorEl(undefined);
    setIsOpen(false);
  };

  const currentOption = head(options.filter((option) => option.value === value));
  const title = currentOption && value ? currentOption.displayName : displayName;

  return (
    <span className={styles.container} title={currentOption?.displayName ?? ''}>
      <NotificationCircle variant='error' visible={invalid} />
      <ButtonDropdown
        onClick={handleButtonClick}
        title={title}
        isSelected={Boolean(value)}
        isOpen={isOpen}
        disabled={Boolean(disabled)}
      />
      {showRemoveButton && (
        <div className={styles.closeButtonContainer} onClick={onRemove}>
          <div className={styles.closeButton}>
            <IconClose size='xsmall' color='tertiary' />
          </div>
        </div>
      )}
      {isOpen && anchorEl && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            action={actionRef}
            id={filterId}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={styles.menu}
          >
            <div className={styles.contentContainer}>
              {options.map((option) => (
                <div
                  key={option.value}
                  className={classNames(styles.option, {
                    [styles.selected]: option.value === value,
                    [styles.unselected]: !option.value,
                    [styles.disabled]: option.disabled,
                    [styles.invalid]: option.disabled && option.value === value,
                  })}
                  onClick={() => {
                    onUpdate(option.value);
                    setValue(option.value);
                    setIsOpen(false);
                  }}
                >
                  {option.displayName}
                </div>
              ))}
            </div>
          </Menu>
        </ClickAwayListener>
      )}
    </span>
  );
};

export default FilterSelect;
